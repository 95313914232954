import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import translationEN from "./locales/en/translation.json"
import translationPL from "./locales/pl/translation.json"
// import translationDE from "./locales/de/translation.json"

const validLanguages = ['en','pl'];

const resources = {
    // en: {
    //     translation: translationEN,
    // },
    pl: {
        translation: translationPL,
    },
    // de: {
    //     translation: translationDE,
    // }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'pl',
        debug: false,
        keySeparator: false,

    });


export const changeLanguageOfTranslations =(language) => {
    i18n.changeLanguage(language)
        .then(()=>window.location.reload(false))
}

export function t(word, options){
    return i18n.t(word, options);
}

// export const l = '/' + i18n.language.slice(0,2);
export const l = '';
export const lang = i18n.language.slice(0,2);
export const updatedLanguagePath = () => l + window.location.pathname.slice(3);
export const noCurrentLanguage = () => window.location.pathname.slice(0,3) !== l;
export const routerLanguageParams = () => validLanguages.join('|');
export const checkInAvailableLanguages = (lang) => validLanguages.includes(lang) ? lang : 'en';

export default i18n;
