import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Container, Form, Message, Segment } from 'semantic-ui-react';
import history from '../../history';
import {l, t} from "../../i18n";
import { ENVIRONMENT } from '../../constants/index';

function mapStateToProps(state) {
	return {
		facebook: state.facebookCallback,
	};
}

class FacebookCallback extends Component {
	constructor(props){
		super(props);
		this.state = {
			showErrors: false
		}
	}


  componentDidMount(){
		let params = this.props.location.search;
    this.props.actions.facebookCallback(params);
	}

	componentDidUpdate(prevProps){
		if(prevProps.facebook !== this.props.facebook){
			let fb = this.props.facebook;
			if(fb.access_token){
				let token = "Bearer " + fb.access_token;
				localStorage.setItem('authToken', token);
				localStorage.setItem('refreshToken', fb.refresh_token);
				history.push(l+'/panel');
			} else if(fb.data){
				if(fb.data.access_token){
					let token = "Bearer " + fb.data.access_token;
					localStorage.setItem('authToken', token);
					localStorage.setItem('refreshToken', fb.data.refresh_token);
					history.push(l+'/panel');
				} else if(fb.data.twoFactorAuth){
					history.push(l+'/panel');
				} else if(fb.data.email){
					this.setState({showSuccess: true})
				}
			} else if(fb.reason) {
				this.setState({message: fb.reason, showErrors: true})
			} else {
				this.setState({message: 'Error - Something went wrong', showErrors: true})
			}
		}
	}

	successMessage() {
    if (ENVIRONMENT === 'production') {
      return (
        <Message success
          header={t("register.success.production.header")}
          content={t("register.success.production.content")}
        />
      )
    } else {
      return (
        <Message success
          header={t("register.success.development.header")}
          content={t("register.success.development.content")}
        />
      )
    }
  }


	render() {
		if(this.state.showErrors || this.state.showSuccess){
			return (
				<Segment basic>
					<Container>
						<Form error={this.state.showErrors} success={this.state.showSuccess}>
							<Message error header={this.state.message}/>
							{this.successMessage()}
						</Form>
					</Container>
				</Segment>
			)
		}
		return (
			<Segment basic loading={true} style={{height: '100px'}}/>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookCallback)
