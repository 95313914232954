import React, { Component } from 'react'
import {Segment, Container, Header, Grid, Icon} from 'semantic-ui-react';
import {t} from "../../i18n";

const PrivacyPolicyFile = "/FiberPay_Polityka_Prywatności_20200818.pdf";

export default class PrivacyPolicy extends Component {

	render() {
		return (
			<Container>
				<Segment basic>
					<Header as='h2'>{t("privacyPolicy.1")}</Header>

					<Grid stackable style={{ paddingTop: "30px", paddingBottom: "30px" }}>
						<Grid.Row columns={2} style={{ marginBottom: "30px" }}>
							<Grid.Column width={3} verticalAlign='top'>
								<Segment inverted basic textAlign='center' padded='very' id='services-segment'>
									<Icon name="umbrella" size='huge' id="icon-white" />
								</Segment>
							</Grid.Column>
							<Grid.Column width={13}>
								<p style={{ fontWeight: "bold", color: "#067ab8"}}>{t("privacyPolicy.2")}</p>
								<p>{t("privacyPolicy.3")}</p>
								<a href={PrivacyPolicyFile}>{t("privacyPolicy.docLink")}</a>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</Container>
		)
	}
}