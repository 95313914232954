import React, { Component } from 'react';
import { Table, Icon, Menu } from "semantic-ui-react";
import _ from 'lodash';

// Komponent do użycia ogólnego w stopce tabeli.
// Wymaga props:
// - count - liczba wszystkich elementów
// - colSpan - liczba połączonych kolumn
// - offset - offset listy potrzebny do zerowania paginacji
// - paginationChange - funkcja pobierająca elementy na podstawie parametru offset,
// który nastepnie powinna ustawiać jako state w komponencie nadrzędnym.
// Opcjonalne props:
// - limit - gdy limit w komponencie nadrzędnym(parent) jest inny niż default = 10
// - range - ustalenie zasięgu paginacji w obie strony (default = 2)


export default class FooterPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            pages: [],
            pageNo: 1,
            pagesCount: 0,
            range: 2,
            mobile: window.innerWidth < 500,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.pageNo !== this.state.pageNo) {
            this.createPages();
        }

        if (prevProps.count !== this.props.count) {
            let pagesCount = Math.ceil(this.props.count / this.state.limit);
            this.setState({ pagesCount: pagesCount }, () => this.createPages())
        }

        if (this.props.limit && this.state.limit !== this.props.limit) {
            this.setState({ limit: this.props.limit })
        }

        if (this.props.range && this.state.range !== this.props.range) {
            this.setState({ range: this.props.range })
        }

        if (prevProps.offset !== this.props.offset && this.props.offset === 0) {
            this.setState({ pageNo: 1 })
        }

        if (prevProps.count !== this.props.count && this.props.offset === this.props.count && this.props.offset !== 0) {
            let pageNo = this.state.pageNo - 1;
            let offset = (pageNo - 1) * this.state.limit;
            this.setState({ pageNo: pageNo }, () => this.props.paginationChange(offset))
        }
    }

    handlePageChange(e, data) {
        if (!data.active) {
            let pageNo = this.state.pageNo;
            if (data.name === 'right') {
                pageNo += 1;
            } else if (data.name === 'left') {
                pageNo -= 1;
            } else {
                pageNo = parseInt(data.name);
            }
            let offset = (pageNo - 1) * this.state.limit;
            this.setState({ pageNo: pageNo }, () => this.props.paginationChange(offset))
        }
    }

    createPages() {
        let range = this.state.range;
        let pageNo = this.state.pageNo;
        let first = pageNo - range;
        let last = pageNo + range;
        if (pageNo <= range) {
            first = 1;
            last = first + (2 * range);
        }
        if (last > this.state.pagesCount) {
            last = this.state.pagesCount;
            first = last - 2 * range;
            if (first < 1)
                first = 1;
        }
        let pages = [];
        for (let i = first; i <= last; i++) {
            pages.push(i);
        }
        this.setState({ pages: pages });
    }


    render() {

        return (
            <Table.Row>
                <Table.HeaderCell id='pagination-th' colSpan={this.props.colSpan}>
                    <Menu floated='right' size={this.state.mobile ? 'mini' : 'tiny'}>
                        <Menu.Item content={<Icon name='left chevron' style={{ margin: 0 }} />}
                                   name='left'
                                   onClick={this.handlePageChange.bind(this)}
                                   disabled={this.state.pageNo === 1}
                        />
                        {_.map(this.state.pages, (value) => (
                            <Menu.Item key={value} name={value.toString()}
                                       onClick={this.handlePageChange.bind(this)}
                                       active={value === this.state.pageNo}>
                                {value}
                            </Menu.Item>
                        ))}
                        <Menu.Item name='right'
                                   icon={<Icon name='right chevron' style={{ margin: 0 }} />}
                                   content=''
                                   onClick={this.handlePageChange.bind(this)}
                                   disabled={this.state.pageNo >= this.state.pagesCount}
                        />
                    </Menu>
                </Table.HeaderCell>
            </Table.Row>
        );
    }
}