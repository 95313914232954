import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Container, Divider, Label, Segment, Table} from "semantic-ui-react";
import Statuses from "./statuses";
import LabelStatuses from "./lables";
import {t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getSplitOrderItemByCode: state.getSplitOrderItemByCode
  };
}

class SplitDetailsItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      loading: true,
    }
  }

  componentDidMount() {
    this.props.actions.getSplitOrderItemByCode(this.props.match.params.code);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getSplitOrderItemByCode !== this.props.getSplitOrderItemByCode) {
      let item = this.props.getSplitOrderItemByCode;
      if (item) {
        this.setState({item: item.data, loading: false})
      }
    }
  }

  render() {
    let item = this.state.item;
    if (item) {
      let status = item.status;
      return (
        <Segment basic>
          <Container>
            <Segment basic>
              <Divider horizontal>{t("orders.splitItemDetails")}</Divider>
              <Segment>
                <Label attached='top'>{t("orders.splitItem")} #{item.code}</Label>
                <Table compact celled basic='very' columns={2}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.status")}</Table.Cell>
                      <Table.Cell>
                        <LabelStatuses status={status}/>
                        &nbsp;
                        <Statuses status={status}/>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.parentCode")}</Table.Cell>
                      <Table.Cell>{item.parentCode}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.amount")}</Table.Cell>
                      <Table.Cell>{parseFloat(item.amount).toFixed(2)} {item.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.feeAmount")}</Table.Cell>
                      <Table.Cell>{parseFloat(item.feeAmountSum).toFixed(2)} {item.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.receiver")}</Table.Cell>
                      <Table.Cell>{item.toName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.iban")}</Table.Cell>
                      <Table.Cell>{item.toIban}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.description")}</Table.Cell>
                      <Table.Cell>{item.description}</Table.Cell>
                    </Table.Row>
                    {item.metadata && <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.metadata")}</Table.Cell>
                      <Table.Cell>{item.metadata}</Table.Cell>
                    </Table.Row>}
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.created")}</Table.Cell>
                      <Table.Cell>{item.createdAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.updated")}</Table.Cell>
                      <Table.Cell>{item.updatedAt}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            </Segment>
          </Container>
        </Segment>
      )
    } else {
      return <Segment basic loading={this.state.loading}/>
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(SplitDetailsItems)
