import React from 'react'
import { Segment, Container, Divider, Form, Input, Message, Button, Header } from 'semantic-ui-react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import {t} from "../../i18n";
import FormComponent from '../containers/formcomponent';

function mapStateToProps(state) {
  return {
    userPassword: state.changeUserPassword,
  };
}

class UserSettings extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ...this.state
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.userPassword !== this.props.userPassword){
			let userPassword = this.props.userPassword;
			this.validateForm(userPassword, () => {
				if(userPassword.data){
					this.setState({message: userPassword.data.message, showSuccess: true, loading: false})
				}
			})
			
		}
  }

	callChangeUserPassword(){
		let data = {
			newPassword: this.state.newPassword,
			repeatPassword: this.state.repeatPassword,
		};
		this.props.actions.changeUserPassword(data);
	}


  render() {
    return (
      <Container as={Segment} basic loading={this.state.loading}>
        <Divider horizontal className='list-title'>{t("settings.title")}</Divider>
        <Segment className="loginSegment" style={{ minWidth: "40%", margin: 'auto' }} basic>
          <Header textAlign='center'>{t('settings.changepassword')}</Header>
          <Form id='new-password-form' error={this.state.showErrors} success={this.state.showSuccess}
            onSubmit={this.handleSubmitForm.bind(this, () => this.callChangeUserPassword())}>
            <Form.Field error={this.state.formErrorsKeys.newPassword}>
              <label for='new-password'>{t('New password')}</label>
              <Input required
                placeholder={t('New password')}
                id='new-password'
                autocomplete="new-password"
                name='newPassword'
                type='password'
                value={this.state.newPassword}
                onChange={this.handleValueChange.bind(this)}
              />
            </Form.Field>
            <Form.Field error={this.state.formErrorsKeys.repeatPassword}>
              <label for='new-password-repeat'>{t('Repeat new password')}</label>
              <Input required
                placeholder={t('Repeat new password')}
                id='new-password-repeat'
                autocomplete="new-password"
                name='repeatPassword'
                type='password'
                value={this.state.repeatPassword}
                onChange={this.handleValueChange.bind(this)}
              />
            </Form.Field>
            <Message error
              header={this.state.formErrorsMessage}
              list={this.state.formErrors}
            />
            <Message success
              header={this.state.message}
            />
            <Button primary type="submit" disabled={this.state.disabled}>{t("Change password")}</Button>
          </Form>
        </Segment>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
