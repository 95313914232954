import React, {Component} from 'react';
import {Button, Container, Dimmer, Divider, Loader, Message, Segment, Table,} from 'semantic-ui-react';
import {Pagination} from '../utils/pagination';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import history from '../../history';
import {l, t} from '../../i18n';

const downloadReport = (data, name) => {
  const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
  // save the file
  const url = URL.createObjectURL(blob);

// 3. Create an anchor element and set its href to the Blob URL
  const a = document.createElement('a');
  a.href = url;
  a.download = name;

// 4. Append the anchor to the document, trigger a click on it, and then remove it
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

// 5. Release the Blob URL
  URL.revokeObjectURL(url);
}

function mapStateToProps(state) {
  return {
    invoice: state.getInvoiceDetails,
    orderItems: state.getOrderItemsForInvoice,
    orderItemsReport: state.getInvoiceOrderItemsReport,
  };
}

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      orderItems: {
        data: [],
        meta: {
          count: '',
          current_page: '',
          per_page: '',
          last_page: '',
        },
      },
      loading: true,
      error: null,
      page: 1,
      pages: [],
    };
  }

  goToOrderDetails(data) {
    const { type, parentCode } = data;
    history.push(l + `/panel/orders/${type.split('_')[0]}/${parentCode}`);
  }

  componentDidMount() {
    const { code } = this.props.match.params;
    this.props.actions.getInvoiceDetails(code);
    this.props.actions.getOrderItemsForInvoice(code);
  }

  componentDidUpdate(prevProps) {
    const { invoice, orderItems } = this.props;
    if (prevProps.invoice !== invoice) {
      if (invoice.data) {
        this.setState({
          loading: false,
          error: null,
          invoice: invoice.data,
        });
      } else {
        this.setState({ error: invoice.reason, loading: false });
      }
    }
    if (prevProps.orderItems !== orderItems) {
      if (orderItems.data) {
        this.setState({
          loading: false,
          error: null,
          orderItems,
        });
      } else {
        this.setState({ error: orderItems.reason, loading: false });
      }
    }

    if (prevProps.orderItemsReport !== this.props.orderItemsReport) {
      const data = this.props.orderItemsReport;
      if (!data) {
        return
      }
      const filename = this.state.invoice.number.replaceAll('/', '_') + '_items_report.csv';
      downloadReport(data, filename)
    }

  }

  callPagination(options) {
    this.setState(state => ({
      orderItems: {
        ...state.orderItems,
        meta: {
          current_page: options.pageNo,
          page: options.name,
          pages: options.pages,
          last_page: options.pagesCount,
          offset: options.offset,
          per_page: options.limit,
        },
      },
    }));

    this.props.actions.getOrderItemsForInvoice(this.state.invoice.code, { page: options.pageNo });
  }

  renderError() {
    return <Message negative>{this.state.error}</Message>;
  }

  renderDetails() {
    const { invoice, orderItems } = this.state;

    return (
      <>
        {invoice && (
          <>
            <Divider className='list-title' horizontal>{`${t('invoiceDetails.title')} #${
              invoice.code
            }`}</Divider>
            <Table compact columns='5'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign={'center'}>{t('invoices.number')}</Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>
                    {t('invoices.billingPeriod')}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>
                    {t('invoices.issueDate')}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>{t('invoices.amount')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign={'center'}>{invoice.number}</Table.Cell>
                  <Table.Cell textAlign={'center'}>{invoice.billingPeriod}</Table.Cell>
                  <Table.Cell textAlign={'center'}>{invoice.issueDate}</Table.Cell>
                  <Table.Cell
                    textAlign={'center'}
                  >{`${invoice.amount} ${invoice.currency}`}</Table.Cell>
                  <Table.Cell textAlign={'center'}>
                    <a href={invoice.downloadUrl}>
                      <Button>{t('invoiceDetails.download')}</Button>
                    </a>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider horizontal section>
              {t('invoiceDetails.orderItems')}
            </Divider>
            <Table compact columns='5' selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign={'center'}>
                    {t('invoiceDetails.code')}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>
                    {t('invoiceDetails.type')}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>{t('invoices.amount')}</Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>{t('invoiceDetails.feeAmount')}</Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>
                    {t('invoiceDetails.updatedAt')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {orderItems.data.map(({ data }) => (
                  <Table.Row key={data.code} onClick={() => this.goToOrderDetails(data)}>
                    <Table.Cell textAlign={'center'}>{data.code}</Table.Cell>
                    <Table.Cell textAlign={'center'}>{data.type}</Table.Cell>
                    <Table.Cell textAlign={'center'}>
                      {data.amount} {data.currency}
                    </Table.Cell>
                    <Table.Cell textAlign={'center'}>
                      {data.fees[0].amount} {data.currency}
                    </Table.Cell>
                    <Table.Cell textAlign={'center'}>{data.updatedAt}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Pagination
                  columnNumber={5}
                  showFunction={this.callPagination.bind(this)}
                  pageNo={orderItems.meta.current_page}
                  pages={this.state.pages}
                  pagesCount={orderItems.meta.last_page}
                  limit={orderItems.meta.per_page}
                />
              </Table.Footer>
            </Table>
            <Segment basic textAlign={'right'}>
              <Button onClick={() => this.props.actions.getInvoiceOrderItemsReport(invoice.code)}>
                {t('invoiceDetails.downloadOrderItemsReport')}
              </Button>
            </Segment>
          </>
        )}
      </>
    );
  }

  render() {
    return (
      <Container>
        <Segment basic>
          <Dimmer inverted active={this.state.loading}>
            <Loader content={t('system.loading')} />
          </Dimmer>
          {this.state.error ? this.renderError() : this.renderDetails()}
        </Segment>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
