import React, { Component } from 'react'
import { Segment, Container, Button, Table, Label, Grid, Divider } from 'semantic-ui-react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import {t} from "../../i18n";
import CreateDeviceModal from './CreateDeviceModal';

const providerName = {
  google_auth: 'Google Authenticator',
  fiber_auth: 'FiberAuth',
}

function mapStateToProps(state) {
  return {
    devices: state.get2FADevices,
  };
}

class TwoFactorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      devices: [],
    };
  }

  componentDidMount() {
    this.get2FADevices();
  }

  componentDidUpdate(prevProps) {
    const { devices } = this.props;
    if (prevProps.devices !== devices) {
      this.setState({loading: false, devices, totalNumberOfKeys: devices.length});
    }
  }

  get2FADevices() {
    this.setState({ loading: true }, () => this.props.actions.get2FADevices())
  }

  handleSetDefault(deviceCode) {
    this.props.actions.setDefault2FADevice(deviceCode);
  }

  handleRemove(deviceCode) {
    this.props.actions.remove2FADevice(deviceCode);
  }

  deviceRow(device) {
    const { provider, code, name, createdAt, isDefault } = device;

    const defaultCell = isDefault ? <Label size='large' color='green'>{t('2fa.default')}</Label>
      : <Button basic positive onClick={() => this.handleSetDefault(code)}>{t('2fa.setDefault')}</Button>

    return (
      <Table.Row key={code} textAlign={"center"}>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{providerName[provider] || provider}</Table.Cell>
        <Table.Cell>{createdAt}</Table.Cell>
        <Table.Cell>{defaultCell}</Table.Cell>
        <Table.Cell>{provider !== 'email' && <Button negative basic onClick={() => this.handleRemove(code)}>{t('2fa.remove')}</Button>}</Table.Cell>
      </Table.Row>
    )
  }

  render() {
    return (
      <Container as={Segment} basic loading={this.state.loading}>
        <Divider horizontal className='list-title'>{t("2fa.twoFactorAuthenticationMethods")}</Divider>
        <Grid stackable>
          <Grid.Row>
            <CreateDeviceModal />
          </Grid.Row>
          <Grid.Row>
            <Table compact>
              <Table.Header>
                <Table.Row textAlign={"center"}>
                  <Table.HeaderCell>{t("2fa.name")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("2fa.type")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("2fa.createdDate")}</Table.HeaderCell>
                  <Table.HeaderCell/>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.devices.map(device => this.deviceRow(device))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth)
