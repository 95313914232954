import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Container, Grid, Header, Icon } from 'semantic-ui-react';
import {l, t} from "../../i18n";
import GitInfo from 'react-git-info/macro';

const Footer = () => (
	<Segment inverted vertical id="footer">
		<Container>
			<Grid stackable inverted className='equal height'>
				<Grid.Column width={5}>
					<Header as='h4'>{t("footer.aboutUs.header")}</Header>
					FiberPay sp. z o.o.<br />
					ul. Sienna 86/47 <br />
					00-815 {t("footer.aboutUs.warsaw")}<br /><br />
					<a href="mailto:info@fiberpay.pl"><Icon name={"mail"} />info@fiberpay.pl</a>
				</Grid.Column>
				<Grid.Column width={6}>
					<Header as='h4'>{t("footer.companyData.header")}</Header>
					NIP: 7010634566<br />
					REGON: 36589948900000<br />
					{t("footer.companyData.1")}MIP28/2019 <br />
					KRS: 0000647662<br />
					{t("footer.companyData.2")}
				</Grid.Column>
				<Grid.Column width={5}>
					<Header as='h4'>{t("footer.information.header")}</Header>
					<Link to={l+'/services'}>{t("footer.information.1")}</Link><br />
					<Link to={l+'/privacy-policy'}>{t("footer.information.2")}</Link><br />
					<a href='https://github.com/fiberpay/api' target="_blank" rel="noopener noreferrer">
						{t("footer.apiDocumentation")}</a>
					<div style={{
						opacity: 0.25,
						paddingTop: '10px',
						pointerEvents: 'none' }}
					>
						{GitInfo().commit.shortHash}
					</div>
				</Grid.Column>
			</Grid>
		</Container>
	</Segment>
)

export default Footer;
