import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Grid, Modal, Form, Select, Segment, Header } from 'semantic-ui-react';
import * as actions from '../../actions';
import { ENVIRONMENT } from '../../constants';
import { t } from '../../i18n';

const partnerTypesOptions = [
  { text: t('kyc.type.individual'), value: 'individual', key: 'individual' },
  {
    text: t('kyc.type.soleProprietorship'),
    value: 'sole_proprietorship',
    key: 'sole_proprietorship',
  },
  { text: t('kyc.type.company'), value: 'company', key: 'company' },
];

function mapStateToProps(state) {
  return {
    updatePartner: state.updatePartner,
  };
}

class PartnerCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerType: '',
      errorMessage: '',
      isOpen: false,
    };
  }

  canPartnerTypeBeChanged() {
    const nonupdatableAttributes = [
      'firstName',
      'lastName',
      'companyName',
      'personalIdentityNumber',
      'taxIdNumber',
      'regon',
    ];
    const details = this.props.partner?.details;
    if (details && nonupdatableAttributes.some(attr => details[attr])) {
      return false;
    }

    return true;
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      partnerType: this.props.partner.type,
      errorMessage: '',
    });
  };

  componentDidUpdate(prevProps) {
    const { updatePartner } = this.props;
    if (updatePartner !== prevProps.updatePartner) {
      if (updatePartner.data) {
        window.location.href = updatePartner.data.kycUrl;
      } else if (updatePartner.reason) {
        this.setState({ errorMessage: updatePartner.reason });
      }
    }
  }

  handleUpdatePartner = e => {
    e.preventDefault();
    if (this.canCreatePartner()) {
      this.props.actions.updatePartner({ type: this.state.partnerType });
    }
  };

  canCreatePartner = () => {
    return !!this.state.partnerType;
  };

  canVerificationBeContinued = () => {
    const { partner } = this.props;
    return partner && !['accepted', 'rejected'].includes(partner.kycStatus) && partner.kycUrl;
  };

  handleChangePartnerType = (e, { value }) => this.setState({ partnerType: value });

  renderModal() {
    const { partner } = this.props;

    const trigger = (
      <Button onClick={this.toggleOpen} primary={!partner.type}>
        {t(`kyc.actions.${partner.type ? 'update' : 'create'}Partner`)}
      </Button>
    );

    return (
      <>
        {['development', 'local'].includes(ENVIRONMENT) && (
          <div style={{ marginBottom: '2rem' }}>
            <Button positive onClick={() => this.props.actions.simulatePartnerAccepted()}>
              Symuluj pozytywną weryfikację konta
            </Button>
          </div>
        )}
        <Modal trigger={trigger} open={this.state.isOpen} onClose={this.toggleOpen}>
          <Header content={t('kyc.modal.header')} textAlign='center' />
          <Modal.Content>
            {this.canPartnerTypeBeChanged() && (
              <Form onSubmit={this.handleUpdatePartner}>
                <Segment basic textAlign='center'>
                  <Select
                    onChange={this.handleChangePartnerType}
                    placeholder={t('kyc.type')}
                    options={partnerTypesOptions}
                  />
                </Segment>
              </Form>
            )}
            <Modal.Description>
              <Segment basic textAlign='center'>
                {t('kyc.modal.description')}
              </Segment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleUpdatePartner}
              content={t('continue')}
              primary
              disabled={!this.canCreatePartner()}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }

  render() {
    const { partner } = this.props;
    return (
      <>
        {this.canVerificationBeContinued() && (
          <Grid.Column>
            <a href={partner.kycUrl}>
              <Button primary>{t('continue')}</Button>
            </a>
          </Grid.Column>
        )}
        <Grid.Column>{this.renderModal()}</Grid.Column>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCreator);
