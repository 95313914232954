import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Button, Form, Grid, Icon, Image, Message, Modal, Segment } from 'semantic-ui-react';
import { t } from '../../../i18n';
import ReplaceDeviceAccordion from './ReplaceDeviceAccordion';
import EmailResender from './EmailResender';
import ActionStatusChecker from './ActionStatusChecker';

const mapStateToProps = state => ({
  action: state.get2FAAction,
});

const initialState = {
  action: null,
  resource: null,
  loading: false,
  otp: '',
  error: '',
};

class TwoFactorAuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.action !== this.props.action) {
      const { action, resource, type, error } = this.props.action;

      this.setState({
        action,
        loading: false,
        resource,
        type: type || prevState.type,
        error: error || '',
        otp: '',
      });

      if (error) {
        this.setState({ error, loading: false });
        return;
      }

      if (['executed', 'declined'].some(status => status === action.status)) {
        this.setState(initialState);
        return;
      }
    }
  }

  fiberAuthContent() {
    const { action, loading, error, type } = this.state;
    const description =
      action.type === 'pair_device'
        ? t('2fa.pairingDescription.fiberAuth')
        : t('2fa.actionDescription.fiberAuth');
    return (
      <Grid centered>
        <Grid.Row>
          <Icon name='mobile alternate' size='massive' />
        </Grid.Row>
        {action.type === 'pair_device' && <Grid.Row>{action.pairingCode}</Grid.Row>}
        <Grid.Row>
          {loading ? (
            <Segment basic loading />
          ) : (
            error && <Icon name='close' size='big' color='red' />
          )}
        </Grid.Row>
        <Grid.Row>
          {error ? (
            error
          ) : (
            <ActionStatusChecker action={action} type={type} description={description} />
          )}
        </Grid.Row>
        {!!action.alternativeDevices.length && (
          <Grid.Row>
            <ReplaceDeviceAccordion
              action={action}
              handleDeviceChange={() => this.handleDeviceChange()}
            />
          </Grid.Row>
        )}
      </Grid>
    );
  }

  handleSubmit = () => {
    const { otp, action, type } = this.state;
    this.props.actions.handle2FAAction({ otp }, action.code, type);
  };

  handleCancel = () => {
    this.setState(initialState);
    if (this.props.action.action.type === 'login') {
        this.props.actions.handleCancelLogin();
    }
  };

  handleDeviceChange = () => {
    this.setState({ loading: true });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, error: '' });
  };

  isValidOtp = () => /^[\d]{6}$/.test(this.state.otp);

  googleAuthContent() {
    const { action, error, otp } = this.state;
    return (
      <Grid centered>
        <Grid.Row>
          {action.type === 'pair_device' ? (
            <Image src={action.pairingQrCodeUrl} wrapped />
          ) : (
            <Icon name='mobile alternate' size='massive' />
          )}
        </Grid.Row>
        <Grid.Row>
          <b>{action.pairingCode}</b>
        </Grid.Row>
        <Grid.Row>
          {t(
            action.type === 'pair_device'
              ? '2fa.pairingDescription.googleAuth'
              : '2fa.actionDescription.googleAuth',
          )}
        </Grid.Row>
        <Grid.Row>
          <Form onSubmit={this.handleSubmit}>
            <Form.Input
              placeholder={t('code')}
              name='otp'
              onChange={this.handleChange}
              value={otp}
              required
            />
            <button type='submit' disabled={!this.isValidOtp()} hidden />
          </Form>
        </Grid.Row>
        <Grid.Row>{error && <Message error>{error}</Message>}</Grid.Row>

        {!!action.alternativeDevices.length && (
          <Grid.Row>
            <ReplaceDeviceAccordion
              action={this.state.action}
              handleDeviceChange={this.handleDeviceChange}
            />
          </Grid.Row>
        )}
      </Grid>
    );
  }

  emailAuthContent() {
    const { action, error, type } = this.state;
    return (
      <Grid centered>
        <Grid.Row>
          <Icon name='mail outline' size='massive' />
        </Grid.Row>
        {error ? (
          <Grid.Row>
            <Message error>{error}</Message>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row>
              <ActionStatusChecker
                action={action}
                type={type}
                description={t('2fa.actionDescription.email')}
              />
            </Grid.Row>
            <Grid.Row>
              <EmailResender action={action} type={type} />
            </Grid.Row>
            {!!action.alternativeDevices.length && (
              <Grid.Row>
                <ReplaceDeviceAccordion
                  action={this.state.action}
                  handleDeviceChange={this.handleDeviceChange}
                />
              </Grid.Row>
            )}
          </>
        )}
      </Grid>
    );
  }

  modalContent() {
    if (!this.state.action) return;

    if (this.state.loading) return <Segment basic loading style={{ height: '400px' }} />;

    switch (this.state.action.device.provider) {
      case 'google_auth':
        return this.googleAuthContent();
      case 'email':
        return this.emailAuthContent();
      case 'fiber_auth':
        return this.fiberAuthContent();
      default:
        break;
    }
  }

  render() {
    const { action } = this.state;
    return (
      <Modal open={!!action} size='small'>
        <Modal.Header style={{ textAlign: 'center' }}>
          {action?.type === 'pair_device' ? 'Dodaj nowe urządzenie' : action?.description.type}
        </Modal.Header>
        <Modal.Content>{this.modalContent()}</Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={this.handleCancel} content={t('cancel')} />
          {action?.device.provider === 'google_auth' && (
            <Button
              positive
              disabled={!this.isValidOtp()}
              onClick={this.handleSubmit}
              content={t('2fa.verifyCode')}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

const handleCancelLogin = () => ({
  type: 'USER_LOGIN',
  payload: { data: { loading: false } },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions, handleCancelLogin }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthModal);
