import React from 'react'
import {
  Segment, Container, Header, Form, Divider,
  Button, Dimmer, Loader, Input, Message
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { SHOW_FACEBOOK } from '../../constants';
import {t} from "../../i18n";
import FormComponent from '../containers/formcomponent';
import FacebookButton from '../utils/FacebookButton';

function mapStateToProps(state) {
  return {
    userRegister: state.userRegister,
  };
}

class Register extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordVisible: false,
      ...this.state
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userRegister !== this.props.userRegister) {
      let register = this.props.userRegister;
      this.validateForm(register, () => {
        this.setState({
          showSuccess: true,
          loading: false,
          showErrors: false
        });
      })
    }
  }

  callRegister() {
    this.props.actions.userRegister({
      email: this.state.email,
      password: this.state.password,
    });
  }

  render() {
    return (
      <div style={{ minHeight: 'calc(100vh - 240px)', display: 'flex', alignItems: 'center' }}>
        <Container>
          <Segment className="loginSegment" style={{ minWidth: "40%", margin: 'auto' }} basic>
            <Dimmer inverted active={this.state.loading}>
              <Loader content={t("system.loading")} />
            </Dimmer>
            <Header as="h3" textAlign='center'>{t("register.header")}</Header>
            <Divider section />
            <Form id='register-form' onSubmit={this.handleSubmitForm.bind(this, () => this.callRegister())}
              error={this.state.showErrors}
              success={this.state.showSuccess}>
              <Form.Field error={this.state.formErrorsKeys.email}>
                <label for='email'>{t("emailAddress")}</label>
                <Input placeholder={t("emailAddress")}
                  id='email'
                  name='email'
                  type='email'
                  onChange={this.handleValueChange.bind(this)}
                  value={this.state.email}
                  required
                />
              </Form.Field>
              <Form.Field error={this.state.formErrorsKeys.password}>
                <label for='new-password'>{t("password")}</label>
                <Input placeholder={t("password")}
                  id='new-password'
                  autocomplete="new-password"
                  name='password'
                  type={this.state.passwordVisible ? "text" : "password"}
                  onChange={this.handleValueChange.bind(this)}
                  value={this.state.password}
                  required
                  labelPosition='right'
							    label={
                    <Button 
                      type='button' 
                      icon={this.state.passwordVisible ? 'eye' : 'eye slash'}
                      onClick={()=> this.setState({passwordVisible: !this.state.passwordVisible})}
                    />}
                />
              </Form.Field>
              <Message success
                header={t("register.success.header")}
                content={t("register.success.content")}
              />
              <Message
                error
                header={this.state.formErrorsMessage}
                list={this.state.formErrors}
              />
              <div style={{textAlign: 'center'}}>
                <Button primary type="submit" disabled={this.state.disabled}>{t("register.register")}</Button>
              </div>
            </Form>
            {SHOW_FACEBOOK && <div style={{textAlign: 'center'}}>
              <Divider />
              <FacebookButton type='register' content={t("register.viaFacebook")}/>
            </div>}
          </Segment>
        </Container>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
