import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Accordion, Button, Form } from 'semantic-ui-react';
import { t } from '../../../i18n';
import FormComponent from '../../containers/formcomponent';

const mapStateToProps = state => ({
  devices: state.get2FADevices,
});

class ReplaceDeviceAccordion extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      deviceCode: '',
    };
  }

  getDeviceLabel = (name, provider) => {
    switch (provider) {
      case 'email':
        return 'email';

      case 'fiber_auth':
        return `${name} - FiberToken`;

      case 'google_auth':
        return `${name} - Google Authenticator`;

      default:
        return `${name}`;
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { deviceCode } = this.state;
    this.setState({ isOpen: false });
    this.props.handleDeviceChange();
    this.props.actions.replaceDevice(this.props.action.code, { deviceCode });
  };

  toggleOpen = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  render() {
    return (
      <Accordion>
        <Accordion.Title
          active={this.state.isOpen}
          content={t('2fa.modal.accordion.title')}
          onClick={this.toggleOpen}
        />
        <Accordion.Content active={this.state.isOpen}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Select
              placeholder={t('2fa.modal.accordion.placeholder')}
              name='deviceCode'
              options={this.props.action.alternativeDevices.map(({ code, name, provider }) => ({
                key: code,
                value: code,
                text: this.getDeviceLabel(name, provider),
              }))}
              onChange={this.handleValueChange.bind(this)}
              required
            />
            <Button
              basic
              primary
              type='submit'
              content={t('2fa.modal.accordion.button')}
              disabled={!this.state.deviceCode}
            />
          </Form>
        </Accordion.Content>
      </Accordion>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceDeviceAccordion);
