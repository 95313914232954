import React, {Component} from 'react'
import {
  Container, Segment, Table, Dimmer, Loader, Divider, Grid, Accordion, Icon, Form, Input, Label
} from 'semantic-ui-react';
import _ from 'lodash';
import {Pagination} from "../utils/pagination";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import history from "../../history";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {l, t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getCallback: state.getCallback
  };
}

class CallbackLogs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      options: [
        {text: t("filter.options.choose"), value: 'choose date type'},
        {text: t("filter.options.equal"), value: 'simple_date'},
        {text: t("filter.options.between"), value: 'is_between'},
        {text: t("filter.options.last"), value: 'last'},
        {text: t("filter.options.before"), value: 'before_date'},
        {text: t("filter.options.after"), value: 'after_date'},
      ],
      activeIndexFilter: 0,
      activeIndexType: 0,
      activeIndexDate: 0,
      activeIndexResponseStatus: 0,
      activeIndexStatus: 0,
      callbackStatus: [
        {text: t("callbackLogs.status.new"), value: 'new', checked: false},
        {text: t("callbackLogs.status.error"), value: 'error', checked: false},
        {text: t("callbackLogs.status.processed"), value: 'processed', checked: false},
      ],
      orderTypes: [
        {text: t("callbackLogs.type.collectOrderItemReceived"), value: 'collect_order_item_received', checked: false},
        {text: t("callbackLogs.type.splitOrderItemSent"), value: 'split_order_item_sent', checked: false},
        {text: t("callbackLogs.type.directOrderSent"), value: 'direct_order_sent', checked: false},
        {text: t("callbackLogs.type.forwardOrderReceived"), value: 'forward_order_received', checked: false},
        {text: t("callbackLogs.type.forwardOrderTargetSent"), value: 'forward_order_target_sent', checked: false},
        {text: t("callbackLogs.type.forwardOrderBrokerSent"), value: 'forward_order_broker_sent', checked: false},
      ],
      data: [],
      meta: {
        count: '',
        current_page: '',
        per_page: '',
        last_page: '',
      },
      loading: true,
      page: 1,
      nextPage: '',
      prevPage: '',
      pages: [],
      status: '',
      showErrorMessage: false,
      search: '',
      filters: {},
    }
  }

  componentDidMount() {
    this.props.actions.getCallback(this.state.page);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getCallback !== this.props.getCallback) {
      let callback = this.props.getCallback;
      if (callback) {
        this.setState({
          loading: false,
          data: callback.data,
          meta: callback.meta
        })
      }
    }

    if (this.state.find) {
      this.find();
    }
  }

  getRequestDetails(code) {
    history.push(l + '/panel/logs/callbacks/' + code);
  }

  callPagination(options) {
    this.setState({
      current_page: options.pageNo,
      page: options.name,
      pages: options.pages,
      last_page: options.pagesCount,
      offset: options.offset,
      per_page: options.limit
    });
    let params = options.params;
    if (this.state.status)
      params.status = this.state.status;

    let query = this.state.queryData;
    let data = {
      page: options.pageNo,
      ...query,
    }

    let search = this.state.search;
    if (search) {
      data = {
        ...data,
        search
      }
    }

    this.props.actions.getCallback(data);
  }

  getCode(data) {
    const { orderItem, shareRequest } = JSON.parse(data);
    return (orderItem || shareRequest).data.code;
  }

  changeDate = (e, date) => {
    if (null === date.value) {
      this.setState({date: null, find: true})
    } else {
      let datePicker = this.parseDate(date.value)
      this.setState({
        date: datePicker,
        find: true,
      });
    }
  };

  changeDateFrom = (e, date) => {
    if (null !== date.value) {
      let datePicker = this.parseDate(date.value)
      this.setState({
        dateFrom: datePicker,
      });
    } else {
      this.setState({dateFrom: null, find: true})
    }
  }

  changeDateTo = (e, date) => {
    if (null !== date.value) {
      let datePicker = this.parseDate(date.value)
      this.setState({
        dateTo: datePicker,
        find: true
      });
    } else {
      this.setState({dateTo: null, find: true})
    }
  }

  parseDate = (date) => date.getFullYear() + "-" + parseInt(date.getMonth() + 1) + "-" + date.getDate();
  handleChange = (e) => this.setState({dateType: e.target.value});


  find() {
    const {date, responseStatusCode, type, dateFrom, dateTo, dateType, callback, lastDate, search} = this.state;

    let filter = {};

    if (responseStatusCode) filter.response_status = responseStatusCode;
    if (callback) filter.status = callback;
    if (type) filter.type = type;
    if (search && search.length > 2) filter.search = search;

    if (dateType === 'last' && lastDate) filter.in_the_last = lastDate;
    else if (dateType === 'before_date' && date) filter.before_date = date;
    else if (dateType === 'after_date' && date) filter.after_date = date;
    else if (dateType === 'simple_date' && date) filter.specific_date = date;
    else if (dateType === 'is_between' && dateFrom && dateTo) filter.between_date = [dateFrom, dateTo];

    let queryData = {filter: filter};

    this.props.actions.getCallback(queryData);
    this.setState({queryData, find: false})
  }

  handleClickData = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndexDate} = this.state;
    const newIndex = activeIndexDate === index ? -1 : index;
    this.setState({activeIndexDate: newIndex});
  }

  handleClickResponseStatus = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndexResponseStatus} = this.state;
    const newIndex = activeIndexResponseStatus === index ? -1 : index;
    this.setState({activeIndexResponseStatus: newIndex});
  }

  handleClickCallbackStatus = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndexStatus} = this.state;
    const newIndex = activeIndexStatus === index ? -1 : index;
    this.setState({activeIndexStatus: newIndex});
  }

  handleClickType = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndexType} = this.state;
    const newIndex = activeIndexType === index ? -1 : index;
    this.setState({activeIndexType: newIndex});
  }

  handleFormInputTypeClick = (event, data) => {
    const orderTypes = this.state.orderTypes.map(type => {
      if (data.value === type.value) {
        type.checked = data.checked
      }
      return type;
    })

    this.orderTypes(orderTypes)
  }

  handleFormInputStatusClick = (event, data) => {
    const callbackStatus = this.state.callbackStatus.map(status => {
      if (data.value === status.value) {
        status.checked = data.checked
      }
      return status;
    })

    this.callbackStatuses(callbackStatus)
  }

  orderTypes = (callbackStatus) => {
    let data = []
    callbackStatus.filter(item => item.checked === true).map(filtered => {
      data.push(filtered.value)
      return data;
    })
    this.setState({type: data, find: true})
  }

  callbackStatuses = (callbackStatus) => {
    let data = []
    callbackStatus.filter(item => item.checked === true).map(filtered => {
      data.push(filtered.value)
      return data;
    })
    this.setState({callback: data, find: true})
  }

  handleInputChange = (e, data) => this.setState({lastDate: data.value, find: true})
  handleInputStatus = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      this.setState({responseStatusCode: value, find: true})
    } else {
      this.setState({responseStatusCode: null, find: true})
    }
  }

  handleClickFilter = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndexFilter} = this.state;
    const newIndex = activeIndexFilter === index ? -1 : index;
    this.setState({
      activeIndexFilter: newIndex,
    });
  }

  handleSearchChange(event) {
    let search = event.target.value
    this.setState({search, find: true})
  }

  labelStatuses(status) {
    if (status === 'new') {
      return <Label color="grey">{status}</Label>
    } else if (status === 'processed') {
      return <Label color="green">{status}</Label>
    } else if (status === 'error') {
      return <Label color="red">{status}</Label>
    }
  }

  render() {
    const {activeIndexFilter, activeIndexDate, activeIndexResponseStatus, activeIndexStatus, activeIndexType, dateType, options, data, meta} = this.state

    if (data && data.length === 1) {
      meta.current_page = 1;
      meta.last_page = 1;
      meta.per_page = 1
    }

    return (
      <Container>
        <Segment basic>
          <Dimmer inverted active={this.state.loading}>
            <Loader content={t("system.loading")}/>
          </Dimmer>
          <Divider horizontal className='list-title'>{t("callbackLogs.callbackRequestsLogs")}</Divider>
          <Grid stackable>
            <Grid.Row columns={2} className="filterBar">
              <Grid.Column floated='left' width={5}>
                <Form>
                  <Form.Group widths='equal'>
                    <Form.Field>
                      <Input
                        iconPosition={"left"}
                        icon={"search"}
                        size={"small"}
                        name='searchLogs'
                        placeholder={t("callbackLogs.searchByData")}
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
              <Grid.Column floated='right' width={5} style={{zIndex: 10}}>
                <Accordion styled style={{zIndex: 10}}>
                  <Accordion.Title
                    content={t("callbackLogs.filterRequests")}
                    active={activeIndexFilter === 1}
                    index={1}
                    onClick={this.handleClickFilter}
                    id="filter-list-header"
                    icon={"filter"}
                  />
                  <Accordion.Content active={activeIndexFilter === 1}>
                    <Accordion.Title
                      active={activeIndexDate === 1}
                      index={1}
                      content={t("callbackLogs.date")}
                      onClick={this.handleClickData}
                      className="filter-list-title"
                    />
                    <Accordion.Content active={activeIndexDate === 1} style={{zIndex: 200}} content={
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Form>
                              <Form.Group widths='equal'>
                                <Form.Field control='select' style={{padding: "5px"}} onChange={this.handleChange}>
                                  {options.map(option => (
                                    <option key={option.value} value={option.value}>{option.text}</option>
                                  ))}
                                </Form.Field>
                              </Form.Group>
                            </Form>
                          </Grid.Column>
                        </Grid.Row>
                        {dateType === 'last' &&
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Input
                                icon='search'
                                size={"mini"}
                                placeholder={t("callbackLogs.days")}
                                onChange={this.handleInputChange.bind(this)}
                                type="number"
                                min="1"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>}
                        {(dateType === 'simple_date' || dateType === 'before_date' || dateType === 'after_date') &&
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <SemanticDatepicker
                                locale="en-US"
                                onChange={this.changeDate}
                                datePickerOnly={true}
                                size={"mini"}
                                keepOpenOnSelect={true}
                                pointing={"left"}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>}
                        {dateType === 'is_between' &&
                        <Grid>
                          <div>From</div>
                          <Grid.Row>
                            <Grid.Column>
                              <SemanticDatepicker
                                locale="en-US"
                                onChange={this.changeDateFrom}
                                datePickerOnly={true}
                                size={"mini"}
                                keepOpenOnSelect={true}
                                pointing={"left"}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <div>To</div>
                          <Grid.Row>
                            <Grid.Column>
                              <SemanticDatepicker
                                locale="en-US"
                                onChange={this.changeDateTo}
                                datePickerOnly={true}
                                size={"mini"}
                                keepOpenOnSelect={true}
                                pointing={"left"}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>}
                      </Grid>
                    }/>

                    <Accordion.Title
                      active={activeIndexResponseStatus === 1}
                      index={1}
                      content={t("callbackLogs.responseStatus")}
                      onClick={this.handleClickResponseStatus}
                      className="filter-list-title"
                    />
                    <Accordion.Content
                      active={activeIndexResponseStatus === 1}
                      content={
                        <div>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column>
                                <Input
                                  icon='search'
                                  size={"mini"}
                                  placeholder={t("callbackLogs.statusCode")}
                                  onChange={this.handleInputStatus}
                                  type="number"
                                  min="2"
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </div>
                      }
                    />
                    <Accordion.Title
                      active={activeIndexType === 1}
                      index={1}
                      onClick={this.handleClickType}
                      content={t("callbackLogs.type")}
                      className="filter-list-title"
                    />
                    <Accordion.Content
                      active={activeIndexType === 1}
                      content={
                        <Form>
                          <Form.Group grouped>
                            {this.state.orderTypes.map((type) => (
                              <Form.Checkbox
                                key={type.value}
                                label={type.text}
                                value={type.value}
                                checked={this.state.orderTypes.checked}
                                onClick={this.handleFormInputTypeClick.bind(this)}/>
                            ))}
                          </Form.Group>
                        </Form>
                      }
                    />

                    <Accordion.Title
                      active={activeIndexStatus === 1}
                      index={1}
                      content={t("callbackLogs.status")}
                      onClick={this.handleClickCallbackStatus}
                      className="filter-list-title"
                    />
                    <Accordion.Content
                      active={activeIndexStatus === 1}
                      content={
                        <Form>
                          <Form.Group grouped>
                            {this.state.callbackStatus.map((status) => (
                              <Form.Checkbox
                                key={status.value}
                                label={status.text}
                                value={status.value}
                                checked={this.state.callbackStatus.checked}
                                onClick={this.handleFormInputStatusClick.bind(this)}/>
                            ))}
                          </Form.Group>
                        </Form>
                      }
                    />
                  </Accordion.Content>
                </Accordion>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table compact selectable={data.length > 0} columns='5' id="table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign={"center"}>{t("callbackLogs.code")}</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>{t("callbackLogs.responseStatus")}</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>{t("callbackLogs.type")}</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>{t("callbackLogs.created")}</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>{t("callbackLogs.status")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {data.length > 0 && <Table.Body>
              {_.map(data, ({code, createdAt, responseStatus, type, data, status}) => (
                <Table.Row key={code} onClick={this.getRequestDetails.bind(this, code)}>
                  <Table.Cell textAlign={"center"}>{this.getCode(data)}</Table.Cell>
                  <Table.Cell textAlign={"center"}>{responseStatus}</Table.Cell>
                  <Table.Cell textAlign={"center"}>{type}</Table.Cell>
                  <Table.Cell textAlign={"center"}>{createdAt}</Table.Cell>
                  <Table.Cell textAlign={"center"}>{this.labelStatuses(status)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>}

            {data.length === 0 && <Table.Body>
              <Table.Row textAlign="center">
                <Table.Cell colSpan={"4"}>
                  <Icon name={'search'}/>{t("callbackLogs.noResultFound")}
                </Table.Cell>
              </Table.Row>
            </Table.Body>}
            <Table.Footer>
              <Pagination columnNumber={5} showFunction={this.callPagination.bind(this)}
                          pageNo={meta.current_page} pages={this.state.pages}
                          pagesCount={meta.last_page} limit={meta.per_page}/>
            </Table.Footer>
          </Table>
        </Segment>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackLogs)
