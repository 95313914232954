import React, { Component } from 'react'
import { Container, Segment, Divider, Loader, Dimmer, Form, Button, Message } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {t} from "../../i18n";

function mapStateToProps(state) {
	return {
		exportCsv: state.exportWithdrawalCsv
	};
}

class ExportCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
	    loading: false,
      provider: '',
      error: '',
	  }

    this.providersOptions = [
      { key: 'bos', value: 'bos', text: 'Bank Ochrony Środowiska' },
      { key: 'ca', value: 'ca', text: 'Crédit Agricole' }
    ];
  }

  componentDidUpdate(prevProps) {
    if(prevProps.exportCsv !== this.props.exportCsv) {
      if(this.props.exportCsv.status === 'ERROR') {
          this.setState({ error: this.props.exportCsv.reason })
      } else {
        this.downloadFile();
      }
    }
  }

  downloadFile() {
    const { content, filename } = this.props.exportCsv;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', filename);
    link.click();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value, error: '' });
  }

  handleSubmit() {
    this.props.actions.exportCsv(this.state.provider)
  }

  render() {
    return (
        <Container>
				<Segment basic>
					<Dimmer inverted active={this.state.loading}>
						<Loader content={t('system.loading')} />
					</Dimmer>
					<Divider horizontal className='list-title'>{t('adminPanel.csv.title')}</Divider>
					<Divider hidden /><Divider hidden />
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <Form.Select
                    options={this.providersOptions}
                    placeholder={t('adminPanel.csv.provider')}
                    name='provider'
                    onChange={this.handleChange.bind(this)}
                    value={this.state.provider}
                />
                <Button primary type='submit' disabled={!this.state.provider}>{t('adminPanel.csv.export')}</Button>
            </Form>
            {this.state.error && <Message negative>{this.state.error}</Message>}
				</Segment>
			</Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCsv)

