import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { t } from '../../../i18n';

const RESEND_TIME = 60;

const INITIAL_STATE = {
  timeLeft: RESEND_TIME,
};

class EmailResender extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    clearInterval(this.handler);
  }

  startCountdown() {
    this.setState(INITIAL_STATE);
    this.handler = setInterval(() => {
      let { timeLeft } = this.state;
      if (timeLeft <= 0) {
        clearInterval(this.handler);
      } else {
        timeLeft--;
        this.setState({ timeLeft });
      }
    }, 1000);
  }

  canBeResend() {
    return this.state.timeLeft <= 0;
  }

  handleResend = () => {
    const { action, type } = this.props;
    this.props.actions.resendAuthEmail(action.code, type);
    this.startCountdown();
  };

  render() {
    const { timeLeft } = this.state;
    return (
      <>
        {this.canBeResend() ? (
          <p onClick={this.handleResend} style={{ cursor: 'pointer' }}>
            {t('2fa.sendAgain')}
          </p>
        ) : (
          t('2fa.resendTimeDesc', { timeLeft })
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(null, mapDispatchToProps)(EmailResender);
