import React, { Component } from 'react';
import { Image, Message, Label } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

function mapStateToProps(state) {
  return {
    payment: state.cashbillPayment,
  };
}

class Channels extends Component {
  render() {
    const { channels, selectedId, handleSelect } = this.props;
    if (channels && channels.status) {
      return <Message error header={channels.reason} />;
    }
    if (this.props.payment && this.props.payment.status) {
      return <Message error header={this.props.payment.reason} />;
    }
    if (this.props.paymentInfo && this.props.paymentInfo.reason) {
      return <Message error header={this.props.paymentInfo.reason} />;
    }

    if (channels) {
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(110px, min-content))',
            justifyContent: 'space-evenly',
          }}
        >
          {channels.map(({ id, name, logoUrl, amount, feeType }) => (
            <div
              key={id}
              style={{display: 'flex', alignItems: 'center'}}
              className={`cashbill_payment_channel ${
                id === selectedId && 'cashbill_payment_channel--selected'
              }`}
              onClick={() => handleSelect(id, amount)}
            >
              <Image
                key={id}
                className='cashbillImage'
                alt={name}
                src={logoUrl}
                size='small'
                centered
                label={ feeType === 'discount' &&
                  <Label as='a' color='red' ribbon size='mini'>
                    Zniżka
                  </Label>
                }
              />
            </div>
          ))}
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
