import React, { Component } from 'react';
import { Route } from 'react-router';
import withUserRoles from '../utils/withUserRoles';
import CloseSettlements from './closeSettlements';
import ExportCsv from './exportCsv';
import LogSwitch from './logSwitch';

class AdminRoutes extends Component {
  constructor(props) {
    super(props);
    this.routes = [
      { path: '/csv', component: ExportCsv },
      { path: '/settlements', component: CloseSettlements },
      { path: '/logswitch', component: LogSwitch },
    ];
  }

  render() {
    if(!this.props.isAdmin) return null;

    return this.routes.map(({ path, component }) => (
      <Route key={path} path={this.props.match.path + path} component={component} />
    ))
  }
}

export default withUserRoles(AdminRoutes);
