import React, {Component} from 'react'
import {Menu, Sidebar, Image, Dropdown} from 'semantic-ui-react'
import logo from '../../images/fiberpay-white.svg';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from './../../actions';
import {l, t} from "../../i18n";
import withUserRole from '../utils/withUserRoles';

function mapStateToProps(state) {
  return {
    getApplication: state.getApplication,
  };
}

class PanelSidebar extends Component {

  componentDidMount() {
    this.props.actions.getApplication();
    this.props.actions.userCheckLoggedIn();
  }

  isItemActive(name) {
    return this.props.pathname.includes(name);
  }

  renderDropdown(name, path, items) {
    return (
      <Menu.Item as={Dropdown} text={t(name)} active={this.isItemActive(path)}>
        <Dropdown.Menu>
          {items.map(({ desc, path }) => (
            <Dropdown.Item
              key={path}
              as={Link}
              to={l + path}
              id="dropdown-item"
              content={t(desc)}
            />
          ))}
        </Dropdown.Menu>
      </Menu.Item>
    )
  }

  adminDropdown() {
    const items = [
      { desc: 'menu.admin.csvExport', path: '/panel/admin/csv' },
      { desc: 'menu.admin.settlementsClose', path: '/panel/admin/settlements' },
      { desc: 'LogSwitch', path: '/panel/admin/logswitch' },
      { desc: 'Blik - zwroty', path: '/panel/admin/blik-returns'},
    ];

    if(this.props.isAdmin) {
      return this.renderDropdown('menu.admin', '/admin', items);
    }
  }

  logsDropdown() {
    const items = [
      { desc: 'menu.apiRequests', path: '/panel/logs/requests' },
      { desc: 'menu.callbackRequests', path: '/panel/logs/callbacks' },
    ];

    return this.renderDropdown('menu.logs', '/logs', items);
  }

  render() {
    const visible = this.props.visible;

    return (
      <Sidebar id="panelMenu" as={Menu} animation='push' visible={visible} inverted vertical>
        <Menu.Item
          as={Link}
          to={l + "/panel"}>
          <Menu.Header><Image src={logo} style={{maxWidth: "80%"}}/></Menu.Header>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={l + "/panel/keygen"}
          active={this.isItemActive('/keygen')} content={t("menu.apiKeys")}
          icon='key'/>
        <Menu.Item
          as={Link}
          to={l + "/panel/kyc"}
          active={this.isItemActive('/kyc')} content={t("menu.kyc")}
          icon='id card'/>
        <Menu.Item as={Link} to={l + "/panel/2fa"} name={'2fa'}
         active={this.isItemActive('/2fa')} content={t("menu.2fa")}
         icon='mobile alternate'/>
        <Menu.Item as={Link} to={l + "/panel/settings"} name={'2fa'}
         active={this.isItemActive('/settings')} content={t("menu.settings")}
         icon='settings'/>
        {this.logsDropdown()}
        <Menu.Item
          as={Link}
          to={l + "/panel/orders"}
          active={this.isItemActive('/orders')}
          content={t("menu.ordersList")}
          icon='unordered list'/>
        <Menu.Item
          as={Link}
          to={l + "/panel/invoices"}
          active={this.isItemActive('/invoices')}
          icon='file alternate'
          content={t("menu.invoices")} />
        {this.adminDropdown()}
        <Menu.Item
          as={Link}
          to={l + "/panel/logout"}
          active={this.isItemActive('/logout')}
          icon='sign-out'
          content={t("menu.logout")} />
      </Sidebar>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(withUserRole(PanelSidebar))
