import React, { Component } from 'react'
import { Container, Segment, Divider, Grid, Loader, Dimmer, Label, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import JSONPretty from 'react-json-pretty';
import NotFound from "../sites/notfound";
import {t} from "../../i18n";

function mapStateToProps(state) {
	return {
		getRequestCode: state.getRequestCode
	};
}


class ApiLogDetails extends Component {

	constructor(props) {
		super(props);
		this.state = {
			code: this.props.match.params.code,
			data: '',
			loading: true,
		}
	}

	componentDidMount() {
		this.props.actions.getRequestCode(this.state.code);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.getRequestCode !== this.props.getRequestCode) {
			let request = this.props.getRequestCode;
			if (request) {
				this.setState({ data: request, loading: false })
			}
		}
	}

	render() {
		let request = this.state.data;
		if (request) {
			if (request.status === "ERROR") {
				return <NotFound />
			} else {

				let string = ''
				if (request.data) {
					string = JSON.parse(request.data.headers);
				}
				return (
					<Container>
						<Segment basic>
							<Dimmer inverted active={this.state.loading}>
								<Loader content={t("system.loading")} />
							</Dimmer>
							<Divider horizontal className='list-title'>{t("apiLogDetails.header")}</Divider>
							<Divider hidden /><Divider hidden />
							<Grid columns='equal' stackable>
								<Grid.Row stretched>
									<Grid.Column>
										<Segment>
											<Label attached='top'>{t("apiLogDetails.request")}</Label>
											<Segment>{string && (string['x-api-route'] ? string['x-api-route'] : request.data.url)}</Segment>
											<Segment basic className='codeSegment'>
												<Label.Detail>
													<Icon name='info circle' />
													{t("apiLogDetails.responseStatus")} {request.data.responseStatus}
												</Label.Detail><br />
												<Label.Detail>
													<Icon name='calendar alternate outline' />{t("apiLogDetails.created")}{request.data.createdAt}
												</Label.Detail><br />
												<Label.Detail><Icon name='laptop' />{t("apiLogDetails.ipAddress")}{request.data.remoteIp}
												</Label.Detail><br />
												<Label.Detail><Icon name='laptop' />{t("apiLogDetails.url")}{request.data.url}
												</Label.Detail><br />
												<Label.Detail><Icon name='clock outline' />{t("apiLogDetails.processingTime")}{request.data.processingTime} ms</Label.Detail>
											</Segment>
										</Segment>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Segment>
											<Label attached='top'>{t("apiLogDetails.requestParams")}</Label>
											<Segment basic className='codeSegment'>
												<JSONPretty id="json-pretty" data={request.data.requestParams} />
											</Segment>
										</Segment>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Segment>
											<Label attached='top'>{t("apiLogDetails.headers")}</Label>
											<Segment basic className='codeSegment'>
												<JSONPretty id="json-pretty" data={request.data.headers} />
											</Segment>
										</Segment>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Segment>
											<Label attached='top'>{t("apiLogDetails.responseBody")}</Label>
											<Segment basic className='codeSegment'>
												<JSONPretty id="json-pretty" data={request.data.responseBody} />
											</Segment>
										</Segment>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
					</Container>
				)
			}
		} else {
			return <Segment basic loading={this.state.loading} />
		}
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiLogDetails)