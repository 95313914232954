import React, {Component} from "react";
import {Divider, Table} from "semantic-ui-react";
import {Pagination} from "../utils/pagination";
import _ from 'lodash';
import history from "../../history";
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import LabelStatuses from "./lables";
import {l, t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getCollectOrderSettlements: state.getCollectOrderSettlements,
  };
}

class SettlementList extends Component{

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      meta: {
        count: '',
        current_page: '',
        per_page: '',
        last_page: '',
        to: null,
      },
      page: 1,
      nextPage: '',
      prevPage: '',
      pages: [],
    }
  }

  componentDidMount() {
    this.props.actions.getCollectOrderSettlements(this.props.code);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getCollectOrderSettlements !== this.props.getCollectOrderSettlements) {
      let settlement = this.props.getCollectOrderSettlements;
      if (settlement) {
        this.setState({settlement: settlement.data, settlementMeta: settlement.meta})
      }
    }
  }

  callPagination = (options) => {
    this.setState({
      current_page: options.pageNo,
      page: options.name,
      pages: options.pages,
      last_page: options.pagesCount,
      offset: options.offset,
      per_page: options.limit,
      showErrors: false,
      value: ''
    });
    let params = options.params;
    if (this.state.status)
      params.status = this.state.status;

    let data = {
      page: options.pageNo,
    }

    this.props.actions.getCollectOrderSettlements(this.props.code, data);
  }

  getSettlement(code) {
    history.push(l+`/panel/orders/collect/${code}/settlements`)
  }

  render() {
    return(
      <>
        <Divider horizontal className='list-title'>{t("orders.settlements")}</Divider>
        <Table selectable compact columns='3'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign={"center"}>{t("orders.code")}</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>{t("orders.updated")}</Table.HeaderCell>
              <Table.HeaderCell textAlign={"center"}>{t("orders.status")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(this.props.settlement, ({data}) => (
              <Table.Row key={data.code} onClick={this.getSettlement.bind(this, data.code)}>
                <Table.Cell textAlign={"center"}>{data.code}</Table.Cell>
                <Table.Cell textAlign={"center"}>{data.updatedAt}</Table.Cell>
                <Table.Cell textAlign={"center"}><LabelStatuses status={data.status}/></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Pagination
              columnNumber={5} showFunction={this.callPagination.bind(this)}
              pageNo={this.props.settlementMeta.current_page} pages={this.state.pages}
              pagesCount={this.props.settlementMeta.last_page} limit={this.props.settlementMeta.per_page}/>
          </Table.Footer>
        </Table>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementList)