import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
	Segment, Container, Message, Grid, Header, Form, Divider,
	Button, Dimmer, Loader, Input
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import FacebookButton from '../utils/FacebookButton';
import history from '../../history';
import {l, t} from "../../i18n";
import FormComponent from '../containers/formcomponent';
import { SHOW_FACEBOOK } from '../../constants';

function mapStateToProps(state) {
	return {
		userLogin: state.userLogin,
		loginRequired: state.loginRequired
	};
}

class Login extends FormComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			passwordVisible: false,
			...this.state,
		}
	}

	componentDidMount(){
		let token = localStorage.getItem('authToken');
		if(token){
			history.push(l + '/panel');
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.loginRequired !== this.props.loginRequired) {
			let loginRequired = this.props.loginRequired;
			this.validateForm(loginRequired);
		}

		if (prevProps.userLogin !== this.props.userLogin) {
			let userLogin = this.props.userLogin;
			this.validateForm(userLogin, () => {
				if(userLogin.access_token){
					let token = "Bearer " + userLogin.access_token;
					localStorage.setItem('authToken', token);
					localStorage.setItem('refreshToken', userLogin.refresh_token);
					history.push(l + '/panel');
				}

				if (userLogin?.loading === false) {
					this.setState({ loading: false, disabled: false, passDisabled: true });
				}
			})
		}
	}

	callLogin() {
		this.props.actions.userLogin({
			email: this.state.email,
			password: this.state.password
		});
	}

	showLoginForm() {
		return (
			<Segment basic>
				<Header as="h3" textAlign='center'>{t("login")}</Header>
				<Divider section />
				<Form id='login-form' onSubmit={this.handleSubmitForm.bind(this, () => this.callLogin())} error={this.state.showErrors}>
					<Form.Field error={this.state.formErrorsKeys.email}>
						<label for='email'>{t("emailAddress")}</label>
						<Input placeholder={t("emailAddress")}
							autocomplete="username"
							id='email'
							name='email'
							type='email'
							onChange={this.handleValueChange.bind(this)}
							value={this.state.email}
							required />
					</Form.Field>
					<Form.Field error={this.state.formErrorsKeys.password}>
						<label for='current-password'>{t("password")}</label>
						<Input placeholder={t("password")}
							autocomplete="current-password"
							labelPosition='right'
							label={
								<Button 
									type='button' 
									icon={this.state.passwordVisible ? 'eye' : 'eye slash'}
									onClick={()=> this.setState({passwordVisible: !this.state.passwordVisible})}
								/>}
							id="current-password"
							name='password'
							type={this.state.passwordVisible ? "text" : "password"}
							onChange={this.handleValueChange.bind(this)}
							value={this.state.password}
							required />
					</Form.Field>
					<Message
						error
						header={this.state.formErrorsMessage}
						list={this.state.formErrors}
					/>
					<div style={{textAlign: 'center'}}>
						<Button primary type="submit" disabled={this.state.disabled}>{t("login")}</Button>
					</div>
				</Form>
				{SHOW_FACEBOOK && <div style={{textAlign: 'center'}}>
					<Divider />
					<FacebookButton type='login' content={t("login.viaFacebook")}/>
				</div>}
			</Segment>
		)
	}


	render() {
		return (
			<div style={{ minHeight: 'calc(100vh - 240px)', display: 'flex', alignItems: 'center' }}>
				<Container>
					<Grid stackable centered padded columns={2}>
						<Grid.Row>
							<Grid.Column>
								{this.showLoginForm()}
							</Grid.Column>
							<Grid.Column>
								<Segment textAlign='center' basic>
									<Header as="h3">{t("login.create")}</Header>
									<Divider section />
									<p>{t("login.createInFiberpay")}</p>
									<Divider hidden />
									<Divider hidden />
									<Button primary as={Link} to={l+'/register'}>{t("login.create")}</Button>
								</Segment>
								<Segment basic textAlign='center'>
									<Divider />
									<p><Link to={l + '/password/reset'}>{t('login.resetPassword')}</Link></p>
									<p><Link to={l + '/email/verify'}>{t('login.resendVerificationMail')}</Link></p>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<Dimmer inverted active={false}>
						<Loader content={t("system.loading")} />
					</Dimmer>
				</Container>
			</div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
