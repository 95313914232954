import React, { Component } from 'react';
import { Table, Icon, Menu } from "semantic-ui-react";
import _ from 'lodash';

export class Pagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pages: [],
			pageNo: 1,
			nextPage: '',
			prevPage: '',
			pagesCount: 1,
			limit: 10,
			offset: 0,
			name: ''
		};
	}

	componentDidMount() {
		let props = this.props;
		let state = {};
		if (props) {
			if (props.name)
				state.name = props.name;
			if (props.pageNo)
				state.pageNo = props.pageNo;
			if (props.pagesCount)
				state.pagesCount = props.pagesCount;
			if (props.limit)
				state.limit = props.limit;
			if (props.pages) {
				if (props.pages.length === 0)
					state.pages = this.arrayForPagination(state.pageNo, state.pagesCount);
				else
					state.pages = props.pages;
			}
			this.setState(state);
		}
	}

	componentDidUpdate(prevProps) {
		let state = {};
		if (prevProps !== this.props) {
			if (this.props.name)
				state.name = this.props.name;
			if (this.props.pageNo)
				state.pageNo = this.props.pageNo;
			if (this.props.pagesCount)
				state.pagesCount = this.props.pagesCount;
			if (this.props.limit)
				state.limit = this.props.limit;
			if (this.props.pages) {
				if (this.props.pages.length === 0)
					state.pages = this.arrayForPagination(state.pageNo, state.pagesCount);
				else
					state.pages = this.props.pages;
			}
			this.setState(state);
		}
	}

	handlePaginationClick(e, { name, value }) {
		let pageNo = parseInt(name, value);
		let pages = this.arrayForPagination(pageNo, this.state.pagesCount);
		this.setState({
			pageNo: pageNo, pages: pages, loading: true
		}, this.callShow);
	}

	handleLeftChevronClick() {
		let pageNo = this.state.pageNo;
		if (pageNo === 1) {
			return;
		}

		let prevPage = this.state.pageNo - 1;
		let pages = this.arrayForPagination(prevPage, this.state.pagesCount);

		this.setState({
			pageNo: prevPage, pages: pages, loading: true
		}, this.callShow);
	}

	handleRightChevronClick() {
		let pageNo = this.state.pageNo;
		let pagesCount = this.state.pagesCount;

		if (pageNo === pagesCount) {
			return;
		}

		let nextPage = this.state.pageNo + 1;
		let pages = this.arrayForPagination(nextPage, this.state.pagesCount);

		this.setState({
			pageNo: nextPage, pages: pages, loading: true
		}, this.callShow);
	}

	arrayForPagination(pageNo, pagesCount) {
		let pages = [];
		if (pageNo <= pagesCount) {
			let firstPage;
			let lastPage;

			if (pagesCount <= 10) {
				firstPage = 1;
				lastPage = pagesCount;
			} else {
				if (pageNo <= 6) {
					firstPage = 1;
					lastPage = 10;
				} else if (pageNo + 4 >= pagesCount) {
					firstPage = pagesCount - 9;
					lastPage = pagesCount;
				} else {
					firstPage = pageNo - 5;
					lastPage = pageNo + 4;
				}
			}


			for (let a = firstPage; a <= lastPage; a++) {
				pages.push(a);
			}
		}
		return pages;
	}

	callShow() {
		let pageNo = this.state.pageNo;
		let offset = (pageNo - 1) * this.state.limit;
		let options = {
			params: {
				offset: offset,
				limit: this.state.limit,
			},
			pageNo: this.state.pageNo,
			pages: this.state.pages,
			pagesCount: this.state.pagesCount,
			paginationName: this.state.name,
		};
		this.props.showFunction(options);
	}

	render() {
		return (
			<Table.Row>
				<Table.HeaderCell id='pagination-th' colSpan={this.props.columnNumber}>
					<Menu floated='right' pagination style={{ display: 'flex', flexWrap: 'wrap' }}>
						<Menu.Item as='a' icon
							onClick={this.handleLeftChevronClick.bind(this)}
							disabled={this.state.pageNo === 1}>
							<Icon name='left chevron' />
						</Menu.Item>
						{_.map(this.state.pages, (value) => (
							<Menu.Item key={value} as='a' name={value.toString()}
								onClick={this.handlePaginationClick.bind(this)}
								active={value === this.state.pageNo}>
								{value}
							</Menu.Item>
						))}
						<Menu.Item as='a' icon
							onClick={this.handleRightChevronClick.bind(this)}
							disabled={this.state.pageNo >= this.state.pagesCount}>
							<Icon name='right chevron' />
						</Menu.Item>
					</Menu>
				</Table.HeaderCell>
			</Table.Row>
		);
	}
}