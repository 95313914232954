import React, { Component } from 'react'
import { Segment, Image, Container, Icon, Message, Header, List } from 'semantic-ui-react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import logo from '../../images/fiberpay-cobalt.svg';

function mapStateToProps(state) {
  return {
    order: state.getOrder,
  };
}

class OrderTag extends Component {

  constructor(props) {
    super(props);
    this.state = {
      order: null,
      loading: true,
    }
  };

  componentDidMount() {
    let code = this.props.match.params.code
    if (code.length === 8) {
      this.props.actions.getDepositOrderItem(code);
    } else if (code.length === 12) {
      this.props.actions.getDepositOrder(code)
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order !== this.props.order) {
      let order = this.props.order;
      if (order.data) {
        this.setState({ order: order.data, invoice: order.invoice, loading: false });
      } else {
        this.setState({ loading: false })
      }
    }
  }

  order() {
    let o = this.state.order;
    let i = this.state.invoice;
    let qrurl = actions.getQRCodeUrl(o.code, 'tag');
    return (
      <Container text>
        <Segment basic style={{paddingLeft: 0, paddingRight: 0}}>
          <Segment basic style={{flex: 1, padding: 0}}>
            {this.orderDescription(o)}
            <Segment basic textAlign='center' style={{ backgroundColor: 'lightblue' }}>
              <Header as='h2' color='blue'><b>{i.amount} zł</b></Header>
            </Segment>
            <Segment basic textAlign='center'>
              <Image src={qrurl} centered/>
            </Segment>
            <Segment basic style={{ display: 'flex', justifyContent: 'center' }}>
              <List ordered>
                <List.Item>Pokazujesz powyższy kod Klientowi</List.Item>
                <List.Item>Klient skanuje kod aparatem w swoim telefonie</List.Item>
                <List.Item>Klient otwiera link</List.Item>
                <List.Item>Klient dokonuje płatności za pomocą FiberPay</List.Item>
              </List>
            </Segment>
            <Segment basic>
              <Image src={logo} centered size='small' />
            </Segment>
          </Segment>
        </Segment>
      </Container>
    )
  }

  orderDescription(o){
    let toName = o.toName;
    let description = o.desciption;
    if(o.type === 'forward'){
      toName = o.brokerName;
      description = o.targetDescription;
    }
    return (
      <>
      <Segment basic textAlign='center' style={{ backgroundColor: 'lightgrey' }}>
        <label>Odbiorca płatności</label>
        <Header color='grey' style={{ marginTop: 0 }}><b>{toName}</b></Header>
      </Segment>
      <Segment basic textAlign='center'>
        <label>Opis płatności</label>
        <Header as='h4' style={{ marginTop: 0 }}><b>{description}</b></Header>
      </Segment>
      </>
    )
  }

  notFound() {
    return (
      <Segment basic>
        <Message icon error>
          <Icon name='frown outline' loading />
          <Message.Content>
            <Message.Header>Wystapił błąd - nie znaleźliśmy płatności</Message.Header>
            <ul>
              <li>Sprawdź, czy na pewno masz poprawny link / identyfikator płatności</li>
              <li>Jeśli potrzebujesz pomocy zgłoś się do nas pisząc na info@fiberpay.pl</li>
            </ul>
          </Message.Content>
        </Message>
      </Segment>
    );
  }

  render() {
    if (this.state.order) {
      return this.order();
    } else if (this.state.loading) {
      return (<Segment basic loading={true} style={{ height: '100%' }} />)
    } else {
      return this.notFound();
    }
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTag)