import React, {Component} from "react";
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Container, Divider, Label, Segment, Table} from "semantic-ui-react";
import _ from 'lodash';
import Statuses from "./statuses";
import history from "../../history";
import {Pagination} from "../utils/pagination";
import SettlementList from "./settlementList";
import LabelStatuses from "./lables";
import {l, t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getCollectOrderByCode: state.getCollectOrderByCode,
    getCollectOrderItems: state.getCollectOrderItems,
    getCollectOrderSettlements: state.getCollectOrderSettlements,
  };
}

class CollectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      loading: true,
      data: [],
      meta: {
        count: '',
        current_page: '',
        per_page: '',
        last_page: '',
        to: null,
      },
      page: 1,
      nextPage: '',
      prevPage: '',
      pages: [],
    }
  }

  getDirectDetails(code) {
    history.push(l + `/panel/orders/collect/item/${code}`);
  }

  componentDidMount() {
    this.props.actions.getCollectOrderByCode(this.props.match.params.code)
    this.props.actions.getCollectOrderItems(this.props.match.params.code);
    this.props.actions.getCollectOrderSettlements(this.props.match.params.code);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getCollectOrderByCode !== this.props.getCollectOrderByCode) {
      let order = this.props.getCollectOrderByCode;
      if (order) {
        this.setState({order: order.data, loading: false})
      }
    }
    if (prevProps.getCollectOrderItems !== this.props.getCollectOrderItems) {
      let item = this.props.getCollectOrderItems;
      if (item) {
        this.setState({data: item.data, meta: item.meta})
      }
    }
    if (prevProps.getCollectOrderSettlements !== this.props.getCollectOrderSettlements) {
      let settlement = this.props.getCollectOrderSettlements;
      if (settlement) {
        this.setState({settlement: settlement.data, settlementMeta: settlement.meta})
      }
    }
  }

  callPagination = (options) => {
    this.setState({
      current_page: options.pageNo,
      page: options.name,
      pages: options.pages,
      last_page: options.pagesCount,
      offset: options.offset,
      per_page: options.limit,
      showErrors: false,
      value: ''
    });
    let params = options.params;
    if (this.state.status)
      params.status = this.state.status;

    let data = {
      page: options.pageNo,
    }

    this.props.actions.getCollectOrderItems(this.props.match.params.code, data);
  }

  callPaginationSettlement = (options) => {
    this.setState({
      current_page: options.pageNo,
      page: options.name,
      pages: options.pages,
      last_page: options.pagesCount,
      offset: options.offset,
      per_page: options.limit,
      showErrors: false,
      value: ''
    });
    let params = options.params;
    if (this.state.status)
      params.status = this.state.status;

    let data = {
      page: options.pageNo,
    }

    this.props.actions.getCollectOrderSettlements(this.props.match.params.code, data);
  }

  render() {
    let {order, data, meta, settlement, settlementMeta} = this.state
    if (order) {
      let status = order.status;
      return (
        <Segment basic>
          <Container>
            <Segment basic>
              <Divider horizontal className='list-title'>{order.type}{t("orders.details")}</Divider>
              <Segment>
                <Label attached='top'>Collect</Label>
                <Table compact celled basic='very' columns={2}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.status")}</Table.Cell>
                      <Table.Cell>
                        <LabelStatuses status={status}/>
                        &nbsp;
                        <Statuses status={status}/>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.code")}</Table.Cell>
                      <Table.Cell>{order.code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.currency")}</Table.Cell>
                      <Table.Cell>{order.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.iban")}</Table.Cell>
                      <Table.Cell>{order.toIban}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.receiver")}</Table.Cell>
                      <Table.Cell>{order.toName}</Table.Cell>
                    </Table.Row>
                    {order.metadata && <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.metadata")}</Table.Cell>
                      <Table.Cell>{order.metadata}</Table.Cell>
                    </Table.Row>}
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.created")}</Table.Cell>
                      <Table.Cell>{order.createdAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.updated")}</Table.Cell>
                      <Table.Cell>{order.updatedAt}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
              {data &&
              <>
                <Divider horizontal className='list-title'>{t("orders.collectItemsList")}</Divider>
                <Table selectable compact columns='3'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign={"center"}>{t("orders.code")}</Table.HeaderCell>
                      <Table.HeaderCell textAlign={"center"}>{t("orders.updated")}</Table.HeaderCell>
                      <Table.HeaderCell textAlign={"center"}>{t("orders.status")}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {_.map(data, ({data}) => (
                      <Table.Row key={data.code} onClick={this.getDirectDetails.bind(this, data.code)}>
                        <Table.Cell textAlign={"center"}>{data.code}</Table.Cell>
                        <Table.Cell textAlign={"center"}>{data.updatedAt}</Table.Cell>
                        <Table.Cell textAlign={"center"}><LabelStatuses status={data.status}/></Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Pagination
                      columnNumber={5} showFunction={this.callPagination.bind(this)}
                      pageNo={meta.current_page} pages={this.state.pages}
                      pagesCount={meta.last_page} limit={meta.per_page}/>
                  </Table.Footer>
                </Table>
              </>}
              {settlement &&
              <SettlementList
                settlement={settlement}
                settlementMeta={settlementMeta}
                code={this.props.match.params.code}
              />}
            </Segment>
          </Container>
        </Segment>
      )
    } else {
      return <Segment basic loading={this.state.loading}/>
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectDetails)
