import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

const CHECK_INTERVAL = 5000;

class ActionStatusChecker extends Component {
  componentDidMount() {
    const { action, type } = this.props;
    this.handler = setInterval(() => this.checkStatus({}, action.code, type), CHECK_INTERVAL);
  }

  componentDidUpdate() {
    if (this.shouldStopChecking()) {
      clearInterval(this.handler);
    }
  }

  shouldStopChecking() {
    const { action } = this.props;
    return ['executed', 'declined'].some(status => status === action.status);
  }

  componentWillUnmount() {
    clearInterval(this.handler);
  }

  checkStatus() {
    const { action, type } = this.props;
    this.props.actions.handle2FAAction({}, action.code, type);
  }

  render() {
    return <div>{this.props.description}</div>;
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(null, mapDispatchToProps)(ActionStatusChecker);
