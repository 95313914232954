import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Message, Segment, Container, Divider } from 'semantic-ui-react';
import { t } from '../../i18n';
import * as actions from '../../actions';
import PartnerCreator from './partnerCreator';
import PartnerDetails from './partnerDetails';

const types = {
  individual: t('kyc.type.individual'),
  sole_proprietorship: t('kyc.type.soleProprietorship'),
  company: t('kyc.type.company'),
};

function mapStateToProps(state) {
  return {
    partner: state.getPartner,
    updatePartner: state.updatePartner,
    user: state.getUser,
  };
}

class KycPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      partner: null,
      user: null,
    };
  }

  componentDidMount() {
    this.props.actions.getUser();
  }

  componentDidUpdate(prevProps) {
    const { user, partner } = this.props;

    if (user !== prevProps.user) {
      this.setState({ user: user.data }, () => this.props.actions.getPartner());
    }

    if (partner !== prevProps.partner) {
      this.setState({ partner: partner.data, loading: false });
    }
  }

  getPartnerKycStatus = () => {
    return this.state.partner?.kycStatus;
  };

  renderStatusMessage() {
    const { partner } = this.state;
    if (!partner.type) {
      return <Message info header={t('kyc.statusMessage.required')} />;
    }

    const kycStatus = this.getPartnerKycStatus();

    switch (kycStatus) {
      case 'accepted':
        return <Message positive header={t('kyc.statusMessage.accepted')} />;

      case 'rejected':
        return (
          <Message negative>
            <Message.Header>{t('kyc.statusMessage.rejected')}</Message.Header>
            {this.state.partner.rejectionReason && <Message.Content>{this.getRejectionReasonMessage()}</Message.Content>}
          </Message>
        );

      default:
        return <Message info header={t('kyc.statusMessage.inProgress')} />;
    }
  }

  getRejectionReasonMessage = () => {
    const { rejectionReason } = this.state.partner;
    const message = t(`kyc.rejection_reason.${rejectionReason}`);

    if (
      ['tax_id_number_not_unique', 'personal_identity_number_not_unique'].includes(rejectionReason)
    ) {
      return (
        <>
          {message}
          <br />
          {t('kyc.use_previously_verified_account')}
        </>
      );
    }

    return <>{message}</>;
  };

  render() {
    const { partner, user, loading } = this.state;

    if (loading) return <Segment basic loading />;
    return (
      <Container>
        <Segment basic>
          <Divider horizontal className='list-title'>
            {t('kyc.header')}
          </Divider>
          <Grid>
            {user && (
              <>
                <Grid.Row>
                  <Container fluid>
                    <Segment basic>{this.renderStatusMessage()}</Segment>
                  </Container>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>{t('welcome.name')}</Grid.Column>
                  <Grid.Column>{user.name}</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>{t('welcome.email')}</Grid.Column>
                  <Grid.Column>{user.email}</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>{t('welcome.created')}</Grid.Column>
                  <Grid.Column>{user.createdAt}</Grid.Column>
                </Grid.Row>
              </>
            )}
            {partner?.type && (
              <Grid.Row columns={2}>
                <Grid.Column>{t('kyc.type')}</Grid.Column>
                <Grid.Column>{types[partner.type]}</Grid.Column>
              </Grid.Row>
            )}
            {this.getPartnerKycStatus() === 'accepted' && (
              <Grid.Row>
                <PartnerDetails type={partner.type} details={partner.details} />
              </Grid.Row>
            )}
            <Grid.Row columns={2}>
              <PartnerCreator partner={partner} />
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(KycPanel);
