import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import url from 'url';
import querystring from 'querystring';
import { Segment, Message, Container } from 'semantic-ui-react';

const mapStateToProps = state => ({
  handleDecision: state.handle2FADecision,
});

class MailAuthExecution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: '',
      messageProps: {},
    };
  }

  componentDidMount() {
    const { match, location } = this.props;
    const { code } = match.params;
    const { query } = url.parse(location.search);
    const { decision, otp } = querystring.parse(query);
    this.props.actions.handleEmailDecision(code, { otp, decision });
  }

  componentDidUpdate(prevProps) {
    const { handleDecision } = this.props;

    if (handleDecision && handleDecision !== prevProps.handleDecision) {
      let message;
      let messageProps;

      const { error, action } = handleDecision;

      if (error) {
        switch (error) {
          case 'Authorization request has expired':
            message = 'Możliwość potwierdzenia wykonania operacji wygasła.';
            messageProps = { negative: true };
            break;

          case 'Action has been already handled':
            message = `Operacja z ${action.createdAt} została już obsłużona.`;
            messageProps = { warning: true };
            break;

          default:
            message = 'W procesie potwierdzania wykonania operacji wystąpił błąd.';
            messageProps = { negative: true };
            break;
        }
      } else {
        if (action.status === 'accepted') {
          messageProps = { positive: true };
          message = `Wykonanie operacji z ${action.createdAt} zostało zatwierdzone.`;
        } else {
          messageProps = { warning: true };
          message = `Wykonanie operacji z ${action.createdAt} zostało odrzucone.`;
        }
      }
      this.setState({ loading: false, message, messageProps });
    }
  }

  render() {
    const { loading, message, messageProps } = this.state;
    if (loading) {
      return <Segment basic loading style={{ height: '100%', minHeight: '100px' }} />;
    }

    return (
      <Container>
        <Segment basic>
          <Message {...messageProps} padded>
            <Message.Header>{message}</Message.Header>
            <p>Możesz zamknąć tę kartę.</p>
          </Message>
        </Segment>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(MailAuthExecution);
