import React, {Component} from "react";
import {Form, Input} from "semantic-ui-react";
import {t} from "../../i18n";

export default class SearchOrder extends Component {
  render() {
    return (
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <Input
              iconPosition={"left"}
              icon={"search"}
              size={"small"}
              name={'searchOrder'}
              placeholder={t("orders.searchByCode")}
              value={this.props.valuesSearch}
              onChange={this.props.handleSearch.bind(this)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}
