import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CallbackPanel from '../callback/CallbackPanel';
// import Intro from '../intro/intro';
import Login from '../sites/login';
import NotFound from '../sites/notfound';
import PrivacyPolicy from '../sites/privacypolicy';
import Register from '../sites/register';
import Services from '../sites/services';
import ErrorPanel from '../sites/errorpanel';
import ResetPassword from '../sites/password/ResetPassword';
import NewPassword from '../sites/password/NewPassword';
import ResendEmailVerification from '../sites/email/ResendEmailVerification';
import EmailVerification from '../sites/email/EmailVerification';
import MailAuthExecution from '../sites/MailAuthExecution';
import ShareRequests from '../accesskeys/shareRequests';
import {NEW_FRONT_URL} from "../../constants";

const AppRouting = ({path}) => {
  return (
    <Switch>
      <Route exact path={path} component={() => {
         window.location.href = NEW_FRONT_URL;
        return null
      }}/>
      <Route path={path + 'callback'} component={CallbackPanel}/>
      <Route path={path + 'login'} component={Login}/>
      <Route path={path + 'password/reset/:code'} component={NewPassword}/>
      <Route path={path + 'password/reset'} component={ResetPassword}/>
      <Route path={path + 'email/verify/:code'} component={EmailVerification}/>
      <Route path={path + 'email/verify'} component={ResendEmailVerification}/>
      <Route path={path + 'auth/:code'} component={MailAuthExecution}/>
      <Route path={path + 'consents/:code'} component={ShareRequests}/>
      <Route path={path + 'register'} component={Register}/>
      <Route path={path + 'services'} component={Services}/>
      <Route path={path + 'privacy-policy'} component={PrivacyPolicy}/>
      <Route path={path + 'error'} component={ErrorPanel}/>
      <Route path={path + '*'} component={NotFound}/>
    </Switch>
  );
};

export default AppRouting;
