export const API_URL = process.env.REACT_APP_BACKEND_URL;

export const NEW_FRONT_URL = process.env.REACT_APP_NEW_FRONTEND_URL || 'https://fiberpay.pl';
export const FRONT_URL = process.env.REACT_APP_FRONTEND_URL || 'https://fiberpay.pl';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_VERSION = 'int';
export const SHOW_FACEBOOK = process.env.REACT_APP_SHOW_FACEBOOK
  ? process.env.REACT_APP_SHOW_FACEBOOK
  : false;

export const GENERAL_ERROR = 'GENERAL_ERROR';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_REGISTER = 'USER_REGISTER';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const USER_VERIFY = 'USER_VERIFY';
export const USER_ENABLE_2FA = 'USER_ENABLE_2FA';
export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_COLLECT_ORDER_ITEM = 'GET_COLLECT_ORDER_ITEM';
export const CREATE_APIKEYS = 'CREATE_APIKEYS';
export const GET_APIKEYS = 'GET_APIKEYS';
export const DISABLE_APIKEYS = 'DISABLE_APIKEYS';
export const FACEBOOK_ACTION = 'FACEBOOK_ACTION';
export const FACEBOOK_CALLBACK = 'FACEBOOK_CALLBACK';
export const GET_USER = 'GET_USER';
export const GET_REQUEST = 'GET_REQUEST';
export const GET_REQUEST_CODE = 'GET_REQUEST_CODE';
export const GET_CALLBACK = 'GET_CALLBACK';
export const GET_CALLBACK_CODE = 'GET_CALLBACK_CODE';
export const GET_DIRECT_ORDER_BY_CODE = 'GET_DIRECT_ORDER_BY_CODE';
export const GET_COLLECT_ORDER_BY_CODE = 'GET_COLLECT_ORDER_BY_CODE';
export const GET_COLLECT_ORDER_ITEM_LIST = 'GET_COLLECT_ORDER_ITEM_LIST';
export const GET_COLLECT_ORDER_ITEM_BY_CODE = 'GET_COLLECT_ORDER_ITEM_BY_CODE';
export const GET_FORWARD_ORDER_BY_CODE = 'GET_FORWARD_ORDER_BY_CODE';
export const GET_SPLIT_ORDER_BY_CODE = 'GET_SPLIT_ORDER_BY_CODE';
export const GET_SPLIT_ORDER_ITEM_BY_CODE = 'GET_SPLIT_ORDER_ITEM_BY_CODE';
export const GET_SPLIT_ORDER_ITEM_LIST = 'GET_SPLIT_ORDER_ITEM_LIST';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_COLLECT_ORDER_SETTLEMENTS = 'GET_COLLECT_ORDER_SETTLEMENTS';
export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export const GET_SETTLEMENT = 'GET_SETTLEMENT';
export const CLOSE_SETTLEMENT = 'CLOSE_SETTLEMENT';
export const INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT';
export const SET_CASHBILL_PAYER = 'SET_CASHBILL_PAYER';
export const CASHBILL_PAYMENT_CHANNELS = 'CASHBILL_PAYMENT_CHANNELS';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_ITEM = 'GET_ORDER_ITEM';
export const GET_ORDER_PAYMENT_CHANNELS = 'GET_ORDER_PAYMENT_CHANNELS';
export const GET_PAYMENT_CHANNELS = 'GET_PAYMENT_CHANNELS';
export const GET_INVOICE_FOR_ORDER = 'GET_INVOICE_FOR_ORDER';
export const GET_SETTLEMENT_REPORT = 'GET_SETTLEMENT_REPORT';
export const AUTHORIZE_2FA = 'AUTHORIZE_2FA';
export const USER_2FA_DEVICES = 'USER_2FA_DEVICES';
export const CREATE_2FA_DEVICE = 'CREATE_2FA_DEVICE';
export const ENABLE_2FA_DEVICE = 'ENABLE_2FA_DEVICE';
export const GET_2FA_ACTION = 'GET_2FA_ACTION';
export const EMAIL_2FA_DECISION = 'EMAIL_2FA_DECISION';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const SEND_EMAIL_VERIFICATION_MAIL = 'SEND_EMAIL_VERIFICATION_MAIL';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const SEND_USER_PASSWORD_RESET_MAIL = 'SEND_USER_PASSWORD_RESET_MAIL';
export const CHECK_USER_PASSWORD_RESET_CODE = 'CHECK_USER_PASSWORD_RESET_CODE';
export const GET_ORDERS_FEES = 'GET_ORDERS_FEES';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_ORDER_ITEMS = 'GET_INVOICE_ORDER_ITEMS';
export const GET_INVOICE_ORDER_ITEMS_REPORT = 'GET_INVOICE_ORDER_ITEMS_REPORT';
export const EXPORT_WITHDRAWAL_CSV = 'EXPORT_WITHDRAWAL_CSV';
export const SIMULATE_ORDER_INVOICE_PAYMENT = 'SIMULATE_ORDER_INVOICE_PAYMENT';
export const LOG_SWITCH = 'LOG_SWITCH';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_PARTNER = 'GET_PARTNER';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const FIND_ACCESS_KEY_SHARE_REQUESTS = 'FIND_ACCESS_KEY_SHARE_REQUESTS';
export const GET_ACCESS_KEY_SHARE_REQUEST = 'GET_ACCESS_KEY_SHARE_REQUEST';
export const ASSIGN_ACCESS_KEY_SHARE_REQUEST = 'ASSIGN_ACCESS_KEY_SHARE_REQUEST';
export const SET_ACCESS_KEY_SHARE_REQUEST_STATUS = 'SET_ACCESS_KEY_SHARE_REQUEST_STATUS';
export const GET_VERIFICATION_TRANSFER_ORDER_BY_CODE = 'GET_VERIFICATION_TRANSFER_ORDER_BY_CODE';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const GET_ORDER_INVOICE = 'GET_ORDER_INVOICE';
export const CREATE_RETURN_SPLIT_ORDER = 'CREATE_RETURN_SPLIT_ORDER';
export const NOTIFY_BLIK_REDIRECT = 'NOTIFY_BLIK_REDIRECT';
