import React, { Component, useState, useEffect } from 'react';
import * as actions from '../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Container,
  Segment,
  Message,
  Button,
  List,
  Header,
  Divider,
  Accordion,
  Icon,
  Popup,
  Step,
  Modal,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import history from '../../history';

const consentScopesMapping = {
  collect_create: 'Tworzenie zleceń typu Collect',
  collect_read: 'Pobieranie informacji o zleceniach typu Collect',
  collect_update: 'Aktualizację zleceń typu Collect',
  collect_delete: 'Usuwanie zleceń typu Collect',
};

const ScopesList = ({ scopes }) => (
  <List bulleted>
    {scopes.map(scope => (
      <List.Item key={scope}>{consentScopesMapping[scope]}</List.Item>
    ))}
  </List>
);

const Steps = ({ appName, isLoggedIn, isPartnerAccepted, isRequestAccepted }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    let currStep = 0;
    if (isLoggedIn) currStep = 2;
    if (isPartnerAccepted) currStep = 3;
    if (isRequestAccepted) currStep = 4;

    if (currentStep !== currStep) {
      setCurrentStep(currStep);
      setActiveIdx(currStep);
    }
  }, [isLoggedIn, isPartnerAccepted, isRequestAccepted, currentStep]);

  const toggleActiveStepIdx = (e, { index }) => {
    const newIndex = activeIdx === index || index === currentStep ? -1 : index;
    setActiveIdx(newIndex);
  };

  const stepsInfo = [
    {
      header: 'Zarejestruj się lub zaloguj',
      content: [
        'Podaj adres email i hasło aby utworzyć konto Fiberpay.',
        'Po chwili na Twoją skrzynkę mailową dotrze wiadomość z linkiem służącym do potwierdzenia adresu.',
        'Jeśli posiadasz już konto Fiberpay - przejdź do kolejnego kroku.',
      ],
      icon: 'user circle outline',
    },
    {
      header: 'Zaloguj się na konto Fiberpay',
      content: [
        <>
          Jeśli nie przeszedłeś pozytywnie procesu weryfikacji danych konta <br /> zostaniesz
          przekierowany na stronę umożliwiającą przeprowadzenie go.
        </>,
      ],
      icon: 'user circle',
    },
    {
      header: 'Zweryfikuj dane konta',
      content: [
        'Małe Instutycje Płatnicze takie jak Fiberpay zobowiązane są do zbierania informacji o obsugiwanych podmiotach (KYC).',
        'Proces weryfikacji danych jest bezpieczny i w przypadku większości klientów zajmuje nie więcej niż kilka minut.',
      ],
      icon: 'id card outline',
    },
    {
      header: 'Potwierdź zgodę na dostęp do Twojego konta',
      content: [
        <>
          Wyrażenie zgody wiąże się z wygenerowaniem kluczy API <br /> Zostaniesz poproszony o
          potwierdzenie tej operacji poprzez kliknięcie w link wysłany poprzez pocztę email.
        </>,
        <>
          Po potwierdzeniu operacji klucze API oraz dane Twojego konta <br /> zostaną automatycznie
          przekazane aplikacji {appName}.
        </>,
        <>
          W dowolnym momencie możesz zdecydować o dezaktywacji kluczy API,
          <br /> co uniemożliwi aplikacji wykonywanie dalszych operacji w Twoim imieniu
        </>,
      ],
      icon: 'check circle outline',
    },
  ];

  return (
    <Step.Group vertical style={{ width: '100% !important' }}>
      <Accordion>
        {stepsInfo.map(({ header, content, icon }, idx) => (
          <Step key={idx} active={currentStep === idx} completed={idx < currentStep}>
            <Icon name={icon} />
            <Step.Content>
              <Accordion.Title
                active={idx === currentStep || idx === activeIdx}
                index={idx}
                onClick={toggleActiveStepIdx}
              >
                <Icon name='dropdown' />
                {header}
              </Accordion.Title>
              <Accordion.Content active={idx === currentStep || idx === activeIdx}>
                <Step.Description>
                  <List bulleted>
                    {content.map((c, idx) => (
                      <List.Item style={{ margin: '0 1.5rem' }} key={idx}>
                        {c}
                      </List.Item>
                    ))}
                  </List>
                </Step.Description>
              </Accordion.Content>
            </Step.Content>
          </Step>
        ))}
      </Accordion>
    </Step.Group>
  );
};

const FaqModal = ({ reqScopes }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(isOpen => !isOpen);
  const title = 'Często zadawane pytania';
  return (
    <div>
      <button className='borderOff link' style={{ cursor: 'pointer' }} onClick={toggleModal}>
        {title}
      </button>
      <Modal dimmer='inverted' open={isOpen} onClose={toggleModal}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <FAQ reqScopes={reqScopes} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={toggleModal}>
            Zamknij
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

class FAQ extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, { index }) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  faqContents = [
    {
      title: 'Dlaczego aplikacja może chcieć uzyskać dostęp do danych mojego konta?',
      content: [
        `Przekaznie zweryfikowanych danych Twojego konta może usprawnić np. proces przygotowania
        przesyłki lub wygenerowania faktury - aplikacja posiada wówczas niezbędne
        infromacje, a Ty nie musisz wypełniać kolejnych formularzy.`,
      ],
    },
    {
      title: 'Dlaczego aplikacja może chcieć przekazania kluczy API?',
      content: [
        `Utworzenie i przekazanie kluczy API pozwala aplikacji wykonywać w Twoim imieniu operacje
        takie jak tworzenie zleceń płatniczych.`,
        <>
          <Popup
            flowing
            basic
            content={<ScopesList scopes={this.props.reqScopes} />}
            trigger={
              <span style={{ cursor: 'pointer', color: '#2867b4' }}>
                Zakres dostępnych operacji
              </span>
            }
          />
          , które aplikacja może wykonywać jest ograniczony.
        </>,
      ],
    },
    {
      title: 'Czy moje dane są bezpieczene?',
      content: [
        <>
          W związku z poufną naturą przekazywanych informacji wprowadzamy dodatkową warstwę
          zabezpieczeń - przekazujemy je w formie zaszyfrowanej, aby nawet w przypadku
          nieuprawnionego przechwycenia nie było możliwe zrobienie z nich użytku.
        </>,
      ],
    },
  ];

  render() {
    const { activeIndex } = this.state;

    return (
      <Accordion>
        {this.faqContents.map(({ title, content }, idx) => (
          <React.Fragment key={idx}>
            <Accordion.Title
              key={title}
              active={activeIndex === idx}
              index={idx}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              {title}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === idx}>
              {content.map((c, idx) => (
                <p style={{ marginLeft: '1.5em' }} key={idx}>
                  {c}
                </p>
              ))}
            </Accordion.Content>
          </React.Fragment>
        ))}
      </Accordion>
    );
  }
}

const ShareRequestInfo = ({ request, isLoggedIn, isPartnerAccepted }) => (
  <>
    {request && (
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', padding: '2rem' }}
      >
        <Header textAlign='center' size='medium'>
          Aplikacja <b>{request.requestedByApplication}</b>
          <br /> chce uzyskać dostęp do Twojego konta Fiberpay
        </Header>
        <Steps
          isLoggedIn={isLoggedIn}
          isPartnerAccepted={isPartnerAccepted}
          appName={request.requestedByApplication}
        />
        <FaqModal reqScopes={request.scopes} />
      </div>
    )}
  </>
);

const mapStateToProps = state => ({
  request: state.getShareRequest,
  setRequestStatus: state.setShareRequestStatus,
  user: state.getUser,
  partner: state.getPartner,
});

class ShareRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: null,
      user: null,
      loading: true,
      isPartnerAccepted: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    const { code } = this.props.match.params;
    this.props.actions.getAccessKeyShareRequest(code);
  }

  checkBearerToken() {
    return !!localStorage.getItem('authToken');
  }

  componentDidUpdate(prevProps) {
    const { request, user, partner } = this.props;
    if (request !== prevProps.request) {
      this.setState(
        {
          request: request?.data,
          errorMessage: request?.reason,
        },
        () => {
          if (request?.data?.status === 'accepted' || request?.data?.status === 'rejected') {
            this.handleForgetConsentCode();
          } else {
            this.handleRememberConsentCode();
          }
          if (localStorage.getItem('authToken')) {
            this.props.actions.getUser();
          } else {
            this.setState({ loading: false });
          }
        },
      );
    }

    if (user !== prevProps.user) {
      this.setState({ user: user.data }, this.props.actions.getPartner);
    }

    if (partner !== prevProps.partner) {
      this.setState({ isPartnerAccepted: partner.data?.kycStatus === 'accepted', loading: false });
    }

    if (this.state.errorMessage) {
      this.handleForgetConsentCode();
    }
  }

  handleSetStatus = (code, status) => () => {
    this.handleForgetConsentCode();
    this.props.actions.setAccessKeyShareRequestStatus(code, { status });
  };

  handleLogin = () => {
    localStorage.removeItem('authToken');
    this.handleRememberConsentCode();
    history.push(`/login`);
  };

  handleConsentRejected = code => {
    this.props.actions.setAccessKeyShareRequestStatus(code, { status: 'rejected' });
    this.handleForgetConsentCode();
    history.push('/login');
  };

  handleRedirectToKyc = () => {
    this.handleRememberConsentCode();
    history.push('/panel/kyc');
  };

  handleForgetConsentCode = () => localStorage.removeItem('shareConsent');
  handleRememberConsentCode = () =>
    localStorage.setItem('shareConsent', this.props.match.params.code);

  renderActionButtons() {
    const { user, request, isPartnerAccepted, errorMessage } = this.state;
    if (request) {
      if (user) {
        return (
          <Segment basic textAlign='center'>
            <Segment basic>
              <p>Jesteś zalogowany jako {user.email}</p>
              {isPartnerAccepted ? (
                <Button positive onClick={this.handleSetStatus(request.code, 'accepted')}>
                  Zgadzam się na przekazanie danych
                </Button>
              ) : (
                <Button primary onClick={this.handleRedirectToKyc}>
                  Przejdź do ustawień weryfikacji konta
                </Button>
              )}
            </Segment>
            <div>lub</div>
            <Segment basic>
              <span className='link' style={{ cursor: 'pointer' }} onClick={this.handleLogin}>
                Zaloguj się na inne konto
              </span>
            </Segment>
            <Segment basic padded textAlign='center'>
              <Divider />
              <a href={request.returnUrl} target='__blank' rel='noopener noreferrer'>
                Powrót do strony aplikacji <b>{request.requestedByApplication}</b>
              </a>
            </Segment>
            <div>
              <span
                onClick={() => this.handleConsentRejected(request.code)}
                style={{ cursor: 'pointer', fontSize: '.9rem' }}
              >
                Nie wyświetlaj tego ekranu ponownie
              </span>
            </div>
          </Segment>
        );
      } else {
        return (
          <>
            <Segment basic textAlign='center'>
              <Button onClick={this.handleLogin}>Zarejestruj się lub zaloguj</Button>
            </Segment>
            <Segment basic padded textAlign='center'>
              <Divider />
              <a href={request.returnUrl} target='__blank' rel='noopener noreferrer'>
                Powrót do strony aplikacji <b>{request.requestedByApplication}</b>
              </a>
            </Segment>
          </>
        );
      }
    }
    if (errorMessage) {
      return (
        <>
          <Segment basic textAlign='center'>
            <p>Jesteś zalogowany jako {user?.email}</p>
            <Message error>{errorMessage}</Message>
          </Segment>
          <Segment basic textAlign='center'>
            <span className='link' style={{ cursor: 'pointer' }} onClick={this.handleLogin}>
              Zaloguj się na inne konto
            </span>
          </Segment>
        </>
      );
    }
  }

  renderAccepted = () => {
    const { request } = this.state;
    return (
      <>
        <Message success header='Wygenerowano i przekazano klucze API' />
        <Segment basic padded textAlign='center'>
          <a href={request.returnUrl} target='__blank' rel='noopener noreferrer'>
            <Button primary>
              Powrót do strony aplikacji <b>{request.requestedByApplication}</b>
            </Button>
          </a>
          <Segment basic>
            <Link to='/panel'>Przejdź do panelu użytkownika Fiberpay</Link>
          </Segment>
        </Segment>
      </>
    );
  };

  render() {
    const { user, request, isPartnerAccepted, loading } = this.state;
    if (loading) return <Segment basic loading style={{ minHeight: '60vh' }} />;
    return (
      <Container>
        <ShareRequestInfo
          request={request}
          isLoggedIn={!!user}
          isPartnerAccepted={isPartnerAccepted}
        />
        {request?.status === 'accepted' ? this.renderAccepted() : this.renderActionButtons()}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareRequests);
