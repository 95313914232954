import React, {Component} from 'react';
import {Button, Container, Header, Label, Menu, Popup, Segment} from 'semantic-ui-react';
import {ENVIRONMENT} from '../../../constants/index';
import Channels from './channels';
import StandardTransfer from './standardTransfer';
import TransferSimulation from './transferSimulation';

const MAX_CASHBILL_PAYMENT_AMOUNT = {PLN: 8000};

export class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.isAmountValidForCashbill() && this.props.channels.quickPayments ? 0 : 1,
      errorFirstName: false,
      errorLastName: false,
    };
  }

  componentDidMount(){
    if(this.state.type === 1){
      this.handleSelect('standard', this.props.channels.standard.amount);
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.type !== this.state.type){
      if(this.state.type === 1){
        this.handleSelect('standard', this.props.channels.standard.amount);
      } else {
        this.handleSelect(null, null);
      }
    }
  }

  handleSelect = (value, amount) => {
    this.props.handleChannelSelect(value, amount);
  };

  renderMethod() {
    const p = this.props;
    switch (this.state.type) {
      case 0:
        return (
          <Channels
            channels={p.channels.quickPayments}
            selectedId={p.paymentChannelId}
            handleSelect={this.handleSelect}
          />
        );

      case 1:
        return (
          <StandardTransfer
            orderInvoice={p.orderInvoice}
            invoice={p.channels.standard}
            toggleStandardPaid={p.toggleStandardPaid}
            standardPaid={p.standardPaid}
          />
        );

      case 2:
        return <TransferSimulation orderInvoice={p.orderInvoice} fetchOrderInvoice={this.props.fetchOrderInvoice} />;

      default:
        break;
    }
  }

  isAmountValidForCashbill = () => {
    const { currency, amount } = this.props.orderInvoice;
    if (currency !== 'PLN') {
      return false; //obsługiwane tylko PLN
    }
    return parseFloat(amount) <= MAX_CASHBILL_PAYMENT_AMOUNT[currency];
  };

  isFizenPayment = () => {
    return (this.props.channels?.quickPayments || [])
      .some(ch => ch.id === this.props.paymentChannelId && ch.provider === 'fizen');
  }

  render() {
    const { currency } = this.props.orderInvoice;
    const p = this.props;
    const s = this.state;

    return (
      <Container text>
        <Segment basic>
          <Header textAlign='center'>Wybierz formę płatności</Header>
        </Segment>
        <Menu secondary style={{ justifyContent: 'center' }}>
          <Popup
            content={`Dostępne dla kwot nie większych niż ${MAX_CASHBILL_PAYMENT_AMOUNT[currency]} ${currency}`}
            position='left center'
            disabled={this.isAmountValidForCashbill()}
            trigger={
              <Menu.Item
                name='quickPayments'
                onClick={() => this.setState({ type: 0 })}
                active={s.type === 0}
                disabled={!this.isAmountValidForCashbill() || !p.channels.quickPayments}
              >
                Szybkie płatności
              </Menu.Item>
            }
          />
          {p.channels.standard && (
            <Menu.Item
              name='standard'
              onClick={() => this.setState({type: 1})}
              active={s.type === 1}
            >
              {p.channels.standard.feeType === 'discount' && <Label as='a' color='red' floating size='mini'>
                Zniżka
              </Label>}
              Przelew tradycyjny
            </Menu.Item>
          )}
          {ENVIRONMENT === 'development' && (
              <Menu.Item
                name="simulation"
                onClick={() => this.setState({type: 2})}
                active={s.type === 2}
              >
                Symulacja transakcji
              </Menu.Item>
          )}
        </Menu>
        {this.renderMethod()}
        {!this.props.standardPaid && this.props.orderInvoice.status !== 'paid' && (
          <Segment basic style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button basic onClick={p.handlePrevStep}>
              Wstecz
            </Button>
            {this.state.type === 0 && (
              <Segment basic>
                {this.isFizenPayment() && (
                  <Button primary basic disabled={!p.paymentChannelId} onClick={() => p.handleSubmit(p.businessContexts.COMPANY)}>
                    Kontynuuj (Konto firmowe)
                  </Button>

                )}
              <Button primary disabled={!p.paymentChannelId} onClick={() => p.handleSubmit()}>
                Kontynuuj
              </Button>
              </Segment>
            )}
          </Segment>
        )}
      </Container>
    );
  }
}

export default PaymentMethods;
