import React, { Component } from 'react'
import { Segment, Container, Header, Button, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {l, t} from "../../i18n";

function mapStateToProps(state) {
	return {};
}

class Logout extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	componentDidMount() {
		this.props.actions.userCheckLoggedIn();
	}

	logout(e) {
		e.preventDefault();
		this.setState({
			loading: true
		}, this.callLogout);
	}

	callLogout() {
		this.props.actions.userLogout();
	}

	render() {
		return (
			<Container>
				<Segment basic>
					<Segment textAlign='center'>
						<Header icon>
							<Icon name='sign out' id='icon-blue'/>
							{t("logout.areYouSure")}
						</Header>
						<Segment basic>
							<Button primary as={Link} to={l+'/panel'}>{t("no")}</Button>
							<Button primary onClick={this.logout.bind(this)}>{t("yes")}</Button>
						</Segment>
					</Segment>
				</Segment>
				<Dimmer inverted active={this.state.loading}>
					<Loader content={t("system.loading")} />
				</Dimmer>
			</Container>

		)
	}
}

function mapDispatchToProps(dispatch) {
	return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)