import React from 'react';
import { Button, Modal, Form, Grid } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { t } from '../../i18n';
import FormComponent from '../containers/formcomponent';

const providerOptions = [
  { key: 'fa', value: 'fiber_auth', text: 'FiberToken' },
  { key: 'ga', value: 'google_auth', text: 'Google Authenticator' },
];

const initialState = {
  isOpen: false,
  name: '',
  provider: '',
  default: true,
};

class CreateDeviceModal extends FormComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSubmit = () => {
    if (!this.canBeSubmitted()) return;

    this.props.actions.create2FADevice({
      deviceName: this.state.name,
      provider: this.state.provider,
      default: this.state.default,
    });

    this.setState(initialState);
  }

  canBeSubmitted = () => {
    const { name, provider } = this.state;
    return provider && name && name.length < 20;
  }

  render() {
    return (
      <Modal
        open={this.state.isOpen}
        onClose={() => {
          this.setState(initialState);
        }}
        onOpen={() => {
          this.setState({ isOpen: true });
        }}
        trigger={<Button primary content={t('2fa.add')} />}
        size='tiny'
      >
        <Modal.Header style={{ textAlign: 'center' }}>{t('2fa.createDevice')}</Modal.Header>
        <Modal.Content>
          <Grid centered padded>
            <Form onSubmit={this.handleSubmit}>
              <Form.Input
                placeholder={t('2fa.deviceName')}
                focus
                name='name'
                type='text'
                onChange={this.handleValueChange.bind(this)}
                value={this.state.name}
                required
              />
              <Form.Select
                placeholder={t('2fa.selectProvider')}
                name='provider'
                options={providerOptions}
                onChange={this.handleValueChange.bind(this)}
                required
              />
              <Form.Checkbox
                name='default'
                onChange={this.handleCheckBoxChange.bind(this)}
                checked={this.state.default}
                label={t('2fa.setAsDefaultMethod')}
              />
            </Form>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={this.handleSubmit}
            disabled={!this.canBeSubmitted()}
          >
            {t('2fa.create')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(null, mapDispatchToProps)(CreateDeviceModal);
