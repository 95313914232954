import { Component } from 'react';

export default class BaseComponent extends Component {

  handleValueChange(e, data) {
    this.setState({
      [data.name]: data.value
    })
  }

  handleValueBlur(e){
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleCheckBoxChange(e, data) {
    this.setState({
      [data.name]: data.checked
    })
  }

  handleValueEventChange(e){
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getBaseUrlPath(){
    let url = this.props.match.url;
    if(this.props.match.params.lang){
      url = url.substr(3);
    }
    if(url[url.length-1] !== '/'){
      url = url+'/';
    }
    return url;
  }

  getRouterActiveItem(){
    return this.props.location.pathname.split('/')[this.props.match.url.split('/').length];
  }

  getPreviousUrl(){
    let url = this.getBaseUrlPath();
    url = url.split('/');
    url.pop();
    url.pop();
    url = url.join('/');
    return url;
  }
}