import React, { Component } from 'react';
import '../css/App.css';
import * as actions from '../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Image, Sidebar, Icon, Segment, Container, Button, Message } from 'semantic-ui-react'

import logo from '../images/fiberpay-cobalt.svg';
import Footer from './parts/footer';
import {l, t} from "../i18n";
import AppRouting from './routes/approuting';

import ueLogo from "../images/ue_logo_render.png";

function mapStateToProps(state) {
  return {
    generalError: state.generalError,
    action: state.get2FAAction
  };
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sideBarVisible: false,
      errorMessage: '',
      errorVisible: false
    };
  }

  toggleSidebarVisibility() {
    this.setState({ sideBarVisible: !this.state.sideBarVisible });
  }

  hideSidebar() {
    if (this.state.sideBarVisible) this.setState({ sideBarVisible: false });
  }

  menu() {
    return (
      <>
        <Menu id="top-menu" secondary>
          <Menu.Item className="toc" style={{ display: "none" }}>
            <Icon size="large" name='sidebar'
              onClick={this.toggleSidebarVisibility.bind(this)} />
          </Menu.Item>
          <Menu.Item as={Link} to={l+'/'}>
            <Image src={logo} size="small" />
          </Menu.Item>

          <Menu.Menu position='right'>
            <Menu.Item as={Link} to={l+'/'} className="toc" style={{ display: "none" }}>
              <Image src={logo} size="small" />
            </Menu.Item>

            {this.props.match.isExact && <Image src={ueLogo} id='ueproject-computer' size="tiny" style={{margin: "0 12px"}}/>}
            <Menu.Item as={Link} to={l+'/login'} name='login'><Button primary>{t('Account')}</Button></Menu.Item>

          </Menu.Menu>
        </Menu>
        {this.props.match.isExact && <Segment basic id='ueproject-mobile'>
          <Image src={ueLogo} centered/>
        </Segment>}
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.generalError !== this.props.generalError) {
      let error = this.props.generalError;
      if (error) {
        if (error.time >= Date.now() - 10) {
          this.setState({
            errorMessage: error.data.reason,
            errorVisible: true
          });
        } else {
          if (this.state.errorVisible)
            this.setState({
              errorVisible: false
            })
        }
      }
    }
  }

  render() {
    const { sideBarVisible } = this.state;
    return (
      <div>
        <Sidebar.Pushable as={Segment} className="borderOff" >
          <Sidebar as={Menu} animation='push' visible={sideBarVisible}
            vertical inverted>
            <Menu.Item name='login' as={Link} to={l+'/login'}>Login</Menu.Item>
          </Sidebar>
          <Sidebar.Pusher onClick={this.hideSidebar.bind(this)}>
            <div className="ui segment vertical header menuSegment">
              <Container>
                {this.menu()}
              </Container>
            </div>
            <div>
              <Message error header={t("system.error")} content={this.state.errorMessage} hidden={!this.state.errorVisible} />
            </div>

            <div style={{
              minHeight: 'calc(100vh - 240px)',
              padding: '0'
            }}>
              <AppRouting path={this.props.match.path}/>

            </div>
            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
