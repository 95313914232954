import {Icon, Popup} from "semantic-ui-react";
import React from "react";
import {t} from "../../i18n";

const Statuses = (props) => {
  const orderStatuses = {
    'defined': t("orders.status.desc.defined"),
    'completed': t("orders.status.desc.completed"),
    'failed':  t("orders.status.desc.failed"),
    'open': t("orders.status.desc.open"),
    'cancelled': t("orders.status.desc.cancelled"),
    'partially_completed': t("orders.status.desc.partially_completed"),
    'payment_received': t("orders.status.desc.payment_received"),
    'processed': t("orders.status.desc.processed")
  }

  return <Popup
    trigger={<Icon circular name='info'
                   color='grey' size='small'/>}
    content={orderStatuses[props.status]}
    on='hover'
    position='top left'
  />
}

export default Statuses;
