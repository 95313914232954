import React, {Component} from 'react';
import { Container, Segment, Table, Dimmer, Loader, Divider } from 'semantic-ui-react';
import {Pagination} from '../utils/pagination';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import history from "../../history";
import * as actions from '../../actions';
import {l, t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getInvoices: state.getInvoices
  };
}

class Invoices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      meta: {
        count: '',
        current_page: '',
        per_page: '',
        last_page: '',
      },
      loading: true,
      page: 1,
      pages: [],
    }
  }

  componentDidMount() {
    this.props.actions.getInvoices(this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getInvoices !== this.props.getInvoices) {
      let invoices = this.props.getInvoices;
      if (invoices) {
        this.setState({
          loading: false,
          data: invoices.data,
          meta: invoices.meta
        })
      }
    }
  }

  callPagination(options) {
    this.setState({
      current_page: options.pageNo,
      page: options.name,
      pages: options.pages,
      last_page: options.pagesCount,
      offset: options.offset,
      per_page: options.limit
    });

    this.props.actions.getInvoices({ page: options.pageNo });
  }

  getDetails(code) {
    history.push(l+`/panel/invoices/${code}`);
  }

  render() {
    const { data, meta } = this.state;

    return (
      <Container>
        <Segment basic>
          <Dimmer inverted active={this.state.loading}>
            <Loader content={t('system.loading')}/>
          </Dimmer>
          <Divider horizontal className='list-title'>{t('invoices.title')}</Divider>
            {!!data.length && !this.state.loading ? (
              <Table compact selectable columns='5'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign={'center'}>{t('code')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'center'}>{t('invoices.number')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'center'}>{t('invoices.billingPeriod')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'center'}>{t('invoices.issueDate')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={'center'}>{t('invoices.amount')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                 <Table.Body>
                  {data.map(({ data }) => (
                    <Table.Row key={data.code} onClick={this.getDetails.bind(this, data.code)}>
                      <Table.Cell textAlign={'center'}>{data.code}</Table.Cell>
                      <Table.Cell textAlign={'center'}>{data.number}</Table.Cell>
                      <Table.Cell textAlign={'center'}>{data.billingPeriod}</Table.Cell>
                      <Table.Cell textAlign={'center'}>{data.issueDate}</Table.Cell>
                      <Table.Cell textAlign={'center'}>{`${data.amount} ${data.currency}`}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Footer>
                  <Pagination
                    columnNumber={5}
                    showFunction={this.callPagination.bind(this)}
                    pageNo={meta.current_page}
                    pages={this.state.pages}
                    pagesCount={meta.last_page}
                    limit={meta.per_page}
                  />
                </Table.Footer>
              </Table>
            ) : <Segment textAlign='center'>{t('invoices.empty')}</Segment>
          }
        </Segment>
      </Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
