import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from '../../actions';

const mapStateToProps = state => ({
	notifyBlikRedirect: state.notifyBlikRedirect,
});
class BlikRedirectHandler extends Component{
	componentDidMount() {
		const urlSearchParams = new URLSearchParams(this.props.location.search);
		const params =([...urlSearchParams.entries()].reduce((acc, [key, value]) => {
			acc[key] = value;
			return acc;
		}, {}));

		const { status } = this.props.match.params;
		 this.props.actions.notifyBlikRedirect(status, params);
	}

	componentDidUpdate(prevProps, prevState) {
		const {notifyBlikRedirect} = this.props;
		if (prevProps.notifyBlikRedirect !== notifyBlikRedirect) {
			if (notifyBlikRedirect.status === 'ERROR') {
				this.setState({error: this.props.notifyBlikRedirect.reason})
			} else {
				const { orderCode, isSuccess } = notifyBlikRedirect;
				let url = `/order/${orderCode}`;
				if(!isSuccess) url += '?blikStatus=failure';
				this.props.history.push(url);
			}
		}
	}

	render() {
		return <pre>{JSON.stringify(this.props, null, 2)}</pre>
	}
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(BlikRedirectHandler);
