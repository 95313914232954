import React from 'react';
import { Message, Segment } from 'semantic-ui-react';
import {t} from "../../i18n";

const ErrorPanel = () => (
  <Segment basic>
    <Message error
      icon='exclamation triangle'
      header={t("system.anError")}
      content={t("system.somethingWrong")}
    />
  </Segment>
)

export default ErrorPanel;
