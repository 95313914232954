import React from 'react';
import {connect} from 'react-redux';

import {bindActionCreators} from 'redux';
import {Button, Container, Grid, Icon, Image, Message, Segment} from 'semantic-ui-react';
import * as actions from '../../../actions';

import {t} from '../../../i18n';
import logo from '../../../images/fiberpay-white.svg';
import background from '../../../images/fiberpay_background.jpg';
import FormComponent from '../../containers/formcomponent';
import OrderDescription from './orderDescription';
import PaymentMethods from './paymentMethods';
import PersonalData from './personaldata';

const PAYMENT_STATUS = {
  NEW: 'new',
  TO_PAY: 'to_pay',
  PAYMENT_CONFIRMATION_PENDING: 'payment_confirmation_pending',
  PAID: 'paid',
  CANCELLED: 'cancelled',
}

const PAYMENT_BUSINESS_CONTEXT = {
  RETAIL: 'retail',
  COMPANY: 'company',
}

function mapStateToProps(state) {
  return {
    status: state.cashbillPaymentInfo,
    channels: state.paymentChannels,
    payment: state.initializePayment,
    setPayer: state.setCashbillPayer,
    orderInvoice: state.getOrderInvoice
  };
}

const dict = {
  [PAYMENT_STATUS.NEW]: t('paymentStatus.new'),
  [PAYMENT_STATUS.TO_PAY]: t('paymentStatus.waitingForPayment'),
  [PAYMENT_STATUS.PAYMENT_CONFIRMATION_PENDING]: t('paymentStatus.paymentConfirmationPending'),
  [PAYMENT_STATUS.PAID]: t('paymentStatus.paymentReceived'),
  [PAYMENT_STATUS.CANCELLED]: t('paymentStatus.cancelled'),
};

class Order extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      stepLoading: false,
      standard: false,
      stardardPaid: false,
      stepNo: 0,
      firstName: '',
      lastName: '',
      email: '',
      regulationsAccepted: false,
      regulationsAcceptanceRequired: true,
      paymentChannelId: null,
      paymentChannelAmount: null,
      channels: null,
      channelsError: null,
      orderInvoice: null,
      externalPaymentFailureMessage: null,
      blikStatus: null,
      invoiceReloadHandler: null,
    };
  }

  componentDidMount() {
    this.fetchOrderInvoice()

    const searchParams = new URLSearchParams(this.props.location.search)
    let blikStatus = searchParams.get('blikStatus');
    if (blikStatus === 'failure') {
      this.setState({
        externalPaymentFailureMessage: 'Płatność BLIK zakończona niepowodzeniem, spróbuj ponownie',
        blikStatus
      });
      this.props.history.replace(this.props.location.pathname);
    }

    this.setState({
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      email: searchParams.get('email') || '',
    });
  }

  fetchOrderInvoice = () => {
    const {code} = this.props.match.params;
    if (code.length === 8 || code.length === 12) {
      this.props.actions.getInvoiceForOrder(code);
    } else if (code.length === 9) {
      this.props.actions.getOrderInvoice(code);
    }
  }

  startOrderInvoiceReload() {
    const handler = this.state.invoiceReloadHandler;
    if(handler) return;

    const invoiceReloadHandler = setInterval(this.fetchOrderInvoice, 1000 * 5);
    console.log('oi reload start')
    this.setState({invoiceReloadHandler});
  }

  stopOrderInvoiceReload() {
    const handler = this.state.invoiceReloadHandler;
    if(handler) {
      console.log('oi reload stop')
      clearInterval(handler);
    }
  }

  componentDidUpdate(prevProps) {
    const { channels, payment, setPayer, orderInvoice } = this.props;

    if (orderInvoice !== prevProps.orderInvoice) {
      console.log('oi', orderInvoice)
      if (orderInvoice.data) {
        const { payer, status, code} = orderInvoice.data;
        this.setState({
          orderInvoice: orderInvoice.data,
          firstName: payer?.firstName || this.state.firstName,
          lastName: payer?.lastName || this.state.lastName,
          email: payer?.email || this.state.email,
        });
        if (status === PAYMENT_STATUS.TO_PAY) {
          this.props.actions.getPaymentChannels(code);
        } else {
          this.setState({ loading: false });
        }

        if(status === PAYMENT_STATUS.PAYMENT_CONFIRMATION_PENDING) {
          this.startOrderInvoiceReload();
        } else {
          this.stopOrderInvoiceReload();
        }
      } else {
        this.setState({ loading: false });
      }
    }

    if (setPayer !== prevProps.setPayer) {
      if (setPayer.data) {
        this.validateForm(setPayer, () => {
          if (this.state.paymentChannelId) {
            this.handleSubmitPayment();
          } else {
            this.incrementStepNo();
            this.setState({stepLoading: false});
          }
        });
      } else {
        this.setState({ stepLoading: false });
      }
    }

    if (channels !== prevProps.channels) {
      if (channels.reason) {
        this.setState({ channels, loading: false });
      } else {
        const channelsArr = Object.values(channels).flat();
        const isBlikOnly = channelsArr.length === 1 && channelsArr[0].id === 'blik';
        const regulationsAcceptanceRequired = !!this.state.orderInvoice.showRegulations;
        const searchParams = new URLSearchParams(this.props.location.search);
        if (isBlikOnly && !regulationsAcceptanceRequired) {
          const isAfterFailedBlikPayment = this.state.blikStatus === 'failure';
          if (isAfterFailedBlikPayment) {
            this.incrementStepNo();
          } else {
            this.setState({stepLoading: true}, () => {
              this.props.actions.initializePayment(this.state.orderInvoice.code, {
                channel: channelsArr[0].id,
              });
            });
          }
        }
        const queryChannelId = (searchParams.get('channel'))?.toLowerCase();
        const isValidChannelId = channels?.quickPayments?.some(c => c.id === queryChannelId);
        const paymentChannelId = isValidChannelId ? queryChannelId : this.state.paymentChannelId;
        this.setState({channels, loading: false, standard: !!channels?.standard, paymentChannelId});
      }
    }

    if (payment !== prevProps.payment) {
      if (payment.reason) {
        this.setState({ stepLoading: false });
      } else {
        if(payment.data.provider === 'blik') {
          const url = new URL(payment.data.redirectUrl);
          this.handleBlikRedirect(url);
        } else {
          window.location.href = payment.data.redirectUrl;
        }
      }
    }
  }

  componentWillUnmount() {
    // this.stopOrderInvoiceReload();
  }

  handleBlikRedirect(url) {
    const form = document.createElement('form');
    form.action = url.origin + url.pathname;
    form.method = 'POST';

    const inputs = document.createDocumentFragment();

    for (const [name, value] of [...url.searchParams.entries()]) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      inputs.appendChild(input);
    }

    form.append(inputs);
    document.body.append(form);
    form.submit();
  }

  handleSubmitPayment = (businessContext = null) => {
    const { paymentChannelId, orderInvoice } = this.state;
    this.setState({ stepLoading: true }, () => {
      const data = {
        channel: paymentChannelId,
      };
      if(businessContext) {
        data.businessContext = businessContext;
      }
      this.props.actions.initializePayment(orderInvoice.code, data);
    });
  };

  handleSubmitPayerData = () => {
    const { firstName, lastName, email, regulationsAccepted, orderInvoice } = this.state;
    const data = { firstName, lastName, email, regulationsAccepted };

    this.setState({ stepLoading: true }, () => {
      this.props.actions.setCashbillPayer(orderInvoice.code, data);
    });
  };

  toggleStandardPaid = () => this.setState({ standardPaid: !this.state.standardPaid });

  incrementStepNo = () => {
    this.setState({ stepNo: this.state.stepNo + 1 });
  };

  decrementStepNo = () => {
    this.setState({ stepNo: this.state.stepNo - 1 });
  };

  handleChannelSelect = (paymentChannelId, paymentChannelAmount) =>{
    this.setState({paymentChannelId, paymentChannelAmount});
  }

  renderStep() {
    const s = this.state;
    switch (s.stepNo) {
      case 0:
        return (
          <Segment basic loading={s.stepLoading}>
            <PersonalData
              isLoading={s.stepLoading}
              handleValueChange={this.handleValueChange.bind(this)}
              handleCheckBoxChange={this.handleCheckBoxChange.bind(this)}
              handleSubmit={this.handleSubmitPayerData}
              incrementStepNo={this.incrementStepNo}
              firstName={s.firstName}
              lastName={s.lastName}
              email={s.email}
              regulationsAccepted={s.regulationsAccepted}
              regulationsAcceptanceRequired={s.orderInvoice.showRegulations || null}
            />
          </Segment>
        );
      case 1:
        return (
          <Segment basic loading={s.stepLoading}>
            <PaymentMethods
              handlePrevStep={this.decrementStepNo}
              handleSubmit={this.handleSubmitPayment}
              handleChannelSelect={this.handleChannelSelect}
              toggleStandardPaid={this.toggleStandardPaid}
              standardPaid={s.standardPaid}
              firstName={s.firstName}
              lastName={s.lastName}
              channels={s.channels}
              paymentChannelId={s.paymentChannelId}
              orderInvoice={s.orderInvoice}
              businessContexts={PAYMENT_BUSINESS_CONTEXT}
              invoice={s.invoice}
              fetchOrderInvoice={() => this.fetchOrderInvoice()}
            />
          </Segment>
        );

      default:
        break;
    }
  }

  order() {
    const { orderInvoice, channels } = this.state;
    const status = dict[orderInvoice.status];

    return (
      <div
        style={{
          margin: 0,
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          minHeight: 'calc(100vh + 14px)',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '768px' }}>
            <Grid centered>
              <Grid.Row>
                <Image src={logo} size='small' style={{ paddingTop: '10px' }} />
              </Grid.Row>
            </Grid>
            <Segment>
              <OrderDescription orderInvoice={orderInvoice} paymentChannelAmount={this.state.paymentChannelAmount}/>
            </Segment>
            {this.paymentFailureMessage()}
            <Segment>
              {channels?.standard || channels?.quickPayments ? (
                this.renderStep()
              ) : (
                <Segment basic>
                  {channels?.reason ? (
                    <Message error>{channels.reason}</Message>
                  ) : (
                    <Message icon>
                      <Icon name={orderInvoice.status === 'paid' ? 'check circle' : 'circle notch'} />
                      <Message.Content>
                        <Message.Header>Informacja o statusie transakcji</Message.Header>
                        {status}
                      </Message.Content>
                    </Message>
                  )}
                </Segment>
              )}
              {this.additionalInfo()}
              {this.redirect()}
            </Segment>
            <br />

            {this.footer()}

            <br />
          </div>
        </div>
      </div>
    );
  }

  additionalInfo() {
    const o = this.state.orderInvoice;
    let paymentInfo = null;
    if(o.status === PAYMENT_STATUS.PAID || this.state.standardPaid) paymentInfo = o.afterPaymentInfo;
    else if(o.status === PAYMENT_STATUS.TO_PAY) paymentInfo = o.beforePaymentInfo;
    if (paymentInfo) {
      return (
        <Segment basic>
          <Message content={JSON.parse(paymentInfo)} />
        </Segment>
      );
    }
  }

  redirect() {
    const { orderInvoice, standardPaid } = this.state;
    if ((standardPaid || orderInvoice.status === 'paid') && orderInvoice.redirectUrl) {
      return (
        <Segment basic textAlign='center'>
          <Button primary as='a' href={orderInvoice.redirectUrl} >
           <p style={{fontWeight:'bold', fontSize:'14px'}}>{t('Powróć na stronę zlecającego płatność')}</p>
          </Button>
        </Segment>
      );
    }
  }

  // noinspection JSMethodCanBeStatic
  footer() {
    return (
      <Container style={{ fontSize: 'small', color: 'white' }} textAlign='left'>
        <p>
          Płatność odbywa się za pośrednictwem <b>FiberPay sp. z o.o.</b>, zarejestrowana przez
          Komisję Nadzoru Finansowego w rejestrze małych instytucji płatniczych
        </p>
        <p>
          <b>FiberPay</b> jako Mała Instytucja Płatnicza, pośredniczy w przekazaniu środków pomiędzy
          Klientem, a Sprzedawcą. Przetwarzanie danych osobowych związanych z komunikacją i na
          potrzeby komunikacji następuje na zasadach wskazanych w Informacji dotyczącej
          przetwarzania danych osobowych.
        </p>
        <p>
          <b>FiberPay Sp. z o.o.</b> Sienna 86/47, 00-815 Warszawa, NIP: 7010634566, REGON:
          365899489, KRS: 0000647662. Wpis do rejestru małych instytucji płatniczych: MIP28/2019
        </p>
        {this.state.orderInvoice?.status === 'to_pay' && (
          <p>
            Pieniądze zostaną niezwłocznie przesłane do odbiorcy i zostanie on poinformowany jak
            tylko środki zostaną zaksięgowane na koncie FiberPay.
          </p>
        )}
      </Container>
    );
  }

  // noinspection JSMethodCanBeStatic
  notFound() {
    return (
      <Segment basic>
        <Message icon error>
          <Icon name='frown outline' loading />
          <Message.Content>
            <Message.Header>Wystapił błąd - nie znaleźliśmy płatności</Message.Header>
            <ul>
              <li>Sprawdź, czy na pewno masz poprawny link / identyfikator płatności</li>
              <li>Jeśli potrzebujesz pomocy zgłoś się do nas pisząc na info@fiberpay.pl</li>
            </ul>
          </Message.Content>
        </Message>
      </Segment>
    );
  }

  render() {
    const { orderInvoice, loading } = this.state;
    if (loading) {
      return <Segment basic loading style={{ height: '100vh' }} />;
    } else if (orderInvoice) {
      return this.order();
    } else {
      return this.notFound();
    }
  }

  paymentFailureMessage() {
    const { externalPaymentFailureMessage } = this.state;
    if(externalPaymentFailureMessage) {
      return <Message error>
        {externalPaymentFailureMessage}
      </Message>
    }
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
