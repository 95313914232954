import { combineReducers } from 'redux';
import * as constants from '../constants';

export function generalError(state = null, action) {
  switch (action.type) {
    case constants.GENERAL_ERROR:
      return null;
    default:
      break;
  }
  return state;
}

export function userLogin(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_LOGIN);
}

export function loginRequired(state = null, action) {
  return singleSwitchReducer(state, action, constants.LOGIN_REQUIRED);
}

export function userRegister(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_REGISTER);
}

export function resendEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.RESEND_EMAIL);
}

export function getApplication(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_APPLICATION);
}

export function getRequest(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_REQUEST);
}

export function getRequestCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_REQUEST_CODE);
}

export function getCallback(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_CALLBACK);
}

export function getCallbackCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_CALLBACK_CODE);
}

export function getCollectOrderItem(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_COLLECT_ORDER_ITEM);
}

export function createApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_APIKEYS);
}

export function getApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_APIKEYS);
}

export function disableApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.DISABLE_APIKEYS);
}

export function facebookAction(state = null, action) {
  return singleSwitchReducer(state, action, constants.FACEBOOK_ACTION);
}

export function facebookCallback(state = null, action) {
  return singleSwitchReducer(state, action, constants.FACEBOOK_CALLBACK);
}
export function getUser(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_USER);
}
export function getNotifications(state = null, action) {
    return singleSwitchReducer(state, action, constants.GET_NOTIFICATION);
}

export function getOrders(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDERS);
}

export function getDirectOrderByCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_DIRECT_ORDER_BY_CODE);
}

export function getCollectOrderByCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_COLLECT_ORDER_BY_CODE);
}

export function getCollectOrderItems(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_COLLECT_ORDER_ITEM_LIST);
}

export function getForwardOrderByCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_FORWARD_ORDER_BY_CODE);
}

export function getSplitOrderByCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SPLIT_ORDER_BY_CODE);
}

export function getSplitOrderItems(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SPLIT_ORDER_ITEM_LIST);
}

export function getSplitOrderItemByCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SPLIT_ORDER_ITEM_BY_CODE);
}

export function getCollectOrderItemByCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_COLLECT_ORDER_ITEM_BY_CODE);
}

export function getCollectOrderSettlements(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_COLLECT_ORDER_SETTLEMENTS);
}

export function getSettlement(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SETTLEMENT);
}

export function closeSettlement(state = null, action) {
  return singleSwitchReducer(state, action, constants.CLOSE_SETTLEMENT);
}

export function initializePayment(state = null, action) {
  return singleSwitchReducer(state, action, constants.INITIALIZE_PAYMENT);
}

export function setCashbillPayer(state = null, action) {
  return singleSwitchReducer(state, action, constants.SET_CASHBILL_PAYER);
}

export function cashbillPaymentChannels(state = null, action) {
  return singleSwitchReducer(state, action, constants.CASHBILL_PAYMENT_CHANNELS);
}

export function paymentChannels(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PAYMENT_CHANNELS);
}

export function getSettlementReport(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SETTLEMENT_REPORT);
}

export function get2FADevices(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_2FA_DEVICES);
}

export function create2FADevice(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_2FA_DEVICE);
}

export function enable2FADevice(state = null, action) {
  return singleSwitchReducer(state, action, constants.ENABLE_2FA_DEVICE);
}

export function get2FAAction(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_2FA_ACTION);
}

export function handle2FADecision(state = null, action) {
  return singleSwitchReducer(state, action, constants.EMAIL_2FA_DECISION);
}

export function getInvoices(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_INVOICES);
}

export function getInvoiceDetails(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_INVOICE_DETAILS);
}

export function getOrderItemsForInvoice(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_INVOICE_ORDER_ITEMS);
}

export function getInvoiceOrderItemsReport(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_INVOICE_ORDER_ITEMS_REPORT);
}

export function verifyEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.VERIFY_EMAIL);
}

export function sendEmailVerificationMail(state = null, action) {
  return singleSwitchReducer(state, action, constants.SEND_EMAIL_VERIFICATION_MAIL);
}

export function resetUserPassword(state = null, action) {
  return singleSwitchReducer(state, action, constants.RESET_USER_PASSWORD);
}

export function sendUserPasswordResetMail(state = null, action) {
  return singleSwitchReducer(state, action, constants.SEND_USER_PASSWORD_RESET_MAIL);
}

export function checkUserPasswordResetCode(state = null, action) {
  return singleSwitchReducer(state, action, constants.CHECK_USER_PASSWORD_RESET_CODE);
}

export function getOrdersFees(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDERS_FEES);
}

export function simulateOrderInvoicePayment(state = null, action) {
  return singleSwitchReducer(state, action, constants.SIMULATE_ORDER_INVOICE_PAYMENT);
}

export function logSwitch(state = null, action) {
  return singleSwitchReducer(state, action, constants.LOG_SWITCH);
}

export function getPartner(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTNER);
}

export function updatePartner(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_PARTNER);
}

export function findShareRequests(state = null, action) {
  return singleSwitchReducer(state, action, constants.FIND_ACCESS_KEY_SHARE_REQUESTS);
}

export function getShareRequest(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ACCESS_KEY_SHARE_REQUEST);
}

export function assignShareRequest(state = null, action) {
  return singleSwitchReducer(state, action, constants.ASSIGN_ACCESS_KEY_SHARE_REQUEST);
}

export function setShareRequestStatus(state = null, action) {
  return singleSwitchReducer(state, action, constants.SET_ACCESS_KEY_SHARE_REQUEST_STATUS);
}

export function getVerificationTransferOrderByCode(state = null, action) {
    return singleSwitchReducer(state, action, constants.GET_VERIFICATION_TRANSFER_ORDER_BY_CODE);
}
export function changeMarketingConsentSetting(state = null, action) {
    return singleSwitchReducer(state, action, constants.GET_USER);
}
export function changeUserPassword(state = null, action) {
    return singleSwitchReducer(state, action, constants.CHANGE_USER_PASSWORD);
}

export function createReturnSplitOrder(state = null, action) {
    return singleSwitchReducer(state, action, constants.CREATE_RETURN_SPLIT_ORDER);
}

export function exportWithdrawalCsv(state = null, action) {
  switch (action.type) {
    case constants.EXPORT_WITHDRAWAL_CSV:
      if (action.payload.status >= 400) return action.payload.data;
      const contentDisposition = action.payload.headers['content-disposition'];
      const filename = contentDisposition.split('filename=')[1].replaceAll('"', '');
      return {
        content: action.payload.data,
        filename,
      };
    default:
      break;
  }

  return state;
}

export function getOrder(state = null, action) {
  switch (action.type) {
    case constants.GET_ORDER:
      let order = action.payload.data;
      order.type = 'order';
      return order;
    case constants.GET_ORDER_ITEM:
      let orderItem = action.payload.data;
      orderItem.type = 'orderItem';
      return orderItem;
    default:
      break;
  }
  return state;
}

export function getOrderInvoice(state = null, action) {
  switch (action.type) {
    case constants.GET_ORDER_INVOICE:
    case constants.GET_INVOICE_FOR_ORDER:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function notifyBlikRedirect(state = null, action) {
  return singleSwitchReducer(state, action, constants.NOTIFY_BLIK_REDIRECT);
}

const rootReducer = combineReducers({
  generalError: generalError,
  loginRequired: loginRequired,
  userLogin: userLogin,
  userRegister: userRegister,
  resendEmail: resendEmail,
  getRequest: getRequest,
  getRequestCode: getRequestCode,
  getCallback: getCallback,
  getCallbackCode: getCallbackCode,
  getApplication: getApplication,
  getCollectOrderItem: getCollectOrderItem,
  createApiKeys: createApiKeys,
  getApiKeys: getApiKeys,
  disableApiKeys: disableApiKeys,
  facebookAction: facebookAction,
  facebookCallback: facebookCallback,
  getUser: getUser,
  getDirectOrderByCode: getDirectOrderByCode,
  getNotifications: getNotifications,
  getCollectOrderByCode: getCollectOrderByCode,
  getCollectOrderItems: getCollectOrderItems,
  getForwardOrderByCode: getForwardOrderByCode,
  getSplitOrderByCode: getSplitOrderByCode,
  getSplitOrderItemByCode: getSplitOrderItemByCode,
  getSplitOrderItems: getSplitOrderItems,
  getCollectOrderItemByCode: getCollectOrderItemByCode,
  getOrders: getOrders,
  getCollectOrderSettlements: getCollectOrderSettlements,
  getSettlement: getSettlement,
  initializePayment,
  cashbillPaymentChannels: cashbillPaymentChannels,
  setCashbillPayer,
  getOrder: getOrder,
  paymentChannels,
  getSettlementReport: getSettlementReport,
  get2FAAction,
  get2FADevices,
  create2FADevice,
  enable2FADevice,
  handle2FADecision,
  getInvoices,
  getInvoiceDetails,
  getOrderItemsForInvoice,
  getInvoiceOrderItemsReport,
  verifyEmail: verifyEmail,
  sendEmailVerificationMail: sendEmailVerificationMail,
  resetUserPassword: resetUserPassword,
  sendUserPasswordResetMail: sendUserPasswordResetMail,
  checkUserPasswordResetCode: checkUserPasswordResetCode,
  getOrdersFees: getOrdersFees,
  closeSettlement,
  exportWithdrawalCsv,
  simulateOrderInvoicePayment,
  logSwitch,
  getPartner,
  updatePartner,
  findShareRequests,
  getShareRequest,
  assignShareRequest,
  setShareRequestStatus,
  getVerificationTransferOrderByCode,
  changeMarketingConsentSetting,
  changeUserPassword,
  getOrderInvoice,
  createReturnSplitOrder,
  notifyBlikRedirect,
});

function singleSwitchReducer(state, action, type) {
  switch (action.type) {
    case type:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export default rootReducer;
