import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Button, Divider, Label, Segment, Table} from "semantic-ui-react";
import Statuses from "./statuses";
import LabelStatuses from "./lables";
import {t} from "../../i18n";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";

function mapStateToProps(state) {
  return {
    getCollectOrderItemByCode: state.getCollectOrderItemByCode
  };
}

function ButtonLink(props) {
  return null;
}

ButtonLink.propTypes = {
  as: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node
};

class CollectDetailsItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      invoice: null,
      loading: true,
    }
  }

  componentDidMount() {
    this.props.actions.getCollectOrderItemByCode(this.props.match.params.code);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getCollectOrderItemByCode !== this.props.getCollectOrderItemByCode) {
      let item = this.props.getCollectOrderItemByCode;
      if (item) {
        this.setState({item: item.data, invoice: item.invoice, loading: false})
      }
    }
  }

  render() {
    let item = this.state.item;
    let invoice = this.state.invoice;
    if (item) {
      let status = item.status;
      return (
        <Segment basic>
          <Segment basic>
            <Divider horizontal className='list-title'>{t("orders.collectItemDetails")}</Divider>
            <Segment>
              <Label attached='top'>{t("orders.collectItem")}{item.code}</Label>
              <Table compact celled basic='very' columns={2}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.status")}</Table.Cell>
                    <Table.Cell>
                      <LabelStatuses status={status}/>
                      &nbsp;
                      <Statuses status={status}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.parentCode")}</Table.Cell>
                    <Table.Cell>{item.parentCode}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.amount")}</Table.Cell>
                    <Table.Cell>{parseFloat(item.amount).toFixed(2)} {item.currency}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.feeAmount")}</Table.Cell>
                    <Table.Cell>{parseFloat(item.feeAmountSum).toFixed(2)} {item.currency}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.receiver")}</Table.Cell>
                    <Table.Cell>{item.toName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.description")}</Table.Cell>
                    <Table.Cell>{item.description}</Table.Cell>
                  </Table.Row>
                  {item.metadata && <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.metadata")}</Table.Cell>
                    <Table.Cell>{item.metadata}</Table.Cell>
                  </Table.Row>}
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.created")}</Table.Cell>
                    <Table.Cell>{item.createdAt}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.updated")}</Table.Cell>
                    <Table.Cell>{item.updatedAt}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            {invoice && <Segment>
              <Label attached={"top"}>{t("orders.invoice")}</Label>
              <Table compact celled basic='very' columns={2}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.amount")}</Table.Cell>
                    <Table.Cell>{parseFloat(invoice.amount).toFixed(2)} {invoice.currency}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.iban")}</Table.Cell>
                    <Table.Cell>{invoice.iban}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className='boldCell'>{t("orders.description")}</Table.Cell>
                    <Table.Cell>{invoice.description}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>}
          </Segment>
          {item.status === 'completed' && (
            <Segment basic>
              <Button to={`/panel/orders/collect/returns?orderItems=${item.code}`} as={Link}>
                {t('orders.collect.createReturn')}
              </Button>
            </Segment>
          )}
        </Segment>
      )
    } else {
      return <Segment basic loading={this.state.loading}/>
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectDetailsItems)
