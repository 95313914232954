import React from 'react';
import { formatAmount } from '../../utils/utilFunctions';
import * as actions from '../../../actions';
import { Checkbox, Grid, Icon, Image, Message, Popup, Segment, Table } from 'semantic-ui-react';
import copy from 'copy-to-clipboard';
import { t } from '../../../i18n';

const formatBBAN = bban => {
  let newBban = bban.slice(0, 2);
  let formattedBban = bban
    .slice(2)
    .replace(/(.{4})/g, '$1 ')
    .trim();
  newBban = newBban + ' ' + formattedBban;
  return newBban;
};

const CopyPopup = ({ value }) => {
  return (
    <Popup
      trigger={<Icon name='copy' onClick={() => copy(value)} style={{ cursor: 'pointer' }} />}
      content={t('Copied')}
      on='click'
      position='top right'
    />
  );
};

const receiver = 'FiberPay sp. z o.o.';

const StandardTransfer = ({ orderInvoice, invoice, toggleStandardPaid, standardPaid }) => {
  const { description, bban, amount } = invoice;
  const totalAmount = formatAmount(amount);
  const qrUrl = actions.getQRCodeUrl(orderInvoice.code, 'bank');
  return (
    <Segment basic>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Message>
              <Message.Header>Uwaga!</Message.Header>
              <Message.List>
                <Message.Item>
                  Przesłany przelew musi mieć dokładnie taki tytuł i kwotę jak wskazano poniżej.
                </Message.Item>
                <Message.Item>
                  Przyjmujemy płatności tylko z polskiego sytemu bankowego.
                  <br />
                  <b>Przelewy z zagranicznych banków będą odrzucane.</b>
                </Message.Item>
              </Message.List>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={10}>
            <Table basic='very' size='small' celled style={{ userSelect: 'text' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>Odbiorca przelewu</Table.Cell>
                  <Table.Cell>
                    <strong>{receiver}</strong> <CopyPopup value={receiver} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: '100%' }}>
                  <Table.Cell collapsing>Nr konta</Table.Cell>
                  <Table.Cell>
                    <strong>{formatBBAN(bban)}</strong> <CopyPopup value={bban} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: '100%' }}>
                  <Table.Cell collapsing>Tytuł przelewu</Table.Cell>
                  <Table.Cell>
                    <strong>{description}</strong> <CopyPopup value={description} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: '100%' }}>
                  <Table.Cell collapsing>Kwota przelewu</Table.Cell>
                  <Table.Cell>
                    <strong>{totalAmount}</strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign='middle'>
            <Image src={qrUrl} centered style={{ maxHeight: 150 }} />
          </Grid.Column>
        </Grid.Row>
        {orderInvoice.paymentType === 'standard_only' && (
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Checkbox
                label={t('paymentPanel.standardPaid')}
                checked={standardPaid}
                onChange={toggleStandardPaid}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

export default StandardTransfer;
