import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Button, Grid, Header, Modal, Segment, Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { l, t } from '../../i18n';
import FacebookButton from '../utils/FacebookButton';
import { SHOW_FACEBOOK } from '../../constants';
import history from '../../history';
import MarketingConsent from './marketingConsent';

function mapStateToProps(state) {
  return {
    user: state.getUser,
    fees: state.getOrdersFees,
    partner: state.getPartner,
  };
}

const infos = {
  split_item: "services.fiberSplit.2",
  collect_item: "services.fiberCollect.2",
  forward_source_item: "services.fiberForward.2",
  forward_target_item: "services.fiberForward.2",
  direct_item: "services.fiberDirect.2",
};
class PartnerStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    const { partner } = this.props;
    if (partner) {
      const isOpen = partner.kycStatus !== 'accepted';
      this.setState({ isOpen });
    }
  }

  handleRedirect = () => history.push('/panel/kyc');
  handleClose = () => this.setState({ isOpen: false });

  render() {
    const { partner } = this.props;
    return (
      <Modal open={this.state.isOpen}>
        <Modal.Content>
          <Header
            content={t('kyc.statusMessage.someFunctionsUnavailable')}
            subheader={t('kyc.statusMessage.required')}
            textAlign='center'
            as={Segment}
            basic
          />
          <Segment basic textAlign='center'>
            {partner.type && (
              <Header
                size='small'
                content={t(
                  `kyc.statusMessage.${
                    partner.kycStatus === 'rejected' ? 'rejected' : 'inProgress'
                  }`,
                )}
              />
            )}
            <Button primary onClick={this.handleRedirect}>
              {t('kyc.redirect')}
            </Button>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.handleClose}>
            Zamknij
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      partner: null,
      fees: null,
    };
  }

  componentDidMount() {
    this.props.actions.getUser();
  }

  componentDidUpdate(prevProps) {
    const { user, partner, fees } = this.props;

    if (user !== prevProps.user) {
      if (user.data) {
        this.setState({ user: user.data }, this.props.actions.getPartner);
      }
    }

    if (partner !== prevProps.partner) {
      if (partner.data) {
        this.setState({ partner: partner.data }, this.props.actions.getOrdersFees);
      }
    }

    if (fees !== prevProps.fees) {
      if (fees.data) {
        this.setState({ fees: fees.data });
      }
    }
  }

  facebookButton(user) {
    if (user.facebook) {
      // return <Label icon='facebook' content={<Icon name='check' color='green' />} />;
      return <FacebookButton type='unlink' content={t('facebook.unlink')} />;
    } else {
      return <FacebookButton type='link' content={t('facebook.link')} />;
    }
  }

  currentProvisions() {
    if (this.state.fees) {
      return (
        <Segment>
          <Header>{t('welcome.provision.info')}</Header>
          <Grid className='welcome-provision-grid'>
            <Grid.Row columns={2}>
              <Grid.Column>{t("welcome.provision.type")}</Grid.Column>
              <Grid.Column>{t("welcome.provision.amount")}</Grid.Column>
            </Grid.Row>
            {this.state.fees.map(({ type, amount }) => (
              <Grid.Row columns={2} key={type}>
                <Grid.Column>
                  {type}
                  {infos[type] && (
                    <Popup
                      trigger={
                        <Icon
                          circular
                          name="info"
                          color="grey"
                          size="small"
                          style={{ margin: "0 10px" }}
                        />
                      }
                      content={t(infos[type])}
                      on="hover"
                      position="top left"
                    />
                  )}
                </Grid.Column>
                <Grid.Column>{Number(amount)}%</Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Segment>
      );
    }
  }

  render() {
    const { user, partner, fees } = this.state;
    if (!(user && partner && fees)) return <Segment basic loading={true} />;

    return (
      <Segment basic>
        <PartnerStatusModal partner={partner} />
        <Segment>
          <Header as='h1'>{t('welcome.welcomeToFiberpay.1')}</Header>
          <Link to={l + '/panel/keygen'}>
            <p>{t('welcome.welcomeToFiberpay.2')}</p>
          </Link>
        </Segment>
        <Segment>
          <Header>{t('welcome.userInfo')}</Header>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>{t('welcome.name')}</Grid.Column>
              <Grid.Column>{user.name}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>{t('welcome.email')}</Grid.Column>
              <Grid.Column>{user.email}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>{t('welcome.created')}</Grid.Column>
              <Grid.Column>{user.createdAt}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header as='h4'>{t('welcome.connect')}</Header>
                {SHOW_FACEBOOK && this.facebookButton(user)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {this.currentProvisions()}
        <MarketingConsent />
      </Segment>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
