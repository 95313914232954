import {createStore, applyMiddleware} from 'redux';
//import logger from 'redux-logger';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import reducers from './reducers';


const storeMiddleware = applyMiddleware(
  ReduxPromise,
  //logger(),
  thunk
)(createStore);

export default storeMiddleware(reducers);
