import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";

import { Segment, Message } from "semantic-ui-react";

function mapStateToProps(state) {
  return {
    getNotifications: state.getNotifications,
  };
}

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    this.props.actions.getNotifications();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getNotifications !== this.props.getNotifications) {
      if(this.props.getNotifications.data){
        const closedNotificationsLocalStorage =
          JSON.parse(localStorage.getItem("closedNotifications")) || [];
        let notificationsToShow = this.props.getNotifications.data.filter(
          (notification) =>
            !closedNotificationsLocalStorage.includes(notification.code)
        );

        this.setState({
          notifications: notificationsToShow,
        });
      }
    }
  }

  handleDismiss(not) {
    let closedNotificationsList =
      JSON.parse(localStorage.getItem("closedNotifications")) || [];
    closedNotificationsList.push(not.code);
    localStorage.setItem(
      "closedNotifications",
      JSON.stringify(closedNotificationsList)
    );

    this.props.actions.getNotifications();
  }

  handleColor(not) {
    if (not.type === "error") {
      return "notificationMessageError";
    } else if (not.type === "warning") {
      return "notificationMessageWarning";
    } else {
      return "notificationMessageInfo";
    }
  }

  render() {
    const not = this.state.notifications;
    if (not.length === 0) {
      return null;
    }

    return (
      <Segment basic padded className="notification">
        {not.map((notification, index) => (
          <Message
            attached
            className={this.handleColor(notification)}
            align="center"
            key={notification.code}
            onDismiss={() => this.handleDismiss(notification, index)}
          >
            <b>{localStorage.getItem("i18nextLng") === "pl"
              ? notification.message_pl
              : notification.message_en}</b>
          </Message>
        ))}
      </Segment>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
