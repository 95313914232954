import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { t } from '../../i18n';

const companyKeys = ['companyAddress', 'companyPostalCode', 'companyCity', 'companyCountry'];

const mapKeyName = (key, type) => {
  const value = t(`kyc.data.${key}`);
  if (type === 'sole_proprietorship' && companyKeys.includes(key)) {
    return `${value} (firma)`;
  }

  return value;
};

const getFormattedValue = (key, value) => {
  if(key === 'country' || key === 'companyCountry') {
    return value === 'PL' ? 'POLSKA' : value;
  }

  return value;
}

const PartnerDetails = ({ details, type }) => (
  <Segment basic>
    <Grid>
      {Object.entries(details).map(
        ([key, value]) =>
          value && (
            <Grid.Row columns={2} key={key}>
              <Grid.Column>{mapKeyName(key, type)}</Grid.Column>
              <Grid.Column>{getFormattedValue(key, value)}</Grid.Column>
            </Grid.Row>
          ),
      )}
    </Grid>
  </Segment>
);

export default PartnerDetails;
