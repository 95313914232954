import React from 'react';
import { Table, Header, Segment } from 'semantic-ui-react';
import { formatAmount } from '../../utils/utilFunctions';

const OrderDescription = ({ orderInvoice, paymentChannelAmount }) => {
  const { currency } = orderInvoice;
  const amount = orderInvoice.amount && formatAmount(orderInvoice.amount, currency);
  const feeAmount = orderInvoice.feeAmount && formatAmount(orderInvoice.feeAmount, currency);
  const totalAmount = formatAmount(orderInvoice.amount, currency);
  const channelAmount = paymentChannelAmount ? formatAmount(paymentChannelAmount, currency) : null;

  const data = {
    Odbiorca: orderInvoice.receiver,
    Opis: orderInvoice.description,
  };

  if (feeAmount) {
    data['Kwota'] = amount;
    data['Opłata'] = feeAmount;
  }

  const entries = Object.entries(data).filter(([key, value]) => !!value)

  return (
      <Segment basic>
        <Table size='small'>
          <Table.Body>
            {entries.map(([key, value]) => (
                <Table.Row key={key}>
                  <Table.Cell textAlign='center'>{key}</Table.Cell>
                  <Table.Cell textAlign='center'>
                    <b>{value}</b>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <Header textAlign='center' size='large'>
          Do zapłaty: <b>{channelAmount || totalAmount}</b>
        </Header>
      </Segment>
  );
};

export default OrderDescription;
