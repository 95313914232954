import React, {Component} from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import {t} from "../../i18n";
import {Button, Checkbox, Grid, Modal, Segment} from "semantic-ui-react";

const mapStateToProps = state => ({
  user: state.getUser,
})

class MarketingConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingConsent: false,
      modalOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props;
    if (user !== prevProps.user) {
      this.setState({marketingConsent: user.data.marketingConsent})
    }
  }

  handleChange = (e, {checked}) => {
    if (checked) {
      this.props.actions.changeMarketingConsentSetting({marketingConsent: checked})
    } else {
      this.setState({modalOpen: true})
    }
  }

  handleSubmit = () => {
    this.setState({modalOpen: false},
      () => this.props.actions.changeMarketingConsentSetting({marketingConsent: false})
    )
  }

  toggleModalOpen = () => this.setState({modalOpen: !this.state.modalOpen})

  render() {
    return (
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              {t('marketingConsent.description')}
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                checked={this.state.marketingConsent}
                onChange={this.handleChange}
                toggle
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          onClose={this.toggleModalOpen}
          open={this.state.modalOpen}
        >
          <Modal.Header>{t('marketingConsent.description')}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{t('marketingConsent.modal.description.one')}</p>
              <p>{t('marketingConsent.modal.description.two')}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button basic onClick={this.toggleModalOpen}>
              {t('cancel')}
            </Button>
            <Button
              content={t('marketingConsent.modal.submit')}
              onClick={this.handleSubmit}
              primary
            />
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(MarketingConsent)
