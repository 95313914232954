import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import store from './store';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import './css/App.css';
import { routerLanguageParams } from "./i18n";
import LanguageRouter from './components/routes/languagerouter';
import TwoFactorAuthModal from './components/utils/2fa/TwoFactorAuthModal';


import Notification from "./components/panel/notification";

const routerParams = '/:lang('+routerLanguageParams()+')';

const LanguageRoutes = () => (
	<Router history={history}>
		<Switch>
			<Route path={routerParams} component={LanguageRouter}/>
			<Route component={LanguageRouter}/>
		</Switch>
	</Router>
);

const FullApp = () => {
	const isIndexing = (process.env.REACT_APP_INDEXING_ENABLED || '').toUpperCase() === 'YES';

	return (
		<>
		<Helmet>
			<meta name="robots" content={isIndexing ? "index,follow" : "noindex,nofollow"} />
		</Helmet>
			<Suspense fallback="loading">
				<Provider store={store}>
				<Notification />
					<TwoFactorAuthModal />
					<LanguageRoutes />
				</Provider>
			</Suspense>
		</>
	)
}

export default FullApp
