import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Button, Container, Form, Input, Message, Segment } from 'semantic-ui-react';
import {t} from "../../../i18n";
import FormComponent from '../../containers/formcomponent';

function mapStateToProps(state) {
	return {
		user: state.checkUserPasswordResetCode,
		reset: state.resetUserPassword,
	};
}

class PasswordReset extends FormComponent {
	constructor(props){
		super(props);
		this.state = {
			email: null,
			newPassword: '',
			repeatPassword: '',
			loading: true,
			...this.state
		}
	}


  componentDidMount(){
		let code = this.props.match.params.code;
		if(code){
    this.props.actions.checkUserPasswordResetCode({code: code});
		}
	}
	
	componentDidUpdate(prevProps){
		if(prevProps.user !== this.props.user){
			let user = this.props.user;
			if(user.data){
				this.setState({email: user.data.email});
			} else {
				this.setState({loading: false});
			}
		}

		if(prevProps.reset !== this.props.reset){
			let reset = this.props.reset;
			this.validateForm(reset, () => {
				if(reset.data){
					this.setState({message: reset.data.message, showSuccess: true})
				}
			})
			
		}
	}

	callResetUserPassword(){
		let data = {
			code: this.props.match.params.code,
			email: this.state.email,
			newPassword: this.state.newPassword,
			repeatPassword: this.state.repeatPassword,
		};
		this.props.actions.resetUserPassword(data);
	}


	render() {
		if(this.state.email){
			return (
				<Segment basic>
					<Container>
						<Segment className="loginSegment" style={{ minWidth: "40%", margin: 'auto' }} basic>
						<Form id='new-password-form' error={this.state.showErrors} success={this.state.showSuccess}
							onSubmit={this.handleSubmitForm.bind(this, () => this.callResetUserPassword())}>
							<Form.Field error={this.state.formErrorsKeys.email}>
							<label for='email'>{t('Email')}</label>
							<Input readOnly id='email'
								name='email'
								type='email'
								value={this.state.email}
								onChange={this.handleValueChange.bind(this)}
							/>
							</Form.Field>
							<Form.Field error={this.state.formErrorsKeys.newPassword}>
							<label for='new-password'>{t('New password')}</label>
							<Input required
								placeholder={t('New password')}
								id='new-password'
								autocomplete="new-password"
								name='newPassword'
								type='password'
								value={this.state.newPassword}
								onChange={this.handleValueChange.bind(this)}
							/>
							</Form.Field>
							<Form.Field error={this.state.formErrorsKeys.repeatPassword}>
								<label for='new-password-repeat'>{t('Repeat new password')}</label>
								<Input required
									placeholder={t('Repeat new password')}
									id='new-password-repeat'
									autocomplete="new-password"
									name='repeatPassword'
									type='password'
									value={this.state.repeatPassword}
									onChange={this.handleValueChange.bind(this)}
								/>
							</Form.Field>
							<Message error
								header={this.state.formErrorsMessage}
								list={this.state.formErrors}
							/>
							<Message success
								header={this.state.message}
							/>
							<Button primary type="submit" disabled={this.state.disabled}>{t("Change password")}</Button>
						</Form>
						</Segment>
					</Container>
				</Segment>
			)
		} else if(this.state.loading){
			return <Segment basic loading={true} style={{height: '100px'}}/>
		}
		return (
			<Segment basic style={{height: '100px'}}>
				<Message warning header={t('Reset password code not found or is invalid')}/>
			</Segment>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
