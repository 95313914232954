import React, {Component} from "react";
import {Button, Container, Divider, Form, Header, Message, Modal, Segment} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {t} from "../../i18n";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const mapStateToProps = (state) => ({
  returnOrder: state.createReturnSplitOrder,
});

const INITIAL_STATE = {
  data: null,
  invoice: null,
  loading: false,
  orderItemsCodes: '',
  error: null,
};

class CollectReturns extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE
  }

  componentDidMount() {
    const {searchParams} = new URL(window.location.href);
    const orderItemsCodes = searchParams.get('orderItems');
    if (orderItemsCodes) {
      this.setState({orderItemsCodes});
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const {returnOrder} = this.props;
    if (prevProps.returnOrder !== returnOrder) {
      if (returnOrder) {
        const {data, invoice, status, reason} = returnOrder;
        if (status === 'ERROR') {
          this.setState({loading: false, error: reason})
        } else {
          this.setState({data, invoice, loading: false, error: null})
        }
      }
    }
  }

  handleInputChange = (e, {value}) => this.setState({orderItemsCodes: value})

  validateAndTransformCodesToArray = () => {
    const {orderItemsCodes} = this.state;

    const transformed = orderItemsCodes
      .replaceAll('#', '')
      .split(',').map((code) => code.trim())
      .filter(code => !!code);

    const ORDER_ITEM_CODE_LENGTH = 8;
    const isValid = transformed.length && transformed.every(code => code.length === ORDER_ITEM_CODE_LENGTH);

    if (!isValid) {
      this.setState({error: t('orders.collect.returns.invalidItemCodes')});
      return null;
    }

    return transformed;
  }

  handleSubmit = () => {
    const itemsCodes = this.validateAndTransformCodesToArray();
    if (!itemsCodes) return;

    this.props.actions.createReturnSplitOrder({itemsCodes});
  }

  renderForm = () => {
    const {orderItemsCodes, error} = this.state;
    return (<Form onSubmit={this.handleSubmit}>
        <Message info>
          <Message.Header>
            {t('orders.collect.returns.infoMessage.header')}
          </Message.Header>
          <Message.Content>
            <div>
              {t('orders.collect.returns.infoMessage.content.one')}
            </div>
            <div>
              {t('orders.collect.returns.infoMessage.content.two')}
            </div>
          </Message.Content>
        </Message>
        {error && <Message negative>{error}</ Message>}
        <Form.Input type='text' value={orderItemsCodes} name='orderItemsCodes'
                    onChange={this.handleInputChange} placeholder={t('orders.collect.returns.form.placeholder')}/>
        <Form.Button
          type='submit'
          primary
        >{t('orders.collect.returns.form.submit')}</Form.Button>
      </Form>
    )
  }

  handleCloseModal = () => {
    this.setState(INITIAL_STATE)
  }

  render() {

    const {data, loading} = this.state;

    if (loading) return <Segment basic loading/>;
    return (
      <Segment basic>
        <Container>
          <Divider horizontal className='list-title'>{t("orders.collect.returns.header")}</Divider>
          <Modal open={!!data} onClose={this.handleCloseModal}>
            {!!data && (
              <>
                <Header content={t('orders.collect.returns.modal.header')} textAlign='center'/>
                <Modal.Content>
                  <Modal.Description>
                    <Segment basic textAlign='center'>
                      <Link to={`/order/${data.code}`}>
                        <Button>{t('orders.collect.returns.modal.paymentButton')}</Button>
                      </Link>
                    </Segment>
                    <Segment basic textAlign='center'>
                      <Link to={`/orders/${data.type}/${data.code}`}>
                        {t("orders.collect.returns.modal.orderDetailsLink")}
                      </Link>
                    </Segment>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={this.handleCloseModal}
                    content={t('close')}
                  />
                </Modal.Actions>
              </>
            )}
          </Modal>
          {this.renderForm()}
        </Container>
      </Segment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectReturns)
