import React, { Component } from 'react';
import { Sidebar, Segment, Icon } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import history from '../../history';

import PanelSidebar from './menu';
import Footer from '../parts/footer';
import Logout from './logout';
import NotFound from '../sites/notfound';
import Welcome from './welcome';
import ApiKeys from './keygen';
import ApiLogs from '../logs/apilogs';
import ApiLogDetails from '../logs/apilogdetails';
import CallbackLogs from '../logs/callbacklogs';
import CallbackLogDetails from '../logs/callbacklogdetails';
import Orders from '../orders/orders';
import CollectReturns from '../orders/collectreturns'
import CollectDetails from '../orders/collectdetails';
import CollectDetailsItems from '../orders/collectdetailsitems';
import Settlement from '../orders/settlement';
import DirectDetails from '../orders/directdetails';
import ForwardDetails from '../orders/forwarddetails';
import SplitDetails from '../orders/splitdetails';
import SplitDetailsItems from '../orders/splitdetailsitems';
import TwoFactorPanel from '../2fa/TwoFactorPanel';
import Invoices from '../invoices/invoices';
import InvoiceDetails from '../invoices/invoiceDetails';
import withUserRoles from '../utils/withUserRoles';
import KycPanel from '../kyc/kycPanel';
import AdminRoutes from '../admin/adminRoutes';
import VerificationTransferDetails from '../orders/verificationtransferdetails';
import UserSettings from '../settings/UserSettings';

function mapStateToProps(state) {
  return {
    getUser: state.getUser,
    getNotification: state.getNotification,
    getPartner: state.getPartner,
  };
}

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarVisible: true,
      isPartnerVerified: false,
    };
  }

  componentDidMount() {
    this.props.actions.userCheckLoggedIn();
    this.props.actions.getPartner();
  }

  componentDidUpdate(prevProps) {
    const { getPartner } = this.props;
    if (getPartner !== prevProps.getPartner) {
      const isPartnerVerified = getPartner?.data?.kycStatus === 'accepted';
      this.setState({ isPartnerVerified }, this.handleShareRequestsRedirects);
    }
  }

  handleShareRequestsRedirects() {
    const shareConsent = localStorage.getItem('shareConsent');
    const isKycPanel = this.props.location.pathname.includes('/panel/kyc');

    if (shareConsent && (!isKycPanel || this.state.isPartnerVerified)) {
      history.push(`/consents/${shareConsent}`);
    }
  }

  toggleSidebarVisibilityPanel() {
    let screenWidth = window.innerWidth;
    if (screenWidth <= 640 && this.state.sideBarVisible) {
      this.setState({ sideBarVisible: false });
    }
  }

  toggleSidebarVisibility = () =>
    this.setState({ sideBarVisible: !this.state.sideBarVisible });


  render() {
    const { sideBarVisible } = this.state;
    const { pathname } = this.props.location;
    const { path } = this.props.match;

    return (
      <Sidebar.Pushable as={Segment} className='borderOff'>
        <PanelSidebar visible={sideBarVisible} pathname={pathname} />
        <Sidebar.Pusher onClick={this.toggleSidebarVisibilityPanel.bind(this)}>
          <div className='ui vertical'>
            <Icon
              onClick={this.toggleSidebarVisibility}
              size='large'
              name='sidebar'
              id='icon-hamburger'
            />
          </div>
          <Segment basic style={{ minHeight: 'calc(100vh - 277px)' }}>
            <Switch>
              <Route path={path + '/keygen'} component={ApiKeys} />
              <Route path={path + '/2fa'} component={TwoFactorPanel} />
              <Route path={path + '/logout'} component={Logout} />
              <Route exact path={path + '/logs/requests'} component={ApiLogs} />
              <Route path={path + '/logs/requests/:code'} component={ApiLogDetails} />
              <Route exact path={path + '/logs/callbacks'} component={CallbackLogs} />
              <Route path={path + '/logs/callbacks/:code'} component={CallbackLogDetails} />
              <Route exact path={path + '/orders'} component={Orders} />
              <Route exact path={path + '/orders/collect/returns'} component={CollectReturns} />
              <Route exact path={path + '/orders/collect/:code'} component={CollectDetails} />
              <Route path={path + '/orders/collect/item/:code'} component={CollectDetailsItems} />
              <Route
                exact
                path={path + '/orders/collect/:code/settlements'}
                component={Settlement}
              />
              <Route exact path={path + '/orders/direct/:code'} component={DirectDetails} />
              <Route path={path + '/orders/forward/:code'} component={ForwardDetails} />
              <Route exact path={path + '/orders/split/:code'} component={SplitDetails} />
              <Route path={path + '/orders/split/item/:code'} component={SplitDetailsItems} />
              <Route
                exact
                path={path + '/orders/verification_transfer/:code'}
                component={VerificationTransferDetails}
              />
              <Route exact path={path + '/invoices'} component={Invoices} />
              <Route path={path + '/invoices/:code'} component={InvoiceDetails} />
              <Route path={path + '/settlements/:code'} component={Settlement} />
              <Route path={path + '/kyc'} component={KycPanel} />
              <Route path={path + '/settings'} component={UserSettings} />
              <Route path={path + '/admin'} component={AdminRoutes} />
              <Route path={path + '/*'} component={NotFound} />
              <Route component={Welcome} />
            </Switch>
          </Segment>
          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withUserRoles(Panel));
