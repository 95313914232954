import React, { Component } from 'react'
import { Container, Segment, Divider, Loader, Dimmer, Form, Button, Message } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {t} from "../../i18n";

function mapStateToProps(state) {
	return {
    closeSettlement: state.closeSettlement
  };
}

class CloseSettlement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      settlementCode: ''
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.closeSettlement !== this.props.closeSettlement) {
      const { status, reason, data } = this.props.closeSettlement;
      if(status === 'ERROR') {
        this.setState({ error: true, message: reason })
      } else if(data && data.status === 'closed') {
        this.setState({ error: false, message: `Settlement #${data.code} has been closed`})
      }
    }
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value, error: false, message: '' });
  }

  handleSubmit() {
    this.props.actions.closeSettlement(this.state.settlementCode);
  }

  isValidCode(code) {
    return /^[abcdefghjkmnpqrstuvwxyz23456789]{10}$/.test(code);
  }

  render() {
    return (
      <Container>
				<Segment basic>
					<Dimmer inverted active={this.state.loading}>
						<Loader content={t("system.loading")} />
					</Dimmer>
					<Divider horizontal className='list-title'>{t('adminPanel.closeSettlement.title')}</Divider>
					<Divider hidden /><Divider hidden />
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Form.Input
                placeholder={t('adminPanel.closeSettlement.settlementCode')}
                name='settlementCode'
                onChange={this.handleChange.bind(this)}
                value={this.state.settlementCode}
              />
              <Button primary type='submit' disabled={!this.isValidCode(this.state.settlementCode)}>{t('adminPanel.closeSettlement.close')}</Button>
            </Form>
            {this.state.message && <Message positive negative={this.state.error}>{this.state.message}</Message>}
				</Segment>
			</Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseSettlement)
