import React from 'react'
import { Segment, Container, Message } from 'semantic-ui-react';
import {t} from "../../i18n";

const NotFound = () => (
  <Container>
    <Segment basic>
      <Message error
        icon='search'
        header={t("system.notFound")}
      />
    </Segment>
  </Container>
)

export default NotFound;