import React, { Component } from 'react';
import {
  Segment,
  Container,
  Header,
  Button,
  Message,
  Checkbox,
  Modal,
  Popup,
  Icon,
  Table,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import { t } from '../../i18n';
import FooterPagination from '../utils/FooterPagination';

function mapStateToProps(state) {
  return {
    getApiKeys: state.getApiKeys,
    createApiKeys: state.createApiKeys,
    disableApiKeys: state.disableApiKeys,
    partner: state.getPartner,
  };
}

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      apiKeys: [],
      message: '',
      showAll: 0,
      offset: 0,
      limit: 10,
      totalNumberOfKeys: 0,
      kycAccepted: false,
    };
  }

  componentDidMount() {
    this.props.actions.getPartner();
  }

  componentDidUpdate(prevProps) {
    if (this.props.partner !== prevProps.partner) {
      const { data } = this.props.partner;
      this.setState({ kycAccepted: data?.kycStatus === 'accepted' }, () => this.getApiKeys());
    }

    if (prevProps.getApiKeys !== this.props.getApiKeys) {
      let apiKeys = [];
      let count = 0;
      if (this.props.getApiKeys.data) {
        let keys = this.props.getApiKeys;
        apiKeys = keys.data;
        count = keys.count;
      }
      this.setState({ loading: false, apiKeys, totalNumberOfKeys: count });
    }

    if (prevProps.createApiKeys !== this.props.createApiKeys) {
      if (this.props.createApiKeys.data) {
        this.setState({ isOpen: true }, () => this.getApiKeys());
      } else {
        this.setState({ loading: false, message: this.props.createApiKeys.reason });
      }
    }

    if (prevProps.disableApiKeys !== this.props.disableApiKeys) {
      if (this.props.disableApiKeys.data) {
        this.getApiKeys();
      } else this.setState({ loading: false, message: this.props.disableApiKeys });
    }
  }

  getApiKeys(offset = 0) {
    let data = {
      offset: offset,
      limit: this.state.limit,
      showall: this.state.showAll,
    };
    this.setState(
      {
        loading: true,
        offset: offset,
      },
      () => this.props.actions.getApiKeys(data),
    );
  }

  handleCreateApiKeys() {
    this.setState({ loading: true }, () => this.props.actions.createApiKeys());
  }

  handleDisableApiKeys = apiKey => {
    this.props.actions.disableApiKeys(
      apiKey,
      this.state.offset,
      this.state.limit,
      this.state.showAll,
    );
  };

  handleShowDisabled = (e, { checked }) => {
    let showAll = checked ? 1 : 0;
    this.setState({ showAll }, this.getApiKeys);
  };

  createCopyPopup(value) {
    return (
      <Popup
        trigger={<Icon name='copy' onClick={() => copy(value)} />}
        content='Kopiuj'
        on='click'
        position='top right'
      />
    );
  }

  render() {
    let apiKey = null;
    let secretKey = null;
    if (this.props.createApiKeys?.data) {
      apiKey = this.props.createApiKeys.data.apiKey;
      secretKey = this.props.createApiKeys.data.secretKey;
    }

    const showKeysTab = _.map(
      this.state.apiKeys,
      ({ apiKey, secretKey, isDeactivated, createdAt, sharedWith }) =>
        !isDeactivated ? (
          <Table.Row key={apiKey} textAlign={'center'}>
            <Table.Cell>{apiKey}</Table.Cell>
            <Table.Cell>{secretKey}</Table.Cell>
            <Table.Cell>{createdAt}</Table.Cell>
            <Table.Cell>
              {sharedWith && (
                <>
                  Przekazano aplikacji <a href={sharedWith.returnUrl}>{sharedWith.name}</a>
                </>
              )}
            </Table.Cell>
            <Table.Cell>
              <Button primary onClick={() => this.handleDisableApiKeys(apiKey)}>
                {t('keygen.deactivate')}
              </Button>
            </Table.Cell>
          </Table.Row>
        ) : (
          <Table.Row key={apiKey} textAlign={'center'} style={{ backgroundColor: '#ffe5e5' }}>
            <Table.Cell>{apiKey}</Table.Cell>
            <Table.Cell>{secretKey}</Table.Cell>
            <Table.Cell>{createdAt}</Table.Cell>
            <Table.Cell>
              {sharedWith && (
                <>
                  Przekazano aplikacji <a href={sharedWith.returnUrl}>{sharedWith.name}</a>
                </>
              )}
            </Table.Cell>
            <Table.Cell>{t('keygen.deactivated')}</Table.Cell>
          </Table.Row>
        ),
    );

    return (
      <Container>
        <Segment basic loading={this.state.loading}>
          <Header as='h3'>{t('keygen.createApiKeys')}</Header>
          <Button
            primary
            content={t('keygen.create')}
            onClick={() => this.props.actions.createApiKeys()}
            disabled={!this.state.kycAccepted}
          />
          {!this.state.kycAccepted && <Message header={t('keygen.kycRequiredMessage')} />}
          <Modal
            open={this.state.isOpen}
            onClose={() => {
              this.setState({ isOpen: false });
            }}
          >
            <Modal.Header>{t('keygen.keepKey')}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Segment basic>
                  <p>
                    {t('keygen.publicKey')}
                    {this.createCopyPopup(apiKey)}{' '}
                  </p>{' '}
                  {apiKey}
                </Segment>
              </Modal.Description>
              <Modal.Description>
                <Segment basic>
                  <p>
                    {t('keygen.secretKey')}
                    {this.createCopyPopup(secretKey)}{' '}
                  </p>{' '}
                  {secretKey}
                </Segment>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              >
                OK
              </Button>
            </Modal.Actions>
          </Modal>
          {this.state.message && <Message error header={this.state.message} />}
          <br />
          <br />
          <Checkbox label={t('keygen.showDeactivated')} onChange={this.handleShowDisabled} />
        </Segment>
        <Header as='h3'>
          {t('keygen.yourApiKeys')}
          {this.state.totalNumberOfKeys}
        </Header>
        <Table compact columns='5' id='api-keys-tab'>
          <Table.Header>
            <Table.Row textAlign={'center'}>
              <Table.HeaderCell>{t('keygen.public')}</Table.HeaderCell>
              <Table.HeaderCell>{t('keygen.secret')}</Table.HeaderCell>
              <Table.HeaderCell>{t('keygen.createdDate')}</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{showKeysTab}</Table.Body>
          <Table.Footer>
            <FooterPagination
              paginationChange={this.getApiKeys.bind(this)}
              offset={this.state.offset}
              colSpan={5}
              count={this.state.totalNumberOfKeys}
            />
          </Table.Footer>
        </Table>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
