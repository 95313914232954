import BaseComponent from './basecomponent';

export default class FormComponent extends BaseComponent {
  constructor(props){
    super(props);
    this.state = {
      formErrorsMessage: '',
      formErrors: [],
      formErrorsKeys: [],
      showErrors: false,
      disabled: false,
      showSuccess: false
    }
  }

  validateForm(p, onSuccess = () => {}) {
    if (p.details) {
      let errors = [];
      let errorKeys = [];
      for (let key in p.details) {
        errors.push(p.details[key]);
        errorKeys[key] = true;
      }
      errors = errors.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      let data = {
        showSuccess: false,
        showErrors: true,
        disabled: false,
        loading: false,
        formErrors: errors,
        formErrorsKeys: errorKeys,
        formErrorsMessage: p.message ? p.message : 'Error'
      };
      this.setState(data);
    } else if(p.error){
      this.setState({
        showSuccess: false,
        showErrors: true,
        disabled: false,
        loading: false,
        formErrors: [p.message],
        formErrorsKeys: [],
        formErrorsMessage: p.error
      });
    } else if(p.reason){
      this.setState({
        showSuccess: false,
        showErrors: true,
        disabled: false,
        loading: false,
        formErrors: [p.reason],
        formErrorsKeys: [],
        formErrorsMessage: p.error
      });
    } else {
      onSuccess();
    }
  }

  handleSubmitForm(callback, e, data ) {
    e.preventDefault();
    this.setState(
      {
        formErrors: [],
        formErrorsKeys: [],
        showErrors: false,
        disabled: true,
        loading: true
      },
      callback
    );
  }

}