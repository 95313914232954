import React, { Component } from 'react';
import FacebookCallback from './FacebookCallback';
import { Route, Switch } from 'react-router-dom';

export default class CallbackPanel extends Component {

	render() {
		let path = this.props.match.path;
		return (
			<Switch>
        <Route path={path + "/facebook"} component={FacebookCallback}/>
        <Route component={FacebookCallback}/>
      </Switch>
		)
	}
}