
import React, { Component } from 'react';
import { Route, Switch , Redirect} from 'react-router-dom';
// import {lang, checkInAvailableLanguages} from '../../i18n';
import FullAppRouting from './fullapprouting';

export default class LanguageRouter extends Component {

  render(){
    if(this.props.match.params.lang){
      let redirectUrl = this.props.location.pathname.substring(3) + this.props.location.search;
      return (
        <Redirect to={redirectUrl} />
      )
    }
    return (
      <Switch>
        <Route component={FullAppRouting}/>
      </Switch>
    )
  }

}