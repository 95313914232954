import {Label} from "semantic-ui-react";
import React from "react";

const LabelStatuses = (props) => {
  const label = {
    'completed': 'green',
    'failed': 'red',
    'defined': 'blue'
  }

  return <Label color={label[props.status]}>{props.status}</Label>
}

export default LabelStatuses;