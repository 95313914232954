import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Container, Form, Message, Segment } from 'semantic-ui-react';
import BaseComponent from '../../containers/basecomponent';

function mapStateToProps(state) {
	return {
		verify: state.verifyEmail,
	};
}

class EmailVerification extends BaseComponent {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			showErrors: false,
			showSuccess: false
		}
	}


  componentDidMount(){
		let code = this.props.match.params.code;
		if(code){
			this.props.actions.verifyEmail({code: code});
		} 
	}
	
	componentDidUpdate(prevProps){
		if(prevProps.verify !== this.props.verify){
			let verify = this.props.verify;
			if(verify.data){
				this.setState({message: verify.data.message, showSuccess: true});
			} else if(verify.reason) {
				this.setState({message: verify.reason, showErrors: true});
			} else {
				this.setState({message: 'Error - Something went wrong', showErrors: true});
			}
		} 
	}


	render() {
		if(this.state.showErrors || this.state.showSuccess){
			return (
				<Segment basic>
					<Container>
						<Form error={this.state.showErrors} success={this.state.showSuccess}>
							<Message error header={this.state.message}/>
							<Message success header={this.state.message}/>
						</Form>
					</Container>
				</Segment>
			)
		}
		return (
			<Segment basic loading={true} style={{height: '100px'}}/>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification)
