import React, { Component } from 'react';
import { Button, Form, Container, Header, Segment, Popup, Icon, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const getError = (content, pointing = 'below') =>
  content && {
    content,
    pointing,
  };

const mapStateToProps = state => ({
  setPayer: state.setCashbillPayer,
})

class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorFirstName: false,
      errorlastName: false,
      errorEmail: false,
      errorRegulations: false,
    };
  }

  validate() {
    const { regulationsAccepted, regulationsAcceptanceRequired, email, firstName, lastName } =
      this.props;

    let errorEmail = false;
    if (email.length > 190) {
      errorEmail = 'Adres email musi składać się z nie więcej niż 190 znaków';
    } else if (!this.emailIsValid(email)) {
      errorEmail = 'Adres email nie jest poprawny';
    }

    let errorRegulations = false;
    if (regulationsAcceptanceRequired && !regulationsAccepted) {
      errorRegulations = 'Zgoda jest wymagana';
    }

    let errorFirstName = false;
    if (firstName.length < 3) {
      errorFirstName = 'Imię musi składać się z co najmniej 3 znaków';
    } else if (firstName.length > 190) {
      errorFirstName = 'Imię musi składać się z nie więcej niż 190 znaków';
    }

    let errorLastName = false;
    if (lastName.length < 3) {
      errorLastName = 'Nazwisko musi składać się z co najmniej 3 znaków';
    } else if (lastName.length > 190) {
      errorLastName = 'Nazwisko musi składać się z nie więcej niż 190 znaków';
    }

    this.setState({
      errorEmail,
      errorFirstName,
      errorLastName,
      errorRegulations,
    });

    return !(errorEmail || errorFirstName || errorLastName || errorRegulations);
  }

  // noinspection JSMethodCanBeStatic
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  // noinspection JSMethodCanBeStatic
  handleSubmit = e => {
    e.preventDefault();
    if (this.props.isLoading) return;
    const isValid = this.validate();
    if (isValid) this.props.handleSubmit();
  };

  render() {
    const s = this.state;
    const {
      handleValueChange,
      handleCheckBoxChange,
      firstName,
      lastName,
      email,
      regulationsAccepted,
      regulationsAcceptanceRequired,
      setPayer,
    } = this.props;

    if(setPayer?.reason) {
      return <Message error header={setPayer?.reason} />;
    }

    return (
      <Container text>
        <Segment basic textAlign='center'>
          <Header textAlign='center'>Wprowadź dane</Header>
        </Segment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            label='Imię'
            value={firstName}
            placeholder='Imię'
            name='firstName'
            error={getError(s.errorFirstName, 'above')}
            required
            onChange={handleValueChange}
          />
          <Form.Input
            label='Nazwisko'
            value={lastName}
            placeholder='Nazwisko'
            error={getError(s.errorLastName, 'above')}
            name='lastName'
            required
            onChange={handleValueChange}
          />
          <Form.Input
            label={{
              children: (
                <>
                  Email
                  <Popup
                    trigger={
                      <Icon
                        circular
                        name='info'
                        color='grey'
                        size='small'
                        style={{ margin: '0 10px' }}
                      />
                    }
                    content='Na wskazany adres otrzymasz potwierdzenie po zrealizowaniu płatności'
                    on='hover'
                    position='top left'
                  />
                </>
              ),
            }}
            value={email}
            placeholder='Email'
            name='email'
            error={getError(s.errorEmail)}
            type='email'
            required
            onChange={handleValueChange}
          />
          {regulationsAcceptanceRequired && (
            <Form.Checkbox
              label={{
                children: (
                  <>
                    Akceptuję{' '}
                    <Link to='/services' target='_blank'>
                      regulamin
                    </Link>
                  </>
                ),
              }}
              checked={regulationsAccepted}
              name='regulationsAccepted'
              error={getError(s.errorRegulations, 'left')}
              required
              onChange={handleCheckBoxChange}
            />
          )}
          <Segment basic textAlign='center'>
            <Button content='Kontynuuj' type='submit' disabled={this.props.isLoading} primary />
          </Segment>
        </Form>
      </Container>
    );
  }
}

export default connect(mapStateToProps)(PersonalData);
