import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Button, Container, Divider, Label, Segment, Table} from "semantic-ui-react";
import Statuses from "./statuses";
import LabelStatuses from "./lables";
import {t} from "../../i18n";
import FileSaver from 'file-saver';

function mapStateToProps(state) {
  return {
    getSettlement: state.getSettlement,
    getSettlementReport: state.getSettlementReport
  };
}

class Settlement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settlement: null,
      loading: true,
    }
  }

  componentDidMount() {
    this.props.actions.getSettlement(this.props.match.params.code);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getSettlement !== this.props.getSettlement) {
      let settlement = this.props.getSettlement;
      if (settlement.data) {
        this.setState({settlement: settlement.data, loading: false})
      }
    }
    if (prevProps.getSettlementReport !== this.props.getSettlementReport) {
      let report = this.props.getSettlementReport;
      if (report) {
        const csvData = new Blob([report], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, this.state.settlement.code + '.csv');
      }
    }
  }

  render() {
    let settlement = this.state.settlement;

    if (settlement) {
      return (
        <Segment basic>
          <Container>
            <Segment basic>
              <Divider horizontal>{t("orders.settlementDetails")}</Divider>
              <Segment>
                <Label attached='top'>{t("orders.settlement")} #{settlement.code}</Label>
                <Table compact celled basic='very' columns={2}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.status")}</Table.Cell>
                      <Table.Cell>
                        <LabelStatuses status={settlement.status}/>
                        &nbsp;
                        <Statuses status={settlement.status}/>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.amount")}</Table.Cell>
                      <Table.Cell>{parseFloat(settlement.amount).toFixed(2)} {settlement.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.feeAmount")}</Table.Cell>
                      <Table.Cell>{parseFloat(settlement.feeAmount).toFixed(2)} {settlement.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.created")}</Table.Cell>
                      <Table.Cell>{settlement.createdAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.updated")}</Table.Cell>
                      <Table.Cell>{settlement.updatedAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.closed")}</Table.Cell>
                      <Table.Cell>{settlement.closedAt}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
              {settlement.closedAt && <Segment basic textAlign='center'>
                <Button primary onClick={() => this.props.actions.getSettlementReport(settlement.code)}>{t('settlement.downloadReportButton')}</Button>
              </Segment>}
            </Segment>
          </Container>
        </Segment>
      )
    } else {
      return <Segment basic loading={this.state.loading}/>
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(Settlement)
