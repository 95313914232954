import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import {
	Segment, Container, Message, Form, Button, Input
} from 'semantic-ui-react';
import { t } from "../../../i18n";
import FormComponent from '../../containers/formcomponent';

function mapStateToProps(state) {
	return {
		reset: state.sendUserPasswordResetMail,
	};
}

class ResetPassword extends FormComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			showSuccess: false,
			...this.state,
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.reset !== this.props.reset) {
			let reset = this.props.reset;
			this.validateForm(reset, () => {
				if (reset.data) {
					this.setState({ message: reset.data.message, showSuccess: true })
				}
			})
		}
	}

	callResetPassword() {
		this.props.actions.sendUserPasswordResetMail({
			email: this.state.email,
		});
	}


	render() {
		return (
			<Segment basic>
				<Container>
					<Segment className="loginSegment" style={{ minWidth: "40%", margin: 'auto' }} basic>
						<Form id='reset-form' onSubmit={this.handleSubmitForm.bind(this, () => this.callResetPassword())}
							error={this.state.showErrors} success={this.state.showSuccess}>
							<Form.Field error={this.state.formErrorsKeys.email}>
								<label for='email'>{t("emailAddress")}</label>
								<Input required placeholder={t("emailAddress")}
									autocomplete="username"
									id='email'
									name='email'
									type='email'
									onChange={this.handleValueChange.bind(this)}
									value={this.state.email}
								/>
							</Form.Field>
							<Message error
								header={this.state.formErrorsMessage}
								list={this.state.formErrors}
							/>
							<Message success
								header={this.state.message}
							/>
							<Button primary type="submit" disabled={this.state.disabled}>{t("Reset")}</Button>
						</Form>
					</Segment>
				</Container>
			</Segment>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
