import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {Container, Divider, Label, Segment, Table} from "semantic-ui-react";
import Statuses from "./statuses";
import LabelStatuses from "./lables";
import {t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getForwardOrderByCode: state.getForwardOrderByCode,
  };
}

class ForwardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      orderInvoice: null,
      item: null,
      loading: true,
    }
  }

  componentDidMount() {
    this.props.actions.getForwardOrderByCode(this.props.match.params.code)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getForwardOrderByCode !== this.props.getForwardOrderByCode) {
      let order = this.props.getForwardOrderByCode;
      if (order) {
        this.setState({order: order.data, orderInvoice: order.invoice, loading: false})
      }
    }
  }

  render() {
    let order = this.state.order;
    let orderInvoice = this.state.orderInvoice;
    if (order) {
      let status = order.status;
      return (
        <Segment basic>
          <Container>
            <Segment basic>
              <Divider horizontal className='list-title'>{order.type}{t("orders.details")}</Divider>
              <Segment>
                <Label attached='top'>{t("orders.forward")}</Label>
                <Table compact celled basic='very' columns={2}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.status")}</Table.Cell>
                      <Table.Cell>
                        <LabelStatuses status={order.status}/>
                        &nbsp;
                        <Statuses status={status}/>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.code")}</Table.Cell>
                      <Table.Cell>{order.code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.brokerAmount")}</Table.Cell>
                      <Table.Cell>{parseFloat(order.brokerAmount).toFixed(2)} {order.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.brokerIban")}</Table.Cell>
                      <Table.Cell>{order.brokerIban}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.brokerName")}</Table.Cell>
                      <Table.Cell>{order.brokerName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.feeAmount")}</Table.Cell>
                      <Table.Cell>{parseFloat(order.feeAmountSum).toFixed(2)} {order.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.targetAmount")}</Table.Cell>
                      <Table.Cell>{parseFloat(order.targetAmount).toFixed(2)} {order.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.targetIban")}</Table.Cell>
                      <Table.Cell>{order.targetIban}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.targetName")}</Table.Cell>
                      <Table.Cell>{order.targetName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.created")}</Table.Cell>
                      <Table.Cell>{order.createdAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.updated")}</Table.Cell>
                      <Table.Cell>{order.updatedAt}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
              {orderInvoice && orderInvoice.length !== 0 &&
              <Segment>
                <Label attached={"top"}>Invoice</Label>
                <Table compact celled basic='very' columns={2}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.amount")}</Table.Cell>
                      <Table.Cell>{parseFloat(orderInvoice.amount).toFixed(2)} {orderInvoice.currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.description")}</Table.Cell>
                      <Table.Cell>{orderInvoice.description}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='boldCell'>{t("orders.iban")}</Table.Cell>
                      <Table.Cell>{orderInvoice.iban}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>}
            </Segment>
          </Container>
        </Segment>
      )
    } else {
      return <Segment basic loading={this.state.loading}/>
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(ForwardDetails)