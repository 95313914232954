import React, { Component } from 'react'
import { Segment, Container, Loader, Dimmer, Divider, Grid, Label, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import JSONPretty from "react-json-pretty";
import NotFound from "../sites/notfound";
import {t} from "../../i18n";

function mapStateToProps(state) {
  return {
    getCallbackCode: state.getCallbackCode
  };
}

class CallbackLogDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: this.props.match.params.code,
      data: '',
      loading: true,
    }
  }

  componentDidMount() {
    this.props.actions.getCallbackCode(this.state.code)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getCallbackCode !== this.props.getCallbackCode) {
      let callback = this.props.getCallbackCode;
      if (callback) {
        this.setState({ data: callback, loading: false })
      }
    }
  }

  parseResponseBody(responseBody) {
    if (responseBody && typeof responseBody !== "string" && responseBody.isValidElement()) {
      let response = JSON.parse(responseBody);
      if (response.payload) {
        let parsed = {};
        parsed.payload = JSON.parse(response.payload);
        parsed.iss = response.iss;
        parsed.iat = response.iat
        return <JSONPretty id="json-pretty" data={parsed} />
      } else {
        return <JSONPretty id="json-pretty" data={responseBody} />
      }
    } else {
      return <JSONPretty id="json-pretty" data={responseBody} />
    }
  }

  labelStatuses(status) {
    if (status === 'processed') {
      return <Label color="green">{status}</Label>
    } else if (status === 'error') {
      return <Label color="red">{status}</Label>
    }
  }

  render() {
    let callback = this.state.data;
    if (callback) {
      if (callback.status === "ERROR") {
        return <NotFound />
      } else {
        let string = '';
        if (callback) {
          string = JSON.parse(callback.data);
        }
        return (
          <Container>
            <Segment basic>
              <Dimmer inverted active={this.state.loading}>
                <Loader content={t("system.loading")} />
              </Dimmer>
              <Divider horizontal className='list-title'>{t("callbackLogDetails.header")}</Divider>
              <Divider hidden /><Divider hidden />
              <Grid columns='equal' stackable>
                <Grid.Row stretched>
                  <Grid.Column>
                    <Segment>
                      <Label attached='top'>{t("callbackLogDetails.callback")}</Label>
                      <Segment>{string && (callback.type + ": " + (string.orderItem || string.shareRequest).data.code)}</Segment>
                      <Segment basic className='codeSegment'>
                        <Label.Detail><Icon name='info circle' />{t("callbackLogDetails.responseStatus")}{callback.responseStatus}</Label.Detail><br />
                        <Label.Detail><Icon name='info' />{t("callbackLogDetails.status")}{this.labelStatuses(callback.status)}</Label.Detail><br />
                        <Label.Detail><Icon name='calendar alternate outline' />{t("callbackLogDetails.created")}{callback.createdAt}</Label.Detail><br />
                        <Label.Detail><Icon name='calendar alternate outline' />{t("callbackLogDetails.sent")}{callback.sentAt}</Label.Detail><br />
                        <Label.Detail><Icon name='calendar alternate outline' />{t("callbackLogDetails.resend")}{callback.resendAt}</Label.Detail><br />
                        <Label.Detail><Icon name='calculator' />{t("callbackLogDetails.resendAttempt")}{callback.resendAttempt}</Label.Detail><br />
                        <Label.Detail><Icon name='laptop' />{t("callbackLogDetails.url")}{callback.url}</Label.Detail>
                      </Segment>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Label attached='top'>{t("callbackLogDetails.customParams")}</Label>
                      <Segment basic className='codeSegment'>
                        {callback.customParams}
                      </Segment>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Label attached='top'>{t("callbackLogDetails.headers")}</Label>
                      <Segment basic className='codeSegment'>
                        <JSONPretty id="json-pretty" data={callback.headers} />
                      </Segment>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Label attached='top'>{t("callbackLogDetails.data")}</Label>
                      <Segment basic className='codeSegment'>
                        <JSONPretty id="json-pretty" data={callback.data} />
                      </Segment>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Label attached='top'>{t("callbackLogDetails.responseBody")}</Label>
                      <Segment basic className='codeSegment'>
                        {this.parseResponseBody(callback.responseBody)}
                      </Segment>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        )
      }
    } else {
      return <Segment basic loading={this.state.loading} />
    }
  }
}


function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackLogDetails)
