import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../actions';

function mapStateToProps(state) {
  return {
    user: state.getUser,
  };
}

function withUserRoles(WrappedComponent) {

  class UserRolesAware extends Component {
    constructor(props) {
      super(props);
      this.state = {
          roles: [],
          isAdmin: false
        }
      }

    componentDidMount() {
      if(this.props.user) {
        this.setUserRoles(this.props.user);
      } else {
        this.props.actions.getUser();
      }
    }

    componentDidUpdate(prevProps) {
      if(prevProps.user !== this.props.user) {
        this.setUserRoles(this.props.user);
      }
    }

    setUserRoles(user) {
      if(user.data){
        const { roles } = this.props.user.data;
        this.setState({ roles });
      }
    }

    hasRole(role) {
      return this.state.roles.includes(role);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          userRoles={this.state.roles}
          hasRole={this.hasRole}
          isAdmin={this.hasRole('admin')}
        />
      );
    } 
  };

  return connect(mapStateToProps, mapDispatchToProps)(UserRolesAware)
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default withUserRoles;
