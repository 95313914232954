import React, { Component } from 'react'
import { Segment, Button, Message} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

function mapStateToProps(state) {
  return {
    simulation: state.simulateOrderInvoicePayment,
  };
}

class TransferSimulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: '',
      disabled: false
    }
  }


  componentDidUpdate(prevProps) {
    if(prevProps.simulation !== this.props.simulation) {
      let simulation = this.props.simulation;
      if(simulation.data){
        this.setState({
          loading: false,
          message: 'Pomyślnie zasymulowano transakcję bankową. W ciągu minuty system powinien przetworzyć zlecenie pozytywnie.'
        })
      } else if(simulation.reason){
        this.setState({
          loading: false,
          message: 'Już istnieje transakcja bankowa dla tego zlecenia. W ciągu minuty system powinien przetworzyć zlecenie pozytywnie.'
        })
      }
    }

    if(this.props.orderInvoice?.status === 'paid' && this.state.intervalHandler){
      clearInterval(this.state.intervalHandler)
    }
  }

  componentWillUnmount(){
    clearInterval(this.state.intervalHandler)
  }

  callSimulation(){
    this.setState({
      loading: true,
      disabled: true,
      message: '',
    }, () => {
      this.props.actions.simulateOrderInvoicePayment(this.props.orderInvoice.code);
      const intervalHandler = setInterval(() => this.props.fetchOrderInvoice(), 5000)
      this.setState({intervalHandler})
    })
  }

  render() {
    return (
      <Segment basic loading={this.state.loading} textAlign='center'>
        {this.state.message && <Message info header={this.state.message}/>}
        <Button primary
          onClick={this.callSimulation.bind(this)}
          disabled={this.state.disabled}
        >
          Transakcja pozytywna
        </Button>
      </Segment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferSimulation)
