import React, { Component } from 'react'
import { Container, Segment, Divider, Loader, Dimmer, Form, Button, Message } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {t, l} from "../../i18n";
import history from '../../history';

function mapStateToProps(state) {
	return {
    logSwitch: state.logSwitch
  };
}

class LogSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      email: '',
      loading: false,
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.logSwitch !== this.props.logSwitch) {
      let logSwitch = this.props.logSwitch;
      if(logSwitch.access_token){
        this.setState({message: ''});
        let token = "Bearer " + logSwitch.access_token;
        localStorage.setItem('authToken', token);
        localStorage.setItem('refreshToken', logSwitch.refresh_token);
        history.push(l+'/panel');
      } else {
        if(logSwitch.reason){
          this.setState({message: logSwitch.reason});
        }
      }
      this.setState({loading: false});
    }
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleSubmit() {
    let data = {
      email: this.state.email
    }
    this.setState({loading: true})
    this.props.actions.logSwitch(data);
  }

  render() {
    return (
      <Container>
				<Segment basic>
					<Dimmer inverted active={this.state.loading}>
						<Loader content={t("system.loading")} />
					</Dimmer>
					<Divider horizontal className='list-title'>LogSwitch</Divider>
					<Divider hidden />
          <Divider hidden />
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Form.Input
                placeholder={'Podaj email'}
                name='email'
                onChange={this.handleChange.bind(this)}
                value={this.state.email}
              />
              <Button primary type='submit' disabled={this.state.loading}>Przeloguj</Button>
            </Form>
            {this.state.message && <Message negative>{this.state.message}</Message>}
				</Segment>
			</Container>
    )
  }
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogSwitch)
