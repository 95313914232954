import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
	Button, Confirm
} from 'semantic-ui-react';
import { t } from '../../i18n';

function mapStateToProps(state) {
	return {
		facebook: state.facebookAction,
	};
}

class FacebookButton extends Component {
	constructor(props){
		super(props);
		this.state = {
			open: false
		}
	}
	componentDidUpdate(prevProps) {
    if(prevProps.facebook !== this.props.facebook){
			let fb = this.props.facebook;
      if(fb.url){
				window.location.href = fb.url;
			}
    }
	}

	handleFacebookButton() {
		if(this.props.type === 'unlink'){
			this.setState({open: true});
		} else {
			this.callFacebookAction();
		}
	}

	callFacebookAction(){
		this.props.actions.facebookAction({type: this.props.type});
	}


	render() {
		return (
			<>
			<Button primary
				icon='facebook'
				labelPosition='right'
				onClick={this.handleFacebookButton.bind(this)}
				content={this.props.content}
			/>
			<Confirm
				open={this.state.open}
				header={t('facebook.button.unlink.header')}
				content={t('facebook.button.unlink.content')}
				cancelButton={t('facebook.button.unlink.cancel')}
				confirmButton={t('facebook.button.unlink.confirm')}
				onCancel={() => this.setState({open: false})}
				onConfirm={() => this.callFacebookAction()}
			/>
			</>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButton)
