import history from '../history';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as constants from '../constants';
import { l } from '../i18n';
import QueryString from 'qs';

const ROOT_URL = constants.API_URL + '/' + constants.API_VERSION;

export const getQRCodeUrl = (code, type) => {
  return `${ROOT_URL}/orderinvoices/${code}/qrcode/${type}`;
};

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log('Request error');
    return Promise.reject(error);
  },
);

// Add a response interceptor - maybe here should be reaction for 500 :)
axios.interceptors.response.use(
  function (response) {
    if (
      !(
        response.request.responseURL.endsWith('/api/refresh') ||
        response.request.responseURL.endsWith('/api/login')
      )
    ) {
      let token = localStorage.getItem('authToken');
      if (token) {
        let decodeSec = jwt_decode(token.slice(8)).exp;
        let now = new Date().valueOf() / 1000;
        let diff = decodeSec - now;
        if (diff <= 120) {
          refreshAttempt();
        }
      }
    }
    return response;
  },
  function (error) {
    console.log('Response error');
    return Promise.reject(error);
  },
);

export function refreshAttempt() {
  const url = `${ROOT_URL}/refresh`;

  let refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    let data = { refresh_token: refreshToken };
    localStorage.removeItem('refreshToken');
    let options = prepareAxiosOptions(url, 'post', data);
    axios(options)
      .then(response => {
        let token = 'Bearer ' + response.data.access_token;
        localStorage.setItem('authToken', token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
      })
      .catch(error => {
        localStorage.setItem('refreshToken', refreshToken);
        console.log(error);
      });
  }
}

export function userLogin(formData) {
  const url = `${ROOT_URL}/login`;
  return generalCall(constants.USER_LOGIN, url, 'post', formData);
}

export function userCheckLoggedIn() {
  return dispatch => {
    let token = localStorage.getItem('authToken');
    if (!token || token === null) {
      history.push('/login');
    }
    return;
  };
}

export function userLogout() {
  return dispatch => {
    localStorage.removeItem('authToken');
    history.push('/login');
  };
}

export function userRegister(formData) {
  const url = `${ROOT_URL}/user`;
  return generalCall(constants.USER_REGISTER, url, 'post', formData);
}

export function resendEmail(formData) {
  const url = `${ROOT_URL}/email/resend`;
  return generalCall(constants.RESEND_EMAIL, url, 'post', formData);
}

export function generalCall(type, url, method, formData = null, additionalOptions = null) {
  let options = prepareAxiosOptions(url, method, formData, additionalOptions);
  return dispatch => {
    return axios(options)
      .then(response => dispatch(generalSuccess(response, type)))
      .catch(error => {
        try {
          if (error.message === 'Network Error') {
            // dispatch(networkProblem(error));
          } else if (error.response.status === 401) {
            dispatch(loginRequired(error));
          } else if (error.response.status >= 400 && error.response.status < 500) {
            if (
              error.response.status === 400 &&
              error.response.data.message === 'No application for user'
            ) {
              history.push('/error');
              dispatch(generalError(error.response));
              return;
            }
            dispatch(errorResponse(error, type));
            //console.log(error.response);
          } else {
            dispatch(generalError(error.response));
          }
        } catch (e) {
          dispatch(generalError(error));
        }
      });
  };
}

export function getNotifications() {
  let url = `${ROOT_URL}/notifications`;
  return generalCall(constants.GET_NOTIFICATION, url, 'get');
}

function prepareAxiosOptions(url, method, formData, additionalOptions) {
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  let authToken = localStorage.getItem('authToken');
  if (authToken) {
    headers['Authorization'] = authToken;
  }
  let language = localStorage.getItem('i18nextLng');
  if (language) {
    headers['Accept-Language'] = language.substr(0, 2);
  }
  let options = {
    method: method,
    headers: headers,
    withCredentials: true,
  };
  if (additionalOptions) {
    options = { ...options, ...additionalOptions };
  }
  let apiUrl = url;
  if (method === 'get') {
    if (formData) {
      apiUrl += '?' + QueryString.stringify(formData);
    }
  } else {
    if (formData) {
      options.data = JSON.stringify(formData);
    }
  }
  options.url = apiUrl;
  return options;
}

export function loginRequired(error) {
  localStorage.removeItem('authToken');
  history.push('/login');
  return {
    type: constants.LOGIN_REQUIRED,
    payload: error.response,
  };
}

export function generalError(error) {
  // history.push('/error');
  console.log(error);
  return {
    type: constants.GENERAL_ERROR,
    payload: error.response,
  };
}

export function errorResponse(error, type) {
  console.log(error);
  return {
    type: type,
    payload: error.response,
  };
}

const is2FaRequired = response => response.data && response.data.status === 'authRequired';

const handle2FARequired = (res, type) => {
  res.data.type = type;
  return {
    payload: res,
    type: constants.GET_2FA_ACTION,
  };
};

export function generalSuccess(response, type) {
  if (is2FaRequired(response)) {
    return handle2FARequired(response, type);
  }

  return {
    type: type,
    payload: response,
  };
}

export function networkProblem(error) {
  history.push(l + '/panel/networkproblem');
}

export function disableApiKeys(key) {
  const url = `${ROOT_URL}/apikeys/${key}`;
  return generalCall(constants.DISABLE_APIKEYS, url, 'patch');
}

export function getCollectOrderItem(code) {
  const url = `${ROOT_URL}/orders/collect/item/${code}`;
  return generalCall(constants.GET_COLLECT_ORDER_ITEM, url, 'get');
}

export function getApplication() {
  const url = `${ROOT_URL}/application`;
  return generalCall(constants.GET_APPLICATION, url, 'get');
}

export function getRequest(formData) {
  let url = `${ROOT_URL}/requests`;
  return generalCall(constants.GET_REQUEST, url, 'get', formData);
}

export function getRequestCode(code) {
  const url = `${ROOT_URL}/requests/${code}`;
  return generalCall(constants.GET_REQUEST_CODE, url, 'get');
}

export function getCallback(formData) {
  let url = `${ROOT_URL}/callbacks`;
  return generalCall(constants.GET_CALLBACK, url, 'get', formData);
}

export function getCallbackCode(code) {
  const url = `${ROOT_URL}/callbacks/${code}`;
  return generalCall(constants.GET_CALLBACK_CODE, url, 'get');
}

export function createApiKeys() {
  const url = `${ROOT_URL}/apikeys`;
  return generalCall(constants.CREATE_APIKEYS, url, 'post');
}

export function getApiKeys(formData) {
  let url = `${ROOT_URL}/apikeys`;
  return generalCall(constants.GET_APIKEYS, url, 'get', formData);
}

export function facebookAction(formData) {
  let url = `${ROOT_URL}/facebook`;
  return generalCall(constants.FACEBOOK_ACTION, url, 'post', formData);
}

export function facebookCallback(formData) {
  let url = `${ROOT_URL}/facebook/callback`;
  url += formData;
  return generalCall(constants.FACEBOOK_CALLBACK, url, 'get');
}

export function getUser() {
  let url = `${ROOT_URL}/user`;
  return generalCall(constants.GET_USER, url, 'get');
}

export function getOrders(formData) {
  let url = `${ROOT_URL}/orders`;
  return generalCall(constants.GET_ORDERS, url, 'get', formData);
}

export function getDirectOrderByCode(code) {
  let url = `${ROOT_URL}/orders/direct/${code}`;
  return generalCall(constants.GET_DIRECT_ORDER_BY_CODE, url, 'get');
}

export function getCollectOrderByCode(code) {
  let url = `${ROOT_URL}/orders/collect/${code}`;
  return generalCall(constants.GET_COLLECT_ORDER_BY_CODE, url);
}

export function getCollectOrderItems(code, formData) {
  let url = `${ROOT_URL}/orders/collect/${code}/items/lists`;
  return generalCall(constants.GET_COLLECT_ORDER_ITEM_LIST, url, 'get', formData);
}

export function getCollectOrderSettlements(code, formData) {
  let url = `${ROOT_URL}/orders/collect/${code}/settlements`;
  return generalCall(constants.GET_COLLECT_ORDER_SETTLEMENTS, url, 'get', formData);
}

export function getSettlements() {
  let url = `${ROOT_URL}/settlements`;
  return generalCall(constants.GET_SETTLEMENTS, url, 'get');
}

export function getSettlement(code, formData) {
  let url = `${ROOT_URL}/settlements/${code}`;
  return generalCall(constants.GET_SETTLEMENT, url, 'get', formData);
}

export function getCollectOrderItemByCode(code) {
  let url = `${ROOT_URL}/orders/collect/item/${code}`;
  return generalCall(constants.GET_COLLECT_ORDER_ITEM_BY_CODE, url, 'get');
}

export function getForwardOrderByCode(code) {
  let url = `${ROOT_URL}/orders/forward/${code}`;
  return generalCall(constants.GET_FORWARD_ORDER_BY_CODE, url, 'get');
}

export function getSplitOrderByCode(code) {
  let url = `${ROOT_URL}/orders/split/${code}`;
  return generalCall(constants.GET_SPLIT_ORDER_BY_CODE, url, 'get');
}

export function getSplitOrderItemByCode(code) {
  let url = `${ROOT_URL}/orders/split/item/${code}`;
  return generalCall(constants.GET_SPLIT_ORDER_ITEM_BY_CODE, url, 'get');
}

export function getSplitOrderItems(code, formData) {
  let url = `${ROOT_URL}/orders/split/${code}/items/lists`;
  return generalCall(constants.GET_SPLIT_ORDER_ITEM_LIST, url, 'get', formData);
}

export function createSplitReturn(code) {
  let url = `${ROOT_URL}/orders/split/${code}/returns`;
  return generalCall(constants.GET_SPLIT_ORDER_ITEM_LIST, url, 'post');
}

export function initializePayment(code, formData) {
  let url = `${ROOT_URL}/orderinvoices/${code}/payment`;
  return generalCall(constants.INITIALIZE_PAYMENT, url, 'post', formData);
}

export function setCashbillPayer(code, formData) {
  let url = `${ROOT_URL}/orderinvoices/${code}/payer`;
  return generalCall(constants.SET_CASHBILL_PAYER, url, 'post', formData);
}

export function getOrder(code) {
  let url = `${ROOT_URL}/orders/${code}`;
  return generalCall(constants.GET_ORDER, url, 'get');
}

export function getOrderItem(code) {
  let url = `${ROOT_URL}/orders/item/${code}`;
  return generalCall(constants.GET_ORDER_ITEM, url, 'get');
}

export function getPaymentChannels(code) {
  let url = `${ROOT_URL}/orderinvoices/${code}/paymentchannels`;
  return generalCall(constants.GET_PAYMENT_CHANNELS, url, 'get');
}

export function getInvoiceForOrder(code) {
  let url = `${ROOT_URL}/orderinvoices/order/${code}`;
  return generalCall(constants.GET_INVOICE_FOR_ORDER, url, 'get');
}

export function getSettlementReport(code) {
  let url = `${ROOT_URL}/settlements/${code}/report`;
  return generalCall(constants.GET_SETTLEMENT_REPORT, url, 'get');
}

export function handle2FAAction(formData, code, actionType) {
  let url = `${ROOT_URL}/2fa/actions/${code}`;
  return call2FA(actionType, url, 'post', formData);
}

export function replaceDevice(code, formData) {
  let url = `${ROOT_URL}/2fa/actions/${code}`;
  return call2FA(null, url, 'patch', formData);
}

export function resendAuthEmail(code, actionType) {
  let url = `${ROOT_URL}/2fa/actions/${code}`;
  return call2FA(actionType, url, 'put');
}

export function get2FADevices() {
  const url = `${ROOT_URL}/2fa/devices`;
  return generalCall(constants.USER_2FA_DEVICES, url, 'get');
}

export function create2FADevice(formData) {
  const url = `${ROOT_URL}/2fa/devices`;
  return generalCall(constants.USER_2FA_DEVICES, url, 'post', formData);
}

export function setDefault2FADevice(code) {
  const url = `${ROOT_URL}/2fa/devices/${code}/default`;
  return generalCall(constants.USER_2FA_DEVICES, url, 'post');
}

export function remove2FADevice(code) {
  const url = `${ROOT_URL}/2fa/devices/${code}`;
  return generalCall(constants.USER_2FA_DEVICES, url, 'delete');
}

export function handleEmailDecision(code, formData) {
  const url = `${ROOT_URL}/2fa/actions/${code}/decision`;
  return generalCall(constants.EMAIL_2FA_DECISION, url, 'post', formData);
}

export function getVerificationTransferOrderByCode(code) {
  let url = `${ROOT_URL}/orders/verification_transfer/${code}`;
  return generalCall(constants.GET_VERIFICATION_TRANSFER_ORDER_BY_CODE, url, 'get');
}

function call2FA(type, url, method, formData = null) {
  let options = prepareAxiosOptions(url, method, formData);
  return dispatch => {
    return axios(options)
      .then(response => {
        const { resource } = response.data;

        if (resource) {
          dispatch(generalSuccess({ data: resource }, type));
        }
        dispatch(generalSuccess(response, constants.GET_2FA_ACTION));
      })
      .catch(error => {
        try {
          if (error.response.status === 401) {
            dispatch(loginRequired(error));
          } else if (error.response.status >= 400 && error.response.status < 500) {
            dispatch(errorResponse(error, constants.GET_2FA_ACTION));
          } else {
            dispatch(generalError(error.response));
          }
        } catch (e) {
          dispatch(generalError(error));
        }
      });
  };
}
export function verifyEmail(formData) {
  let url = `${ROOT_URL}/users/email/verify`;
  return generalCall(constants.VERIFY_EMAIL, url, 'post', formData);
}

export function sendEmailVerificationMail(formData) {
  let url = `${ROOT_URL}/users/email/verify/send`;
  return generalCall(constants.SEND_EMAIL_VERIFICATION_MAIL, url, 'post', formData);
}

export function resetUserPassword(formData) {
  let url = `${ROOT_URL}/users/password/reset`;
  return generalCall(constants.RESET_USER_PASSWORD, url, 'post', formData);
}

export function changeUserPassword(formData) {
  let url = `${ROOT_URL}/users/password/change`;
  return generalCall(constants.CHANGE_USER_PASSWORD, url, 'post', formData);
}

export function checkUserPasswordResetCode(formData) {
  let url = `${ROOT_URL}/users/password/reset/check`;
  return generalCall(constants.CHECK_USER_PASSWORD_RESET_CODE, url, 'post', formData);
}

export function sendUserPasswordResetMail(formData) {
  let url = `${ROOT_URL}/users/password/reset/send`;
  return generalCall(constants.SEND_USER_PASSWORD_RESET_MAIL, url, 'post', formData);
}

export function getOrdersFees() {
  let url = `${ROOT_URL}/orders/fees`;
  return generalCall(constants.GET_ORDERS_FEES, url, 'get');
}

export function getInvoices(formData) {
  let url = `${ROOT_URL}/invoices`;
  return generalCall(constants.GET_INVOICES, url, 'get', formData);
}

export function getInvoiceDetails(code, formData) {
  let url = `${ROOT_URL}/invoices/${code}`;
  return generalCall(constants.GET_INVOICE_DETAILS, url, 'get', formData);
}

export function getOrderItemsForInvoice(code, formData) {
  let url = `${ROOT_URL}/invoices/${code}/items`;
  return generalCall(constants.GET_INVOICE_ORDER_ITEMS, url, 'get', formData);
}

export function getInvoiceOrderItemsReport(code) {
  const url = `${ROOT_URL}/invoices/${code}/items-report`;
  return generalCall(constants.GET_INVOICE_ORDER_ITEMS_REPORT, url, 'get');
}

export function exportCsv(type) {
  let url = `${ROOT_URL}/admin/withdrawal/${type}`;
  return generalCall(constants.EXPORT_WITHDRAWAL_CSV, url, 'get', null, { responseType: 'blob' });
}

export function closeSettlement(code) {
  let url = `${ROOT_URL}/admin/closeSettlement/${code}`;
  return generalCall(constants.CLOSE_SETTLEMENT, url, 'get');
}

export function simulateOrderInvoicePayment(code) {
  let url = `${ROOT_URL}/bankTransactions/orderinvoices/${code}`;
  return generalCall(constants.SIMULATE_ORDER_INVOICE_PAYMENT, url, 'post');
}

export function logSwitch(formData) {
  let url = `${ROOT_URL}/admin/logswitch`;
  return generalCall(constants.LOG_SWITCH, url, 'post', formData);
}

export function changeMarketingConsentSetting(formData) {
  let url = `${ROOT_URL}/users/marketing`;
  return generalCall(constants.GET_USER, url, 'put', formData);
}

export function getPartner() {
  let url = `${ROOT_URL}/partners`;
  return generalCall(constants.GET_PARTNER, url, 'get');
}

export function updatePartner(formData) {
  let url = `${ROOT_URL}/partners`;
  return generalCall(constants.UPDATE_PARTNER, url, 'post', formData);
}

export function simulatePartnerAccepted() {
  let url = `${ROOT_URL}/partners/simulate`;
  return generalCall(constants.GET_PARTNER, url, 'post');
}

export function getAccessKeyShareRequest(code) {
  let url = `${ROOT_URL}/apikeys/share-requests/${code}`;
  return generalCall(constants.GET_ACCESS_KEY_SHARE_REQUEST, url, 'get');
}

export function assignAccessKeyShareRequests(code, formData) {
  let url = `${ROOT_URL}/apikeys/share-requests/${code}/assign`;
  return generalCall(constants.ASSIGN_ACCESS_KEY_SHARE_REQUEST, url, 'post');
}

export function setAccessKeyShareRequestStatus(code, formData) {
  let url = `${ROOT_URL}/apikeys/share-requests/${code}/status`;
  return generalCall(constants.GET_ACCESS_KEY_SHARE_REQUEST, url, 'post', formData);
}

export function getOrderInvoice(code) {
  let url = `${ROOT_URL}/orderinvoices/${code}`;
  return generalCall(constants.GET_ORDER_INVOICE, url, 'get');
}

export function createReturnSplitOrder(formData) {
  let url = `${ROOT_URL}/orders/collect/items/returns`;
  return generalCall(constants.CREATE_RETURN_SPLIT_ORDER, url, 'post', formData);
}

export function notifyBlikRedirect(status, data) {
  const url = `${ROOT_URL}/blik-redirects/${status}`;
  return generalCall(constants.NOTIFY_BLIK_REDIRECT, url, 'get', data);
}
