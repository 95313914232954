
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
// import {lang, changeLanguageOfTranslations} from '../../i18n';
import App from '../App';
import Panel from '../panel/panel';
import Order from '../sites/payment/order';
import OrderTag from '../sites/OrderTag';
import BlikRedirectHandler from '../sites/BlikRedirectHandler';


export default class FullAppRouting extends Component {

  // setLanguage(newLang){
  //   if(newLang !== lang){
  //     changeLanguageOfTranslations(newLang);
  //   }
  // }

  render(){
    // let path = this.props.match.path;
    let path = '';
    return (
      <Switch>
        <Route path={path + "/panel"} component={Panel} />
        <Route path={path + "/order/:code/tag"} component={OrderTag} />
        <Route path={path + "/order/:code"} component={Order} />
        <Route path={path + "/blik-redirects/:status"} component={BlikRedirectHandler} />
        <Route path={path} component={App} />
      </Switch>
    )
  }

}
